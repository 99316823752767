import React from 'react';

interface Props {}

interface State {
  hasError: boolean;
}

class LocalErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <h4>
          Something went wrong. please refresh the page or contact ITD for Help.
        </h4>
      );
    }

    return this.props.children;
  }
}

export default LocalErrorBoundary;
