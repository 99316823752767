export enum FuelLevels  {
    Empty = 0,
    Quarter = 1,
    Half = 2, 
    ThreeQuarters = 3,
    Full = 4,
}

export const FuelLevelValuesMapping = {
    [FuelLevels.Empty]: 'Empty',
    [FuelLevels.Quarter]: '1/4', 
    [FuelLevels.Half]: '1/2', 
    [FuelLevels.ThreeQuarters]: '3/4',
    [FuelLevels.Full]: 'Full',
} as const;

export function FuelSelectList(){
    var keys = Object.keys(FuelLevelValuesMapping)
    
    let values =  keys.map(fuelLevel => {
        var tempFuelLevel = parseInt(fuelLevel) as FuelLevels;
        
        return {
            value: tempFuelLevel,
            label: FuelLevelValuesMapping[tempFuelLevel]
        }
    });
    return values;
}
