import React from 'react';
import { CarType } from '../../../../models/FleetAsset';

import MaterialTable from '@material-table/core';
import urlJoin from 'url-join';
import LoadingSpinner from '../../../../components/Loading';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import {
  CheckInCheckOutType,
  CHECKOUT_TABLE_COLUMNS,
} from '../../../../models/CheckInCheckOut';

const CHECKOUT_REFERENCE_URL = (id: number) =>
  urlJoin('api/car/', id.toString(), 'checkouts');

export default function CheckOutTable({
  parentCar: { id },
}: {
  parentCar: CarType;
}) {
  const dataUrl: string = CHECKOUT_REFERENCE_URL(id);
  const { response, error, loading } =
    useTokenGetRequest<CheckInCheckOutType[]>(dataUrl);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <MaterialTable
        columns={CHECKOUT_TABLE_COLUMNS}
        data={response ?? []}
        title={'Checkout History'}
        isLoading={loading}
      ></MaterialTable>
    </>
  );
}
