import React from 'react';
import {
  Box, CircularProgress, Fab, TextField
} from '@mui/material';
import { green } from '@mui/material/colors';

import Stack from '@mui/material/Stack';

import SendIcon from '@mui/icons-material/Send';



import { useFormik } from 'formik';
import useAccessToken from '../../../../hooks/useAccessToken';
import useNotifier from '../../../../hooks/useNotifier';
import httpClient from '../../../../utils/httpClient';

import * as yup from 'yup';

export default function AddNote({
  url,
  refresh,
}: {
  url: string;
  refresh: Function;
}) {
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();

  const NewNoteSchema = yup.object({
    note: yup.string().required('required'),
  });

  const initialValues = {
    note: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: NewNoteSchema,
    onSubmit: (
      values: { note: string | null },
      { resetForm, setFieldValue }
    ) => {
      //   setLoading(true);
      httpClient
        .request({
          url: url,
          method: 'POST',
          data: values,
          headers: {
            'Content-Type': 'text/json',
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'json',
        })
        .then(() => {
          createNotification({
            message: 'Success',
            options: { variant: 'success' },
          });
        })
        .catch(() => {
          createNotification({
            message: 'Error while saving.',
            options: { variant: 'error' },
          });
        })
        .finally(() => {
          setFieldValue('note', '');
          resetForm();
          refresh();
        });
    },
  });

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
        <Stack
          direction='row'
          spacing={3}
          alignItems='center'
          justifyContent='space-around'
          sx={{ mt: 2, mb: 2 }}
        >
          <TextField
            id='note'
            label='New Note'
            fullWidth
            value={formik.values.note}
            onChange={formik.handleChange}
            disabled={formik.isSubmitting}
            error={formik.touched.note && Boolean(formik.errors.note)}
            helperText={formik.touched.note && formik.errors.note}
          />

          <Box sx={{ m: 1, position: 'relative' }}>
            <Fab
              color='primary'
              aria-label='add'
              size='small'
              type='submit'
              disabled={formik.isSubmitting}
            >
              <SendIcon />
            </Fab>

            {formik.isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Stack>
      </form>
    </>
  );
}
