import React from 'react';
import { CarType } from '../../../models/FleetAsset';

import MaterialTable from '@material-table/core';
import urlJoin from 'url-join';
import LoadingSpinner from '../../../components/Loading';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { CarMileageReading, CAR_MILEAGE_TABLE_COLUMNS } from '../../../models/CarMileageReading';


const MILEAGE_REFERENCE_URL = (id: number) =>
  urlJoin('api/car', id.toString(), 'mileage');


export default function UpcomingReservations() {
    const dataUrl: string = MILEAGE_REFERENCE_URL(2527);
    const { response, error, loading } =
      useTokenGetRequest<CarMileageReading[]>(dataUrl);
  
    if (loading) {
      return <LoadingSpinner />;
    }
  
    return (
      <>
        <MaterialTable
          columns={CAR_MILEAGE_TABLE_COLUMNS(() => {return;})}
          data={response ?? []}
          title={'Upcoming Reservations'}
        ></MaterialTable>
      </>
    );
}
