import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { deepOrange, green, grey } from '@mui/material/colors';
import httpClient from '../../utils/httpClient';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import useGraphToken from '../../hooks/useGraphToken';
import { styled } from '@mui/material/styles';

function getNameInitials(userName: string): string {
  const [lastName, firstName] =
    userName.indexOf(',') >= 0 ? userName.split(',') : userName.split(' ');
  return `${
    firstName !== undefined ? firstName.trim().charAt(0).toUpperCase() : ''
  }${lastName !== undefined ? lastName.trim().charAt(0).toUpperCase() : ''}`;
}
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));
export default function Image({
  imgUrl,
  userName = 'User',
  addAdminCheck = false,
  impersonatedUser,
  small = false,
}: {
  imgUrl: string;
  userName?: string;
  addAdminCheck?: boolean;
  impersonatedUser?: { imgUrl: string; username?: string | null };
  small?: boolean;
}) {
  const { graphToken, refreshGraphToken } = useGraphToken();
  const [pictureData, setPictureData] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bgColor, setBgColor] = useState<any>(deepOrange[500]);

  const name = getNameInitials(userName);

  useEffect(() => {
    let isMounted = true;

    const requestConfig: AxiosRequestConfig = {
      url: imgUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'image/jpeg',
        Authorization: `Bearer ${graphToken}`,
      },
      responseType: 'blob',
    };

    const fetchImage = async () => {
      try {
        refreshGraphToken();
        setPictureData('');
        const result: AxiosResponse = await httpClient.request(requestConfig);
        if (result.status === 200) {
          const url = window.URL || window.webkitURL;
          const imageUrl = url.createObjectURL(result.data);
          if (isMounted) setPictureData(imageUrl);
          setBgColor(grey);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch {
        setIsLoading(false);
      }
    };

    if (graphToken !== '') {
      setIsLoading(true);
      fetchImage();
    }
    return () => {
      isMounted = false;
      setPictureData('');
    };
  }, [graphToken, imgUrl]);

  const getAvatar = (): React.ReactNode => {
    if (addAdminCheck) {
      return (
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          color='secondary'
          variant='dot'
        >
          <Avatar sx={{ bgcolor: bgColor }} src={pictureData} children={name} />
        </Badge>
      );
    } else if (impersonatedUser !== undefined) {
      return (
        <Badge
          overlap='circular'
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ marginRight: '5px' }}
          badgeContent={
            <Image
              imgUrl={impersonatedUser.imgUrl}
              small={true}
              userName={impersonatedUser?.username ?? undefined}
            />
          }
        >
          <Avatar sx={{ bgcolor: bgColor }} src={pictureData} children={name} />
        </Badge>
      );
    } else if (small) {
      return (
        <SmallAvatar
          sx={{ bgcolor: bgColor }}
          src={pictureData}
          children={name}
        />
      );
    } else {
      return (
        <Avatar sx={{ bgcolor: bgColor }} src={pictureData} children={name} />
      );
    }
  };

  return (
    <Box sx={{ ml: 1, position: 'relative' }}>
      {!isLoading && getAvatar()}

      {isLoading && (
        <CircularProgress
          size={24}
          sx={{
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
}
