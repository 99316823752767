import React from 'react';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import { ProfileType } from '../../../../models/Access';
import urljoin from 'url-join';
import Grid from '@mui/material/Grid';
import { text } from '@fortawesome/fontawesome-svg-core';
import { Typography, Box, IconButton } from '@mui/material';
import Image from '../../../../components/Profile/Image';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UnassignUser from './UnassignUser';

const SINGLE_USER_URL = (id: string | number): string => `/api/user/${id}`;
const USER_PHOTO_URL = (azureId: string): string =>
  `https://graph.microsoft.com/v1.0/users/${azureId}/photo/$value`;

interface AssignedUserCardProps {
  carId: number | string;
  user: ProfileType;
  refresh: Function;
}

export default function AssignedUserCard({
  carId,
  user,
  refresh,
}: AssignedUserCardProps) {
  const [open, setOpen] = React.useState<boolean>(false);

  const openModal = () => setOpen(true);
  const closeModalNoSave = () => setOpen(false);
  const closeModalSave = () => {
    refresh();
    setOpen(false);
  };

  return (
    <>
      <UnassignUser
        carId={carId}
        open={open}
        setClose={closeModalNoSave}
        closeRefresh={closeModalSave}
      />
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
      >
        <Grid item>
          <Image
            userName={user.name ?? 'No, User'}
            imgUrl={USER_PHOTO_URL(user.azureId)}
          />
        </Grid>
        <Grid item>
          <Typography variant='h5' color='darkgreen'>
            {user && user.name}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => openModal()}
            color='error'
            aria-label='delete'
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
