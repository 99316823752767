import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { GroupSelectorState, GroupSelectionActions } from '../../../store/GroupSelector';
import { ApplicationState } from '../../../store';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { GroupType } from '../../../models/Group';

export default function index({group, setGroup, isGroupAdmin = false}:{group: string, setGroup: Function, isGroupAdmin?: boolean}) {
  const dispatch = useDispatch();
  const [ groupNames, setGroupNames] = useState<string[]>([]);

  const { response, error, loading } = useTokenGetRequest<GroupType[]>(`/api/group${isGroupAdmin ? `?groupAdmin=${isGroupAdmin}` : ''}`);

  useEffect(() => {
    let isMounted = true;
  
    if(response !== null) {
        let _names = response.map((group: GroupType, index: number) => group.id.toString())
        if(isMounted) setGroupNames(_names);
    }

    return () => {
      isMounted = false;
    }
  }, [response])
  
  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    dispatch(setGroup(event.target.value as string));
  };

  return (
    <Box sx={{ minWidth: 320, backgroundColor: "white" }}>
      <FormControl sx={{ minWidth: 320, }}>
        <InputLabel id="demo-simple-select-label">Group</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={group}
          label="Group"
          onChange={handleChange}
        >
          <MenuItem value={"all"}>ALL</MenuItem>
          {response !== null && response.map((group: GroupType, index: number) =>  (
            <MenuItem value={group.id.toString()}>{group.name.toUpperCase()}</MenuItem>
          ))}

          
        </Select>
      </FormControl>
    </Box>
  );
}
