import React from 'react';

import { tabConfigType } from '../../../../models/Tabs';
import ReservationTable from './ReservationTable';

import Tabs from '../../../../components/Tabs';

export default function RelatedUserTabs() {
  const tabConfig: tabConfigType[] = [
    { label: 'Check In/Out History', key: 0, component: <ReservationTable/> },
    {
      label: 'Reservation History',
      key: 1,
      component: <ReservationTable/>,
    },
    {
      label: 'Groups',
      key: 2,
      component: <ReservationTable/>,
    },

  ];

  return (
    <>
      <Tabs tabConfig={tabConfig} />
    </>
  );
}
