import React from 'react';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useFormik } from 'formik';
import moment from 'moment';
import { useSelector } from 'react-redux';

import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import {
  CheckInCheckOutType,
  NewCheckOutSchema,
} from '../../../models/CheckInCheckOut';
import { CarType } from '../../../models/FleetAsset';
import { ApplicationState } from '../../../store';
import { AccessState } from '../../../store/Access';
import httpClient from '../../../utils/httpClient';
import {
  createCheckInSelection,
  roundToNearest30,
  roundToNearest5,
} from '../../../utils/timeHelper';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { AxiosRequestConfig } from 'axios';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';

interface CheckOutFormProps {
  car: CarType;
  closeCheckout: Function;
  closeRefresh: Function;
}

export default function CheckOutForm({
  car,
  closeCheckout,
  closeRefresh,
}: CheckOutFormProps) {
  const today = new Date();

  const listTime = createCheckInSelection(today);
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();
  const [checkoutLoading, setLoading] = React.useState(false);
  const now: Date = roundToNearest5(today);
  const [created, setCheckoutCreation] = React.useState(false);

  const willReturn: Date = roundToNearest30(today);

  const { profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );
  const initialValues: CheckInCheckOutType = {
    carId: car.id,
    userId: profile?.id ?? 0,
    purpose: '',
    checkOutDate: moment(now).format('YYYY-MM-DDTHH:mm'),
    expectedCheckInDate: moment(willReturn).format('YYYY-MM-DDTHH:mm'),
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: NewCheckOutSchema,
    onSubmit: (values: CheckInCheckOutType) => {
      setLoading(true);

      httpClient
        .request({
          url: '/api/checkout',
          method: 'POST',
          data: values,
          headers: {
            'Content-Type': 'text/json',
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'json',
        })
        .then(() => {
          createNotification({
            message: 'Success',
            options: { variant: 'success' },
          });
          setCheckoutCreation(true);
          closeRefresh();
        })
        .catch(() => {
          createNotification({
            message: 'Error while saving.',
            options: { variant: 'error' },
          });
        })
        .finally(() => {
          setLoading(false);
          formik.setSubmitting(false);
        });
    },
  });

  const { response, error, loading, refresh } = useTokenGetRequest<any[]>(
    `/api/reservation/car/${car.id}?startTime=${formik.values.checkOutDate}&endTime=${formik.values.expectedCheckInDate}`,
    'Error retrieving data'
  );
  React.useEffect(() => {
    if (!loading) {
      refresh();
    }
  }, [formik.values.expectedCheckInDate]);

  const modalName = (text: string): React.ReactNode => (
    <Stack direction='column' mb={2}>
      <Typography color='secondary'>{text}</Typography>
    </Stack>
  );

  const handleSelectChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('expectedCheckInDate', event.target.value);
  };

  return (
    <>
      <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
        <DialogContent>
          {modalName(`${car.year} ${car.make} ${car.model}`)}
          <Stack direction='column' spacing={1}>
            <Grid container>
              <Grid item>
                <p>Upcomming Reservations:</p>
                {loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                ) : (
                  <>
                    <ul>
                      {response?.map((reservation) => (
                        <li>
                          {moment(reservation.expectedStartTime).format(
                            'MM/DD/YY h:mmA'
                          )}{' '}
                          -{' '}
                          {moment(reservation.expectedEndTime).format(
                            'MM/DD/YY h:mmA'
                          )}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} direction='row' alignItems='center'>
              <Grid item>
                <Box>
                  <>Return Date:</>
                  <Typography variant='h5'>
                    {moment(now).format('ddd, MMM D')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item>
                <FormControl
                  variant='standard'
                  sx={{ 
                    // m: 1, 
                    width: 120
                   }}
                  size='small'
                >
                  <>Return Time:</>
                  <Select
                    labelId='Check-In-time-select-label'
                    id='Check-In-time-select'
                    value={formik.values.expectedCheckInDate ?? ''}
                    onChange={handleSelectChange}
                    size='small'
                  >
                    {listTime &&
                      listTime.map((t: Date, idx: number) => (
                        <MenuItem
                          disabled={now > t}
                          key={moment(t).format('YYYY-MM-DDTHH:mm')}
                          value={moment(t).format('YYYY-MM-DDTHH:mm')}
                        >
                          <Typography variant='h6'>
                            {moment(t).format('hh:mm a')}
                          </Typography>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <TextField
              required
              disabled={formik.isSubmitting}
              fullWidth
              sx={{ height: 70, width: 'auto' }}
              value={formik.values.purpose}
              id='purpose'
              label='Purpose'
              variant='standard'
              onChange={formik.handleChange}
              error={formik.touched.purpose && Boolean(formik.errors.purpose)}
              helperText={formik.touched.purpose && formik.errors.purpose}
              multiline
              maxRows={2}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            variant='contained'
            disabled={formik.isSubmitting}
            onClick={() => closeCheckout()}
            sx={{ mr: 4 }}
          >
            cancel
          </Button>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              color='primary'
              variant='contained'
              disabled={formik.isSubmitting}
              type='submit'
            >
              Checkout
            </Button>
            {checkoutLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </DialogActions>
      </form>
    </>
  );
}
