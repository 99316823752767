import React from 'react';
import {
  Divider, List,
  ListItem, ListItemText, Paper
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { CarType } from '../../../../models/FleetAsset';


import { CarNoteType } from '../../../../models/FleetAsset';

import { toHumanFriendlyDate } from '../../../../utils/timeHelper';

import { useSelector } from 'react-redux';
import urlJoin from 'url-join';
import LoadingSpinner from '../../../../components/Loading';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import { ApplicationState } from '../../../../store';
import { AccessState } from '../../../../store/Access';
import AddNote from './AddNote';

const GET_NOTES_URL = (id: number) =>
  urlJoin('api/car', id.toString(), 'notes');
const POST_NOTES_URL = (id: number) =>
  urlJoin('api/car', id.toString(), 'note');

const classes = {

  messageArea: {
    height: '41vh',
    overflowY: 'auto',
    marginBottom: 2,
    marginTop: 2,
  },
};

export default function Notes({ parentCar: { id } }: { parentCar: CarType }) {
  const dataUrl: string = GET_NOTES_URL(id);
  const { response, error, loading, refresh } =
    useTokenGetRequest<CarNoteType[]>(dataUrl);

  const { profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );
  let profileId: number = -1;

  if (profile !== null) {
    profileId = profile.id;
  }

  return (
    <Paper>
      <Stack direction='column' justifyContent='center' sx={{ m: 2 }}>
        <AddNote url={POST_NOTES_URL(id)} refresh={refresh} />
        <Divider />
        {loading ? (
          <div style={{ height: '44vh' }}>
            <LoadingSpinner />
          </div>
        ) : (
          <List sx={classes.messageArea}>
            {response &&
              response
                .sort((a: CarNoteType, b: CarNoteType) => {
                  var textA = new Date(a.createdOn);
                  var textB = new Date(b.createdOn);
                  return textA > textB ? -1 : textA < textB ? 1 : 0;
                })
                .map((note: CarNoteType, idx: number) => (
                  <ListItem dense key={note.userId}>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: '24px',
                        color: '#607d8b',
                      }}
                      secondaryTypographyProps={
                        note.userId === profileId
                          ? {
                              color: '#F25804',
                            }
                          : { color: '#0E86D4' }
                      }
                      primary={`${note.note}`}
                      secondary={`${note.userName}, ${toHumanFriendlyDate(
                        note.createdOn
                      )}`}
                    />
                  </ListItem>
                ))}
          </List>
        )}
      </Stack>
    </Paper>
  );
}

