import MaterialTable from '@material-table/core';
import { Column } from '@material-table/core';
import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import InfoCard from '../../../components/Card/InfoCard';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { CarType } from '../../../models/FleetAsset';
import { InfoCardsType } from '../../../models/InfoCards';
import { CheckInCheckOutType } from '../../../models/CheckInCheckOut';
import { toHumanFriendlyDate } from '../../../utils/timeHelper';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import { formatMialage } from '../../../utils/carHelper';

export default function checkedOutVehiclesList() {
  const history = useHistory();

  interface ComboCheckout {
    car: CarType;
    checkout: CheckInCheckOutType;
  }

  const { response, error, loading } = useTokenGetRequest<{
    results: ComboCheckout[];
  }>('api/checkouts/group');

  const vehicleTableColumns: Array<Column<ComboCheckout>> = [
    { title: 'Asset Number', field: 'car.assetNumber' },
    { title: 'Active', field: 'car.active', lookup: { true: 'Active' } },
    {
      title: 'Mileage',
      field: 'car.latestMileage',
      render: (rowData) => formatMialage(rowData.car.latestMileage),
    },
    { title: 'License', field: 'car.licensePlate' },
    { title: 'Checked Out By', field: 'checkout.userName' },
    {
      title: 'Check Out',
      field: 'checkout.checkOutDate',
      type: 'date',
      render: (rowData, renderType) => {
        if (renderType === 'row') {
          if (rowData.checkout.checkOutDate !== null) {
            return toHumanFriendlyDate(rowData.checkout.checkOutDate);
          }
          return '';
        } else {
          if (rowData !== null) {
            return format(new Date(rowData.toString()), 'MM-dd-yyyy');
          } else {
            return '';
          }
        }
      },
    },
    {
      title: 'Expected Return',
      field: 'checkout.expectedCheckInDate',
      type: 'date',
      render: (rowData, renderType) => {
        if (renderType === 'row') {
          if (
            rowData.checkout.expectedCheckInDate !== null ||
            rowData.checkout.expectedCheckInDate !== undefined
          ) {
            return toHumanFriendlyDate(rowData.checkout.expectedCheckInDate);
          }
          return '';
        } else {
          if (rowData !== null || rowData !== undefined) {
            return format(new Date(rowData.toString()), 'MM-dd-yyyy');
          } else {
            return '';
          }
        }
      },
    },
    { title: 'Make', field: 'car.make' },
    { title: 'Model', field: 'car.model' },
    { title: 'Year', field: 'car.year' },
    { title: 'Color', field: 'car.color' },
    { title: 'Purpose', field: 'checkout.purpose' },
  ];

  const handleRowClick = (
    event?: React.MouseEvent,
    rowData?: ComboCheckout
  ) => {
    console.log('row click');
    if (rowData?.car?.id) {
      history.push(`/vehicle/${rowData.car.id}`);
    }
  };
  return (
    <>
      <Grid container direction='column' spacing={3}>
        <Grid item>
          <div style={{ height: 600, width: 'auto' }}>
            <MaterialTable
              columns={vehicleTableColumns}
              data={response?.results ?? []}
              title={'Vehicles Checked Out'}
              options={{
                grouping: true,
                pageSize: 15,
                pageSizeOptions: [15, 50, 100],
                emptyRowsWhenPaging: false,
              }}
              onRowClick={handleRowClick}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
