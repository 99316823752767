import * as WeatherForecasts from "./WeatherForecasts";
import * as Counter from "./Counter";
import * as ApplicationBackdrop from "./ApplicationBackdrop";
import * as SnackBar from "./Snackbar";
import * as Access from "./Access";
import * as GroupSelector from "./GroupSelector";
import * as DeptGroupSelector from "./DeptGroupSelector";
import * as ItdGroupSelector from "./ItdGroupSelector";

// The top-level state object
export interface ApplicationState {
  counter: Counter.CounterState | undefined;
  weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
  applicationBackdrop: ApplicationBackdrop.AppBackdropState | undefined;
  snackBar: SnackBar.SnakBarStateType | undefined;
  access: Access.AccessState;
  groupSelector: GroupSelector.GroupSelectorState;
  deptGroupSelector: DeptGroupSelector.DeptGroupSelectorState;
  itdGroupSelector: ItdGroupSelector.ItdGroupSelectorState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  counter: Counter.reducer,
  weatherForecasts: WeatherForecasts.reducer,
  applicationBackdrop: ApplicationBackdrop.reducer,
  snackBar: SnackBar.reducer,
  access: Access.reducer,
  groupSelector: GroupSelector.reducer,
  deptGroupSelector: DeptGroupSelector.reducer,
  itdGroupSelector: ItdGroupSelector.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
