const { PUBLIC_URL, API_URL } = process.env;


export const msalConfig = {
  auth: {
    clientId: "0e9c5e55-5c88-4d7f-9abd-ac7edeff96d2",
    authority: "https://login.microsoftonline.com/ca71c0aa-a028-43ec-8c30-056b60ed7414", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: PUBLIC_URL,
    resource: API_URL
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read", "offline_access"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const fleetScopes = {
  scopes: ["api://0e9c5e55-5c88-4d7f-9abd-ac7edeff96d2/access_as_user", "offline_access"],
};

export const graphScopes = {
  scopes: ['User.Read.All'],
}