import { Box, Button, CircularProgress, Paper, Stack } from '@mui/material';
import { green } from '@mui/material/colors';
import _ from 'lodash';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import useAccessToken from '../../../hooks/useAccessToken';
import { carImageUploadService } from '../../../services/document';

export default function DocumentDropZone({
  carId,
  handleClose,
}: {
  carId: string;
  handleClose: Function;
}) {
  const { accessToken } = useAccessToken();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 1,
  });

  const acceptedFileItems = acceptedFiles.map((file: File, idx: number) => (
    <h6 key={idx}>{file.name}</h6>
  ));

  // TODO: display image preview

  const handleSave = () => {
    setIsLoading(true);
    let documentFormData = new FormData();
    if (!_.isEmpty(acceptedFiles)) {
      documentFormData.append('file', acceptedFiles[0]);
      documentFormData.append('carId', carId);
      documentFormData.append('fileName', acceptedFiles[0].name);
    }
    carImageUploadService(accessToken, carId, documentFormData)
      .then((response) => {
        console.log(response);
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <Stack direction='column' spacing={1} sx={{ width: 400 }}>
      <Paper
        elevation={3}
        {...getRootProps()}
        sx={{
          borderStyle: 'dashed',
          borderWidth: 2,
          borderColor: 'rgb(0,0,0,0.5)',
          borderRadius: 2,
          width: 400,
          paddingTop: 1,
          paddingBottom: 1,
        }}
        className='text-center'
      >
        <input {...getInputProps()} disabled={isLoading} />
        <p>Drag 'n' drop image files here, or click to select a file</p>
        <em>(Only *.jpeg and *.png images will be accepted)</em>
      </Paper>
      <Stack direction='row' spacing={1} justifyContent='space-between'>
        <aside>{acceptedFileItems}</aside>

        <Box sx={{ ml: 1, position: 'relative' }}>
          <Button
            disabled={_.isEmpty(acceptedFiles)}
            size='small'
            variant='contained'
            color='info'
            onClick={handleSave}
          >
            Save
          </Button>

          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
