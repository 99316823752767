import React from 'react';
import {
  CarPreventativeMaintenance,
  CAR_PM_TABLE_COLUMNS,
} from '../../../../models/CarPreventativeMaintenance';
import { CarType } from '../../../../models/FleetAsset';

import MaterialTable from '@material-table/core';
import urlJoin from 'url-join';
import LoadingSpinner from '../../../../components/Loading';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';

const PM_REFERENCE_URL = (id: number) => {
  return urlJoin('api/car', id.toString(), 'pm');
};

export default function PreventativeMaintenanceTable({
  parentCar: { id },
}: {
  parentCar: CarType;
}) {
  const dataUrl: string = PM_REFERENCE_URL(id);
  const { response, error, loading } =
    useTokenGetRequest<CarPreventativeMaintenance[]>(dataUrl);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <MaterialTable
        columns={CAR_PM_TABLE_COLUMNS}
        data={response ?? []}
        title={'Preventative Maintenance'}
      ></MaterialTable>
    </>
  );
}
