import React from 'react';

import MaterialTable, { Column } from '@material-table/core';
import urlJoin from 'url-join';


const MILEAGE_REFERENCE_URL = (id: number) =>
  urlJoin('api/car', id.toString(), 'mileage');

export default function ReservationTable() {
  /// if needed get user Id from url 
  // ex:
  // interface SingleUserUrlProps { id: string;}
  //  const { id } = useParams<SingleUserUrlProps>();

    interface ReservationType {
        active: string,
        id: number,
        out: number,
        in: string,

    }
    
    
    const CAR_MILEAGE_TABLE_COLUMNS: Array<Column<ReservationType>> = [
        {title: "Column 1", field: 'active'},
        {title: "Column 2", field: 'id'},
        {title: "Column 3", field: 'out'},
        {title: "Column 4", field: 'in'},
    ]

  return (
    <>
      <MaterialTable
        columns={CAR_MILEAGE_TABLE_COLUMNS}
        data={[]}
        title={'PLACE HOLDER TABLE'}
      ></MaterialTable>
    </>
  );
}
