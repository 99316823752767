import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '../Drawer';
import Copyright from './Copyright';
import { DRAWER_WIDTH, APP_HEADER_NAME } from '../../constants/layout';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export default function Public({ children }: { children?: React.ReactNode }) {
  const backdrop = useSelector(
    (state: ApplicationState) => state.applicationBackdrop
  );

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000000 }}
        open={backdrop?.isLoading ?? false}
      >
        <CircularProgress size='18em' thickness={4} color='secondary' />
      </Backdrop>

      <AppBar position='absolute'>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            component='div'
            sx={{ fontStyle: 'italic', width: 'auto' }}
            variant='h5'
            color='inherit'
            noWrap
          >
            {APP_HEADER_NAME}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
        </Toolbar>
      </AppBar>

      <Toolbar />
      <main
        style={{
          height: '100%',
        }}
      >
        <Grid
          container
          spacing={3}
          direction='column'
          justifyContent='space-between'
          alignItems='stretch'
          style={{ height: '100%' }}
        >
          <Grid item xs={12}>
            {children}
          </Grid>
          <Grid item xs={12} alignContent='start'>
            <Copyright sx={{ pt: 4 }} />
          </Grid>
        </Grid>
      </main>
    </>
  );
}
