import * as React from 'react';
import useAccessToken from '../../../../hooks/useAccessToken';
import { Box, Button, CircularProgress, DialogActions, DialogContent, FormControl, Grid, IconButton, TextField, } from '@mui/material';
import Dialog from '../../../../components/Dialog';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Add } from '@mui/icons-material';
import DateTimePicker from '../../../Home/Reservations/DateTimePicker';
import { green } from '@mui/material/colors';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import { CarMileageReading } from '../../../../models/CarMileageReading';
import { toHumanFriendlyDate } from '../../../../utils/timeHelper';
import { mileageAddService } from '../../../../services/mileage';
import moment from 'moment';

interface MileageSubmission {
    parentId: number,
    mileage: number | null,
    transactionDate: string
}

const MileageSchema = yup.object({
    parentId: yup.number().required('Required'),
    mileage: yup.number().required('Required'),
    transactionDate: yup.string().required('Required'),
});

type MileageSchemaType = yup.InferType<
    typeof MileageSchema
>;

const CAR_LATEST_MILEAGE_URL = (carId: number) => `api/car/${carId}/latestMileage`;

export default function AddMileage({id, updateFunction}: {id: number, updateFunction: () => void})
{
    const { accessToken } = useAccessToken();
    const [confirmAddition, setAdditionStatus] = React.useState<boolean>(false);
    const [showDialog, setDialogStatus] = React.useState<boolean>(false);
    const [dialogLoading, setLoadingStatus] = React.useState<boolean>(false);

    const { response, error, loading } =
      useTokenGetRequest<CarMileageReading>(CAR_LATEST_MILEAGE_URL(id));

      
    const formik = useFormik({
        initialValues: {
            parentId: id,
            mileage: null,
            transactionDate: moment(new Date).format('YYYY-MM-DDTHH:mm')
        } as MileageSubmission,
        validationSchema: MileageSchema,
        onSubmit: (values: MileageSubmission, { resetForm}) => {
            console.log("upload mileage");
                mileageAddService(accessToken, id, values).then(data => {
                closeDialog();
                updateFunction();
                resetForm();
            })
            
        }
    })
    
    const closeDialog = () => {
        setAdditionStatus(false);
        setDialogStatus(false);
        formik.resetForm();
    }

    const displayDialogContentByState = () => {
        return (
            <>
                <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
                    <DialogContent>                            
                        <h3 className="text-center">Add Mileage</h3>
                        <hr />
                        <Grid container direction='column' spacing={1}>
                            <Grid item>
                                {loading ? (<></>): 
                                (<p style={{textAlign: 'center'}}>Last Reported Reading: <br /><b>{response?.reading}</b> on <br />{toHumanFriendlyDate(response?.transactionDate ?? null)}</p>)}
                            </Grid>
                            <Grid item>
                                <DateTimePicker
                                    date={moment(formik.values.transactionDate).toDate()}
                                    title='Date of Odometer Reading'
                                    handleDate={formik.handleChange} />
                            </Grid>
                            <Grid item>
                                <TextField 
                                    id='mileage' 
                                    onChange={formik.handleChange}
                                    disabled={formik.isSubmitting}
                                    value={formik.values.mileage}
                                    required
                                    fullWidth
                                    autoFocus
                                    margin='dense'
                                    label="Odometer Reading"
                                    error={formik.touched.mileage && Boolean(formik.errors.mileage)}
                                    helperText={formik.touched.mileage && formik.errors.mileage}
                                    variant='outlined' />
                            </Grid>
                           
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Box>
                            <Button disabled={formik.isSubmitting} color='secondary' type='button' className="mr-2" onClick={closeDialog}>
                                Cancel
                            </Button>
                            <Button disabled={formik.isSubmitting} variant='outlined' type='submit'>
                                Add
                            </Button> 
                           
                            {
                                dialogLoading && (
                                <CircularProgress 
                                    size={24} 
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                    />
                                )
                            }
                        </Box>
                    </DialogActions>
                </form>
            </>
        )
    }

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item>
                <IconButton onClick={() => setDialogStatus(true)}><Add /></IconButton>
            </Grid>
            <Grid item>
            {
                showDialog ? 
                (
                <Dialog 
                    maxWidth='md'
                    open={showDialog}
                    fullScreen={false}
                    clickClose={closeDialog}>
                        {displayDialogContentByState()}
                </Dialog>
                ): 
                (<></>)
            }
        </Grid>
        </Grid>
        
    )

}