import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import Tabs from '../../components/Tabs';
import { tabConfigType } from '../../models/Tabs';
import { ApplicationState } from '../../store';
import {
  ItdGroupSelectionActions,
  ItdGroupSelectorState,
} from '../../store/ItdGroupSelector';
import GroupSelector from '../components/groupSelector';
import User from '../components/users';
import Vehicles from '../components/vehiclesAlternative';
import SectionName from '../../components/SectionName';

export default function index() {
  const { userUrl, carUrl, group } = useSelector<
    ApplicationState,
    ItdGroupSelectorState
  >((state) => state.itdGroupSelector);

  const tabConfig: tabConfigType[] = [
    { label: 'Users', key: 0, component: <User url={userUrl} /> },
    {
      label: 'Vehicles',
      key: 1,
      component: <Vehicles url={carUrl} hideDeptNumber={false} />,
    },
  ];

  return (
    <Grid container direction='column' spacing={2}>
              <Grid item>
          <SectionName title={'Application Management'} />
        </Grid>
      <Grid item>
        <GroupSelector
          group={group}
          setGroup={ItdGroupSelectionActions.setItdGroup}
        />
      </Grid>
      <Grid item>
        <Tabs tabConfig={tabConfig} />
      </Grid>
    </Grid>
  );
}
