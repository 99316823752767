import { Button, CardActions, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import InfoCard from '../../../components/Card/InfoCard';
import { InfoCardsType } from '../../../models/InfoCards';
import Notifications from '../../components/Notifications/Notifications';

const HEIGHT: number = 140;

//TODO: remove coming soon
const content = (title: string, soon: boolean = false): React.ReactNode => (
  <CardContent>
    <Typography sx={{ fontSize: 20 }} color='text.secondary' gutterBottom>
      {title}
    </Typography>
    {soon && (
      <Typography sx={{ fontSize: 20 }} color='text.secondary' gutterBottom>
        COMING SOON
      </Typography>
    )}
  </CardContent>
);

const action: React.ReactNode = (
  <CardActions>
    <Button size='small'>Learn More</Button>
  </CardActions>
);

const notificationContent = (title: string): React.ReactNode => (
  <CardContent>
    <Typography sx={{ fontSize: 20 }} color='text.secondary' gutterBottom>
      {title}
    </Typography>
    <Notifications />
  </CardContent>
);

const ASSIGNED_COLOR = '#FFE599';
const MAINTENANCE_COLOR = '#FBBBCA';

export const createInfoCardContent = (): InfoCardsType => {
  const history = useHistory();

  const reservationActions: React.ReactNode[] = [
    <Button size='small' onClick={() => history.push('/group/checkedout')}>
      View Vehicles
    </Button>,
  ];

  const notificationActions: React.ReactNode[] = [
    <Button size='large' onClick={() => history.push('/group/notifications')}>
      View
    </Button>,
  ];

  return {
    reservation: (
      <InfoCard
        content={content('Number of Checked out Vehicles')}
        height={HEIGHT}
        actions={reservationActions}
      />
    ),
    assigned: (
      <InfoCard
        content={notificationContent('User Reported Issues')}
        height={HEIGHT}
        color={ASSIGNED_COLOR}
        actions={notificationActions}
      />
    ),
    actions: (
      <InfoCard
        content={content('Vehicle Maintenance (next 30 days)', true)}
        height={HEIGHT}
        color={MAINTENANCE_COLOR}
      />
    ),
  };
};
