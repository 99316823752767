import React from 'react';
import { Column } from "@material-table/core"
import { formatMialage } from '../utils/carHelper';
import { toHumanFriendlyDate } from '../utils/timeHelper';
import MileageActions from '../views/components/vehiclesAlternative/components/MileageActions';

export interface CarMileageReading {
    carId: number,
    reading: number,
    transactionDate: string,
    dataSource: string,
    id: number,    
    meterTransactionId: number
}


export const CAR_MILEAGE_TABLE_COLUMNS: (updateFunction: () => void) => Array<Column<CarMileageReading>> = (updateFunction) => {
    let reading: Array<Column<CarMileageReading>> =  [
        {title: "Date Tracked", field: "transactionDate", render: rowData => 
            rowData.transactionDate !== null ? (toHumanFriendlyDate(rowData.transactionDate)): ""},
        {title: "Miles", field: "reading", render: rowData => formatMialage(rowData.reading)},
        {title: "Actions", field: "id", render: (rowData, type) => {
            if(type == "row" && rowData.dataSource == "fleet"){
                return (<MileageActions id={rowData.id} updateFunction={updateFunction} />)
            }
            else 
            {
                return "";
            }
        }}
    ]
    return reading;
}