import internal from 'assert';
import * as React from 'react';
import useAccessToken from '../../../hooks/useAccessToken';
import { CarType } from "../../../models/FleetAsset";

import Dialog from '../../../components/Dialog';
import { CreateNewFolder, CloudDone, Upload } from '@mui/icons-material';
import { Button, IconButton, Grid, DialogContent, TextField, DialogActions, Box, CircularProgress, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { green } from '@mui/material/colors';
import { folderCreateService } from '../../../services/document';

enum UploadState {
    Pending, 
    Uploading,
    Uploaded
}

interface UploadFolder {
    name: string, 
    parentId: number,
    parentType: number,
    type: string
}

const categoriesType = [
    {name: "insurace", label: "Insurance"},
    {name: "accident", label: "Accident Report"}
]

const UploadFolderSchema = yup.object({
    name: yup.string().required('Required'),
    parentId: yup.number().required('Required'),
    parentType: yup.number().required('Required'),
    type: yup.string().required('Required')
});
type UploadFolderSchemaType = yup.InferType<
    typeof UploadFolderSchema
>;

const createMenuItems = (): JSX.Element[] => {
    return categoriesType.map(item => (<MenuItem value={item.name}>{item.label}</MenuItem>))
}

export default function UploadFolder({
    parentCar,
    showLabel = true,
    updateFunction
}: {
    parentCar: number,
    showLabel?: boolean,
    updateFunction: () => void
}){
    const { accessToken } = useAccessToken();
    const [open, setUploadDialogOpen] = React.useState<boolean>(false);
    const [uploadStatus, setUploadStatus] = React.useState<UploadState>(UploadState.Pending);
    const [loading, setLoadingStatus] = React.useState<boolean>(false);
 
    const handleNewFolderDialogClose = () => {

        setUploadDialogOpen(false);
        setUploadStatus(UploadState.Pending);
    }

    const formik = useFormik({
        initialValues: {
            parentId: parentCar,
            parentType: 1,
            name: "",
            type: ""
        } as UploadFolder,
        validationSchema: UploadFolderSchema,
        onSubmit: (values: UploadFolder, { resetForm}) => {
            console.log("upload");
            folderCreateService(accessToken, values).then(data => {
                setUploadStatus(UploadState.Uploaded);
                updateFunction();
                resetForm();
            });
        }
    })

    const displayDialogContentByState = () => {
        switch(uploadStatus){
            case UploadState.Pending: 
                return (
                    <>
                        <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
                            <DialogContent>                            
                                <h3 className="text-center">Create Folder</h3>
                                <hr />
                                <Grid container direction='column' spacing={1}>
                                    <Grid item>
                                        <TextField 
                                            id='name' 
                                            onChange={formik.handleChange}
                                            disabled={formik.isSubmitting}
                                            value={formik.values.name}
                                            required
                                            fullWidth
                                            autoFocus
                                            margin='dense'
                                            label="Folder Name"
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                            variant='outlined' />
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <InputLabel id="type-label">Folder Type</InputLabel>
                                            <Select
                                                disabled={formik.isSubmitting}
                                                labelId="type-label"
                                                fullWidth
                                                margin='dense'
                                                id='type'
                                                label="Folder Type"
                                                value={formik.values.type}
                                                onChange={(event) => formik.setFieldValue("type", event.target.value)}
                                                error={formik.touched.type && Boolean(formik.errors.name)}
                                                >
                                                {createMenuItems()}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Box>
                                    <Button disabled={formik.isSubmitting} type='submit'>
                                        Create
                                    </Button> 
                                    {
                                        loading && (
                                        <CircularProgress 
                                            size={24} 
                                            sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                            />
                                        )
                                    }
                                </Box>
                            </DialogActions>
                        </form>
                    </>
                )
            case UploadState.Uploaded:
                return (
                    <>  
                        <DialogContent>
                            <Grid container justifyContent="center" direction='column' alignItems='center'>
                                <Grid item>
                                    <CloudDone sx={{fontSize:'4rem'}} />
                                </Grid>
                                <Grid item>
                                    <h3>Folder Created</h3>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </>
                )
            default:
                return(<></>);
        }
    }

    return (
        <Grid container direction='column'>
            <Grid item>
                {
                    showLabel ? 
                        (<Button variant='contained' onClick={() => setUploadDialogOpen(true)}>AttachFolder</Button>):
                        (<IconButton onClick={() => setUploadDialogOpen(true)}><CreateNewFolder /></IconButton>)
                }
            </Grid>
            <Grid item>
                {open ? 
                (
                    <Dialog
                        maxWidth='md'
                        open={open}
                        fullScreen={false}
                        clickClose={handleNewFolderDialogClose}
                        >
                            {displayDialogContentByState()}
                    </Dialog>
                ): (
                    <></>
                )}
            </Grid>
        </Grid>
    )
}