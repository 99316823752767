import { AxiosResponse } from 'axios';
import { access } from 'fs';
import queryString from 'query-string';
import urlJoin from 'url-join';
import { string } from 'yup';
import httpClient from '../utils/httpClient';

//const responseBody = (response: AxiosResponse): GroupType => response.data;

const documentRequests = {
  get: (accessToken: string, url: string) =>
    httpClient.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  //.then(responseBody)
  post: (accessToken: string, url: string, body: any) =>
    httpClient.post(url, body, {
      headers: {
        ContentType: 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  //.then(responseBody)
  delete: (accessToken: string, url: string) =>
    httpClient.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  move: (accessToken: string, url: string, body: any) =>
    httpClient.post(url, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
};

const folderRequests = {
  post: (accessToken: string, url: string, body: any) =>
    httpClient.post(url, body, {
      headers: {
        ContentType: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }),
  delete: (accessToken: string, url: string) =>
    httpClient.delete(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }),
};

export function documentCreateService(
  accessToken: string,
  documents: FormData
): Promise<any> {
  return documentRequests.post(
    accessToken,
    urlJoin('api', 'document'),
    documents
  );
}

export function documentDeleteService(
  accessToken: string,
  documentId: string
): Promise<any> {
  return documentRequests.delete(
    accessToken,
    urlJoin('api', 'document', documentId)
  );
}

export function documentGetService(
  accessToken: string,
  documentId: string
): Promise<any> {
  return documentRequests.get(
    accessToken,
    urlJoin('api', 'document', documentId)
  );
}

export function folderCreateService(
  accessToken: string,
  folderData: any
): Promise<any> {
  return folderRequests.post(accessToken, urlJoin('api', 'folder'), folderData);
}

export function folderDeleteService(
  accessToken: string,
  folderId: string
): Promise<any> {
  return folderRequests.delete(accessToken, urlJoin('api', 'folder', folderId));
}

export function documentMoveToFolder(
  accessToken: string,
  documentId: string,
  folderId: string | null
): Promise<any> {
  return documentRequests.move(
    accessToken,
    urlJoin('api', 'document', documentId, 'move'),
    { folderId: folderId }
  );
}

// create carImageUploadService function that takes in accessToken and FormData
export function carImageUploadService(
  accessToken: string,
  carId: string,
  carImage: FormData
): Promise<any> {
  // return documentRequests.post with the urlJoin of api and carImage
  return documentRequests.post(
    accessToken,
    urlJoin('api', 'car', 'image'),
    carImage
  );
}

export function unarchiveDocument(
  accessToken: string,
  documentId: string
): Promise<any> {
  return documentRequests.post(
    accessToken, 
    urlJoin("api", "document", documentId, "unarchive"),
    null
  );
}