import { Column } from '@material-table/core';
import IAttachableObject from './IAttachableObject';
export enum RoleType {
  BASIC = 'Basic',
  GROUP = 'Group',
  DEPARTMENT = 'Department',
  FLEET = 'Fleet',
  ITD = 'Itd',
}

export interface ProfileType extends IAttachableObject {
  id: number;
  azureId: string;
  name: string | null;
  dept: {
    departmentId: string | null;
    departmentName: string | null;
  } | null;
  unit: string | null;
  isDeptAdmin: boolean;
  isFleetAdmin: boolean;
  isItdadmin: boolean;
  groups: any;
  adminGroups: any;
  userGroups: any;
  groupAdmin: boolean;
  impersonation: boolean;
  storageToken: string | null;
}

export const userTableColumns: Array<Column<ProfileType>> = [
  {
    title: 'Name',
    field: 'name',
    editable: 'never',
  },
  {
    title: 'Unit',
    field: 'unit',
    editable: 'never',
  },
  {
    title: 'Department',
    field: 'dept.departmentName',
    editable: 'never',
  },
  {
    title: 'Dept. Admin',
    field: 'isDeptAdmin',
    lookup: { true: 'Yes', false: 'No' },
    editable: 'never',
  },
];
