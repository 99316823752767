import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { ProfileType } from '../models/Access';
import { GroupInitType, GroupType } from '../models/Group';
import httpClient from '../utils/httpClient';

const responseBody = (response: AxiosResponse): GroupType => response.data;
const responseUserBody = (response: AxiosResponse): ProfileType[] =>
  response.data;
const postResponse = (response: AxiosResponse): AxiosResponse => response;

const requests = {
  get: (accessToken: string, url: string) =>
    httpClient
      .get(url, {
        headers: {
          ContentType: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(responseUserBody),
  post: (accessToken: string, url: string, body: any) =>
    httpClient
      .post(url, body, {
        headers: {
          ContentType: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(responseBody),
  postUsers: (accessToken: string, url: string, body: any) =>
    httpClient
      .post(url, body, {
        headers: {
          ContentType: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(postResponse),
  put: (url: string, body: any) =>
    httpClient.put(url, queryString.stringify(body)).then(responseBody),
};

export default function groupCreateService(
  accessToken: string,
  group: GroupInitType
): Promise<GroupType> {
  return requests.post(accessToken, '/api/group', group);
}

export function getGroupUsersService(
  accessToken: string,
  url: string
): Promise<ProfileType[]> {
  return requests.get(accessToken, url);
}

export interface groupUserAddType {
  azureGuidId: string;
  role: string;
  Department?: string;
}

export function setGroupUsersService(
  accessToken: string,
  url: string,
  body: groupUserAddType[]
): Promise<AxiosResponse> {
  return requests.postUsers(accessToken, url, body);
}

export function setGroupCarsService(
  accessToken: string,
  url: string,
  body: any
): Promise<AxiosResponse> {
  return requests.postUsers(accessToken, url, body);
}
