import React from 'react';

import { CarType } from '../../../../models/FleetAsset';

import MaterialTable from '@material-table/core';
import urlJoin from 'url-join';
import LoadingSpinner from '../../../../components/Loading';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import {
  CarScheduledMaintenance,
  CAR_SM_TABLE_COLUMNS,
} from '../../../../models/CarScheduledMaintenance';

const SM_REFERENCE_URL = (id: number) => {
  return urlJoin('api/car', id.toString(), 'sm');
};

export default function ScheduledMaintenanceTable({
  parentCar: { id },
}: {
  parentCar: CarType;
}) {
  const dataUrl = SM_REFERENCE_URL(id);
  const { response, error, loading } =
    useTokenGetRequest<CarScheduledMaintenance[]>(dataUrl);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <MaterialTable
        columns={CAR_SM_TABLE_COLUMNS}
        data={response?.sort() ?? []}
        title={'Preventative Maintenance'}
      ></MaterialTable>
    </>
  );
}
