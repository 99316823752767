import { AxiosResponse } from 'axios';
import { access } from 'fs';
import queryString from 'query-string';
import urlJoin from 'url-join';
import { string } from 'yup';
import httpClient from '../utils/httpClient';

const documentRequests = {
    get: (accessToken: string, url: string) =>
      httpClient.get(url, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    //.then(responseBody)
    post: (accessToken: string, url: string, body: any) =>
      httpClient.post(url, body, {
        headers: {
          ContentType: 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
    }),
    delete: (accessToken: string, url: string) =>
      httpClient.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
}

export function mileageDeleteService(
    accessToken: string,
    mileageId: string
  ): Promise<any> {
    return documentRequests.delete(
      accessToken,
      urlJoin('api', 'mileage', mileageId)
    );
  }

export function mileageAddService(
    accessToken:string,
    carId: number,
    body: any
): Promise<any> {
    return documentRequests.post(
        accessToken,
        urlJoin("api", "car", carId.toString(), "mileage"), 
        body
    );
}