import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

export interface ItdGroupSelectorState {
  isLoading: boolean;
  group: string;
  carUrl: string;
  userUrl: string;
}

const initialState: ItdGroupSelectorState = {
  isLoading: true,
  group: 'all',
  carUrl: '/api/car/admin',
  userUrl: '/api/User',
};

export interface SetItdGroupAction {
  type: 'SET_ITD_GROUP';
  group: string;
  carUrl: string;
  userUrl: string;
}

export interface SetDefautlAction {
  type: 'SET_ITD_DEFAULT';
  group: string;
  carUrl: string;
  userUrl: string;
}

export type KnownAction = SetItdGroupAction | SetDefautlAction;

const getItdGroupUrls = (groupName: string) => {
  switch (groupName.toLocaleLowerCase()) {
    case 'all':
      return { carUrl: '/api/car', userUrl: '/api/User' };

    default:
      return {
        carUrl: `/api/Car/group?groupId=${groupName}`,
        userUrl: `/api/group/${groupName}/members`,
      };
  }
};

export const ItdGroupSelectionActions = {
  setItdGroup:
    (group: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const appState = getState();

      const groupUrls = getItdGroupUrls(group);

      dispatch({
        type: 'SET_ITD_GROUP',
        group,
        ...groupUrls,
      });
    },
  setItdGroupDefault:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      const appState = getState();

      dispatch({
        type: 'SET_ITD_DEFAULT',
        group: 'all',
        carUrl: '/api/car/admin',
        userUrl: '/api/User',
      });
    },
};

export const reducer: Reducer<ItdGroupSelectorState> = (
  state: ItdGroupSelectorState | undefined,
  incomingAction: Action
): ItdGroupSelectorState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'SET_ITD_GROUP':
      return {
        group: action.group,
        carUrl: action.carUrl,
        userUrl: action.userUrl,
        isLoading: false,
      };
    case 'SET_ITD_DEFAULT':
      return {
        group: action.group,
        carUrl: action.carUrl,
        userUrl: action.userUrl,
        isLoading: false,
      };
    default:
      return state;
  }
};
