import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, CircularProgress, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import Dialog from '../../../components/Dialog';
import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import httpClient from '../../../utils/httpClient';

const CANCEL_RESERVATION_URL = (reservationId: string | number) =>
  `/api/Reservation/${reservationId}`;

interface CancelReservationButtonProps {
  reservationId: number;
  refresh: Function;
}

export default function CancelReservationButton({
  reservationId,
  refresh,
}: CancelReservationButtonProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const cancelReservation = () => {
    setLoading(true);
    httpClient
      .request({
        url: CANCEL_RESERVATION_URL(reservationId),
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(() => {
        refresh();
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
        setLoading(false);
      })
      .catch(() => {
        createNotification({
          message: 'Error while Canceling.',
          options: { variant: 'error' },
        });
      })
      .finally(() => handleDialogClose());
  };

  return (
    <>
      <Button
        aria-label='Cancel reservation'
        color='error'
        size='small'
        fullWidth
        onClick={() => handleDialogOpen()}
      >
        cancel
      </Button>

      {open && (
        <Dialog
          maxWidth='md'
          open={open}
          fullScreen={false}
          title={'Cancel Reservation'}
          clickClose={handleDialogClose}
        >
          <DialogContent sx={{ width: '400px' }}>
            <Stack
              direction='row'
              spacing={2}
              justifyContent='center'
              alignItems='center'
            >
              Confirm you want to cancel this reservation?
              <Box sx={{ m: 1, position: 'relative' }}>
                <IconButton
                  aria-label='delete'
                  size='large'
                  onClick={() => cancelReservation()}
                  color='success'
                >
                  <CheckIcon fontSize='inherit' />
                </IconButton>

                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
              <IconButton
                aria-label='cancel'
                size='large'
                onClick={() => handleDialogClose()}
                color='error'
              >
                <ClearIcon fontSize='inherit' />
              </IconButton>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
