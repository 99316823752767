import Typography from '@mui/material/Typography';
import React from 'react';
import NewReservation from './NewReservation';

export default function DeskTopReservation() {
  return (
    <>
      <Typography
        variant='h5'
        align='left'
        color='secondary'
        sx={{ fontWeight: 'italic' }}
      >
        Reservation Form
      </Typography>
      <NewReservation />
    </>
  );
}
