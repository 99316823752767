import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { OptionsObject } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fleetScopes, graphScopes } from '../authConfig';
import { snackBarActions } from '../store/Snackbar';

const errorOptions: OptionsObject = {
  variant: 'error',
};

const errorMessage = 'Graph Api connection Error.';

export default function useGraphToken() {
  const [graphToken, setGraphToken] = useState<string>('');
  const { instance, accounts, inProgress } = useMsal();
  const [counter, setCounter] = useState<number>(0);

  const refreshGraphToken = () => setCounter(counter + 1);
  const dispatch = useDispatch();

  const fetchToken = () => {
    if (accounts && inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(graphScopes)
        .then((response) => {
          if (response) {
            setGraphToken(response.accessToken);
          }
        })
        .catch((error: any) => {
          instance.acquireTokenPopup(fleetScopes).then(response => {
            if(response){
              setGraphToken(response.accessToken);
            }
          });
          dispatch(
            snackBarActions.enqueueSnackbar({
              options: { ...errorOptions },
              message: errorMessage,
            })
          );
        });
    }
  };

  useEffect(() => {
    let isMounted = true;
    fetchToken();
    return () => {
      isMounted = false;
    };
  }, [counter]);

  return { graphToken, refreshGraphToken };
}
