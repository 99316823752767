import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { OptionsObject } from 'notistack';
import { useHistory, useParams } from 'react-router';
import urljoin from 'url-join';
import Image from '../../../../components/Profile/Image';
import useAccessToken from '../../../../hooks/useAccessToken';
import useNotifier from '../../../../hooks/useNotifier';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import { ProfileType } from '../../../../models/Access';
import {
  ItdUserAssignmentVm,
  NewItdUserAssignment,
} from '../../../../models/ItdAdminAssignmentVm';
import setUserIsAdmin from '../../../../services/user';
import { ApplicationState } from '../../../../store';
import { AccessState } from '../../../../store/Access';
import RelatedUserTabs from './RelatedUserTabs';
import UserInfo from './UserInfo';

const SINGLE_USER_URL = (id: string): string => urljoin('/api/user', id);
const USER_INFO_URL = (id: string): string =>
  urljoin('https://graph.microsoft.com/v1.0/users', id);
const USER_PHOTO_URL = (id: string): string =>
  urljoin(USER_INFO_URL(id), '/photo/$value');

interface SingleUserUrlProps {
  id: string;
}

export default function SingleUserView() {
  const { profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );
  const history = useHistory();
  const { createNotification } = useNotifier();
  const { id } = useParams<SingleUserUrlProps>();
  const singleUserUrl = SINGLE_USER_URL(id);
  const { response, error, loading, refresh } =
    useTokenGetRequest<ProfileType>(singleUserUrl);
  const [hideSwitch, setHideSwitch] = useState<boolean>(loading);

  const [caSetAdmin, setCanSetAdmin] = useState<boolean>(
    (profile?.isDeptAdmin ?? false) || (profile?.isItdadmin ?? false)
  );

  const { accessToken, refreshToken } = useAccessToken();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) setHideSwitch(loading);

    return () => {
      isMounted = false;
    };
  }, [loading]);

  const successOptions: OptionsObject = {
    variant: 'success',
  };

  const errorOptions: OptionsObject = {
    variant: 'error',
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHideSwitch(true);
    if (_.isBoolean(event.target.checked) && response !== null) {
      // refreshToken();
      const thisUserToAdmin = new NewItdUserAssignment({
        ...response,
        isDeptAdmin: event.target.checked,
      });
      setToAdmin(thisUserToAdmin);
    }
  };

  const setToAdmin = (userAdminVm: ItdUserAssignmentVm) => {
    setUserIsAdmin(accessToken, userAdminVm)
      .then(() => {
        createNotification({ message: 'Success', options: successOptions });
        refresh();
      })
      .catch(() => {
        createNotification({
          message: 'Connection Error',
          options: errorOptions,
        });
        refresh();
      });
  };

  return (
    <>
      <Button color='secondary' onClick={() => history.goBack()}>
        go back
      </Button>
      <br />
      {response && (
        <Grid
          container
          spacing={2}
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-end'
        >
          <Grid item>
            <Image
              userName={'Daniels, Frank'}
              imgUrl={USER_PHOTO_URL(response.azureId)}
              addAdminCheck={response.isDeptAdmin}
            />
          </Grid>
          <UserInfo url={USER_INFO_URL(response.azureId)} user={response} />
        </Grid>
      )}
      <br />
      {caSetAdmin && (
        <>
          <Stack direction='row' spacing={1} alignItems='center'>
            <Typography>Department Admin</Typography>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Switch
                disabled={hideSwitch}
                checked={response ? response.isDeptAdmin : false}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'ant design' }}
              />
              {hideSwitch && (
                <CircularProgress
                  size={24}
                  color='secondary'
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              )}
            </Box>

            <Typography>{response?.isDeptAdmin ? 'Yes' : 'No'}</Typography>
          </Stack>
        </>
      )}
      <br />
      <RelatedUserTabs />
    </>
  );
}
