import React from 'react';
import { tabConfigType } from '../../../models/Tabs';

import Tabs from '../../../components/Tabs';

import { useParams } from 'react-router-dom';
import GroupUsersTable from '../../components/users/GroupUsersTable';
import GroupTable from '../../components/vehiclesAlternative/GroupTable';
const CAR_URL = (groupName: number | string) =>
  `/api/Car/group?groupId=${groupName}`;

const USER_URL = (groupName: number | string) =>
  `/api/group/members?groupId=${groupName}`;

interface GroupUsersProps {
  id: string;
}

export default function GroupManagementTabs() {
  const { id } = useParams<GroupUsersProps>();

  const tabConfig: tabConfigType[] = [
    {
      label: 'Users',
      key: 0,
      component: <GroupUsersTable url={USER_URL(id)} />,
    },
    {
      label: 'Vehicles',
      key: 1,
      component: <GroupTable url={CAR_URL(id)} />,
    },
  ];

  return (
    <>
      <Tabs tabConfig={tabConfig} />
    </>
  );
}
