import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';

const CARS_GROUP_URL_DEFAULT = '/api/car/group';
const USERS_GROUP_URL_DEFAULT = '/api/group/members';

export interface  GroupSelectorState {
  isLoading: boolean;
  group: string;
  carUrl: string;
  userUrl: string;
}

const initialState: GroupSelectorState = {
  isLoading: true,
  group: 'all',
  carUrl: CARS_GROUP_URL_DEFAULT,
  userUrl: USERS_GROUP_URL_DEFAULT,
};

export interface SetGroupAction {
  type: 'SET_GROUP';
  group: string;
  carUrl: string;
  userUrl: string;
}

export interface SetDefautlAction {
  type: 'SET_DEFAULT';
  group: string;
  carUrl: string;
  userUrl: string;
}

export type KnownAction = SetGroupAction | SetDefautlAction;

const getGroupUrls = (groupName: string) => {
  switch (groupName.toLocaleLowerCase()) {
    case 'all':
      return { carUrl: CARS_GROUP_URL_DEFAULT, userUrl: USERS_GROUP_URL_DEFAULT };

    default:
      return {
        carUrl: `${CARS_GROUP_URL_DEFAULT}?groupId=${groupName}`,
        userUrl: `${USERS_GROUP_URL_DEFAULT}?groupId=${groupName}`,
      };
  }
};

export const GroupSelectionActions = {
  setAGroup:
    (group: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const appState = getState();

      const groupUrls = getGroupUrls(group);

      dispatch({
        type: 'SET_GROUP',
        group,
        ...groupUrls,
      });
    },
  setGroupDefautl: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();

    dispatch({
      type: 'SET_DEFAULT',
      group: 'all',
      carUrl: CARS_GROUP_URL_DEFAULT,
      userUrl: USERS_GROUP_URL_DEFAULT,
    });
  },
};

export const reducer: Reducer<GroupSelectorState> = (
  state: GroupSelectorState | undefined,
  incomingAction: Action
): GroupSelectorState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'SET_GROUP':
      return {
        group: action.group,
        carUrl: action.carUrl,
        userUrl: action.userUrl,
        isLoading: false,
      };
    case 'SET_DEFAULT':
      return {
        group: action.group,
        carUrl: action.carUrl,
        userUrl: action.userUrl,
        isLoading: false,
      };
    default:
      return state;
  }
};
