import React, { useState } from 'react';
import { 
  Card, 
  List, 
  ListItem, 
  ListItemText, 
  Stack, 
  Typography, 
  ListItemIcon, 
  IconButton, 
  InputBase, 
  Paper, 
  Divider } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Column } from '@material-table/core';
import Button from '@mui/material/Button';
import { OptionsObject } from 'notistack';
import { useParams } from 'react-router-dom';
import urlJoin from 'url-join';
import { AlternateProps } from '../../../components/TransferList';
import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import { searchResultVmType } from '../../../models/Graph';
import Image from '../../../components/Profile/Image';
import {
  groupUserAddType,
  setGroupUsersService,
} from '../../../services/group';
import UserSearch from '../../../components/UserSearch';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import CustomCheckBox from '../../../components/CustomCheckBox';
import urljoin from 'url-join';
import _ from 'lodash';

const USER_INFO_URL = (id: string): string =>
  urljoin('https://graph.microsoft.com/v1.0/users', id);
const USER_PHOTO_URL = (id: string): string =>
  urljoin(USER_INFO_URL(id), '/photo/$value');
interface GroupUsersProps {
  id: string;
}

interface Props {
  groupId: number;
  clickClose: () => void;
  currentUsers: string[],
}


export default function GroupUsers({ groupId = -1, clickClose, currentUsers }: Props) {
  const { id } = useParams<GroupUsersProps>();
  const { accessToken, refreshToken } = useAccessToken();
  const { createNotification } = useNotifier();

  const [groupUsers, setGroupUsers] = useState<AlternateProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [availableDone, setAvailableDone] = useState<boolean>(false);
  const [memberDone, setMembersDone] = useState<boolean>(true);
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  interface ReservationType {
    active: string;
    id: number;
    out: number;
    in: string;
  }


  const successOptions: OptionsObject = {
    variant: 'success',
  };

  const errorOptions: OptionsObject = {
    variant: 'error',
  };
  const handleRemove = (value: any) => {
    let evens = _.remove(selectedUsers, function (n: any) {
      return n.id !== value.id;
    });
    setSelectedUsers(evens);
  };
  function handleCheck(name: string, checked: boolean) {
    const index = _.findIndex(selectedUsers, { id: name });
    const prev = selectedUsers;
    prev[index].isGroupAdmin = checked;
    setSelectedUsers(prev);
  }

  const handleNewUsers = () => {
    setIsSubmitting(true);
    const postNewUrl = `/api/group/${groupId}/user`;
    const body: groupUserAddType[] = selectedUsers.map(
      (user: searchResultVmType) => {
        return {
          azureGuidId: user.id,
          role: user.isGroupAdmin ? 'Admin' : 'User',
          Department: user.department,
        };
      }
    );

    setGroupUsersService(accessToken, postNewUrl, body)
      .then((res) => {
        createNotification({ message: 'Success', options: successOptions });
      })
      .catch((err) => {
        createNotification({
          message: 'Connection Error',
          options: errorOptions,
        });
      })
      .finally(() => {
        clickClose();
        setIsSubmitting(false);
      });
  };

  const customSelectedList = (
    items: searchResultVmType[],
    message: string = 'Empty'
  ) => {
    if (_.isEmpty(items)) {
      return (
        <Card>
          <List
            sx={{
              width: 600,
              height: 450,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component='div'
            role='list'
          >
            <ListItem key={'#48957-32'} role='listitem'>
              <ListItemText id={'34'} primary={`${message}`} />
            </ListItem>
          </List>
        </Card>
      );
    } else {
      return (
        <Card>
          <List
            sx={{
              width: 600,
              height: 390,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component='div'
            role='list'
          >
            <ListItem key={'#48957-3245'} role='listitem'>
              <Stack direction='row' justifyContent='flex-start'>
                <Typography>Admin</Typography>
              </Stack>
            </ListItem>
            {items.map((value: searchResultVmType) => {
              const labelId = `transfer-list-all-item-${value}-label`;
              let adName: string = value.userPrincipalName.split('@')[0];
              return (
                <ListItem
                  defaultValue={value.id}
                  key={value.id}
                  role='listitem'
                  disabled={isSubmitting}
                >
                  <ListItemIcon>
                    {value !== null && (
                      <CustomCheckBox
                        name={value.id}
                        initialValue={value.isGroupAdmin}
                        cb={handleCheck}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemIcon>
                    <IconButton
                      color='secondary'
                      aria-label='remove from selected list'
                      onClick={() => handleRemove(value)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </ListItemIcon>

                  <ListItemIcon>
                    <Image
                      userName={value.displayName}
                      imgUrl={USER_PHOTO_URL(value.id)}
                      addAdminCheck={false}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={value.displayName}
                    secondary={`(${adName}) ${value.jobTitle}`}
                  />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Card>
      );
    }
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          fullWidth
          variant='contained'
          color='secondary'
          disabled={selectedUsers.length < 1 || isSubmitting}
          onClick={() => handleNewUsers()}
        >
          Add Selected Users
        </Button>
        {isSubmitting && (
          <CircularProgress
            size={24}
            color='secondary'
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>

      <UserSearch selectUser={(user: any) => setSelectedUsers([...selectedUsers.filter(selected => selected.id !== user.id), user]) } isSubmitting={isSubmitting} userBlackList={currentUsers} multiple={true}>
        {customSelectedList(selectedUsers, "")}
      </UserSearch>
    </div>
  );
}
