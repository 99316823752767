import React from 'react';
import { CarType } from '../../../../models/FleetAsset';

import MaterialTable from '@material-table/core';
import urlJoin from 'url-join';
import LoadingSpinner from '../../../../components/Loading';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import {
  CarWorkOrder,
  CAR_WO_TABLE_COLUMNS,
} from '../../../../models/CarWorkOrder';

const WO_REFERENCE_URL = (id: number) =>
  urlJoin('api/car', id.toString(), 'workorders');

export default function WorkOderTable({
  parentCar: { id },
}: {
  parentCar: CarType;
}) {
  const dataUrl = WO_REFERENCE_URL(id);
  const { response, error, loading } =
    useTokenGetRequest<CarWorkOrder[]>(dataUrl);

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <MaterialTable
        columns={CAR_WO_TABLE_COLUMNS}
        data={response?.sort(ascSort) ?? []}
        title={'Work Orders'}
      ></MaterialTable>
    </>
  );
}

function ascSort(a: CarWorkOrder, b: CarWorkOrder) {
  if (a.inDate === null) {
    return -1;
  }
  if (b.inDate === null) {
    return 1;
  }
  var nameA = new Date(a.inDate);
  var nameB = new Date(b.inDate);
  if (nameA < nameB) {
    return 1;
  }
  if (nameA > nameB) {
    return -1;
  }
  return 0;
}
