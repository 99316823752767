import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Loading from '../../../../components/Loading';
import useGraphToken from '../../../../hooks/useGraphToken';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import { CarType } from '../../../../models/FleetAsset';
import CarImage from './CarImage';

const DEPARTMENT_CARS_URL = '/api/car/ungrouped';

export default function CarSearch({
  setUsers,
  isSubmitting,
  blackList,
}: {
  setUsers: Function;
  isSubmitting: boolean;
  blackList: number[];
}) {
  const { response, error, loading, refresh } =
    useTokenGetRequest<CarType[]>(DEPARTMENT_CARS_URL);
  const [searchResult, setSearchResult] = useState<CarType[]>([]);
  const [selectedItems, setSelectedItems] = useState<CarType[]>([]);

  useEffect(() => {
    let mounted = true;

    if (!loading && response !== null) {
      const _seachResult = response.filter(
        (item: CarType) => !blackList.includes(item.id)
      );
      setSearchResult(_seachResult);
    }

    return () => {
      mounted = false;
    };
  }, [loading]);

  const handleRemove = (value: any) => {
    let evens = _.remove(selectedItems, function (n: any) {
      return n.id !== value.id;
    });
    setSelectedItems(evens);
    setUsers(evens);
  };

  const customSelectedList = (items: CarType[], message: string = 'Empty') => {
    if (_.isEmpty(items)) {
      return (
        <Card>
          <List
            sx={{
              width: 600,
              height: 450,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component='div'
            role='list'
          >
            <ListItem key={'#48957-32'} role='listitem'>
              <ListItemText id={'34'} primary={`${message}`} />
            </ListItem>
          </List>
        </Card>
      );
    } else {
      return (
        <Card>
          <List
            sx={{
              width: 600,
              height: 360,
              bgcolor: 'background.paper',
              overflow: 'scroll',
            }}
            dense
            component='div'
            role='list'
          >
            {items.map((value: CarType, index: number) => {
              const labelId = `transfer-list-all-item-${value}-label`;
              // let adName: string = value.userPrincipalName.split('@')[0];
              return (
                <ListItem
                  defaultValue={value.id}
                  key={index}
                  role='listitem'
                  disabled={isSubmitting}
                >
                  <ListItemIcon>
                    <IconButton
                      color='secondary'
                      aria-label='remove from selected list'
                      onClick={() => handleRemove(value)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </ListItemIcon>

                  <ListItemIcon>
                    <CarImage id={value.imageId} width={50} height={50} />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={`${value?.assetNumber ?? 'n/a'} - ${value.year} ${
                      value.make
                    } ${value.make}`}
                    secondary={`${value.fuelType}`}
                  />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Card>
      );
    }
  };

  const renderAvailableCarList = (items: CarType[]): React.ReactNode => {
    return (
      <>
        {items.map((value: CarType, index: number) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          // let adName: string = value.userPrincipalName.split('@')[0];
          return (
            <ListItem
              defaultValue={value.id}
              key={index}
              role='listitem'
              button
              disabled={isSubmitting}
              onClick={(event) => {
                event.preventDefault();
                if (_.isUndefined(_.find(selectedItems, value))) {
                  let newSelected = [...selectedItems, value];
                  setSelectedItems(newSelected);
                  setUsers(newSelected);
                }
              }}
            >
              <ListItemIcon>
                <CarImage id={value.imageId} width={50} height={50} />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value?.assetNumber ?? 'n/a'} - ${value.year} ${
                  value.make
                } ${value.model}`}
                secondary={`${value.fuelType}`}
              />
            </ListItem>
          );
        })}
      </>
    );
  };
  const customAvailableList = (items: CarType[], message: string = 'Empty') => {
    return (
      <Card>
        <List
          sx={{
            width: 600,
            height: 360,
            bgcolor: 'background.paper',
            overflow: 'auto',
          }}
          dense
          component='div'
          role='list'
        >
          {_.isEmpty(items) ? (
            <Loading message={'Searching for Available vehicles...'} />
          ) : (
            renderAvailableCarList(items)
          )}
        </List>
      </Card>
    );
  };

  return (
    <div style={{ height: 460, marginLeft: 16 }}>
      <Stack direction='row' spacing={4} mt={2} mr={1} sx={{ height: 370 }}>
        <>{customAvailableList(searchResult)}</>
        <>{customSelectedList(selectedItems)}</>
      </Stack>
      <br />
    </div>
  );
}
