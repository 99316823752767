import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponseTransformer, CancelTokenSource } from 'axios';

import { API_BASE_URL } from '../constants/fleetApi';

const axiosOptions: AxiosRequestConfig = {
  timeout: 200000,
  baseURL: API_BASE_URL,
};

const httpClient: AxiosInstance = axios.create(axiosOptions);

let call: CancelTokenSource;

export const requestOnce = ({ config }: { config: AxiosRequestConfig }) => {

    if (call) {
      call.cancel("CANCELED");
    }
    call = axios.CancelToken.source();
    config.cancelToken = call.token
    return httpClient.request(config);
  }

export default httpClient;
