import React from 'react';
import { Column } from '@material-table/core';
import { toHumanFriendlyDate } from '../utils/timeHelper';
import { formatMialage } from '../utils/carHelper';

export interface CarPreventativeMaintenance {
  assetPmId: number;
  assetId: number;
  pm: string;
  pmDesc: string;
  pmDatePreviousDate: string | null;
  pmDateNextDate: string | null;
  pmMeterPrevious: number | null;
  pmMeterNext: number | null;
}

export const CAR_PM_TABLE_COLUMNS: Array<Column<CarPreventativeMaintenance>> = [
  { title: 'Short Code', field: 'pm' },
  { title: 'Description', field: 'pmDesc' },
  {
    title: 'Previous Maint. Date',
    field: 'pmDatePreviousDate',
    render: (rowData) => (
      <>
        {rowData.pmDatePreviousDate !== null
          ? toHumanFriendlyDate(rowData.pmDatePreviousDate)
          : ''}
      </>
    ),
  },
  {
    title: 'Next Maint. Date',
    field: 'pmDateNextDate',
    render: (rowData) => (
      <>
        {rowData.pmDateNextDate !== null
          ? toHumanFriendlyDate(rowData.pmDateNextDate)
          : ''}
      </>
    ),
  },
  {
    title: 'Previous Maint. Mileage',
    field: 'pmMeterPrevious',
    render: (rowdata) => formatMialage(rowdata.pmMeterPrevious),
  },
  {
    title: 'Next Main. Mileage',
    field: 'pmMeterPrevious',
    render: (rowdata) => formatMialage(rowdata.pmMeterNext),
  },
];
