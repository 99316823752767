import React from 'react';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip from '@mui/material/Tooltip';
import { AdditionalDrawerItemsType, DrawerItemType } from '../../models/drawer';

export const generateMainListItems = (
  itemList: DrawerItemType[]
): React.ReactNode => {
  return (
    <>
      {itemList.map((item: DrawerItemType, index: number) => (
        <Tooltip key={index} title={item.lable} placement='right' disableInteractive>
          <ListItemButton
            key={index}
            onClick={(event) => item.handleClick(event)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.lable} />
          </ListItemButton>
        </Tooltip>
      ))}
    </>
  );
};

export const generateAdditionalListItems = (
  { subheaderLabel = false, itemList }: AdditionalDrawerItemsType,
  index: number
): React.ReactNode => {
  return (
    <React.Fragment key={index}>
      <Divider sx={{ my: 1 }} />
      {subheaderLabel && (
        <ListSubheader component='div' inset>
          {subheaderLabel}
        </ListSubheader>
      )}
      {generateMainListItems(itemList)}
    </React.Fragment>
  );
};

export const generateDrawerSections = (
  items: AdditionalDrawerItemsType[]
): React.ReactNode => {
  return (
    <>
      {items.map((item: AdditionalDrawerItemsType, index: number) =>
        generateAdditionalListItems(item, index)
      )}
    </>
  );
};

export const createRoutingFunc = (path: string, history: any) => {
  const historyRoute = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    history.push(path);
  return historyRoute;
};
