import React from 'react';
import { useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import useTokenGetRequest from '../../../hooks/useTokenGetRequest';

function message(text: string) {
  return (
    <Typography
      sx={{ fontSize: 20, margin: 0, width: 277 }}
      color='secondary'
      gutterBottom
    >
      {text}
    </Typography>
  );
}

export default function Notifications() {
  const location = useLocation();
  const { pathname } = location;

  const url = pathname.includes('group')
    ? 'api/notifications/groups/unseen'
    : 'api/notifications/unseen';

  const { response, error, loading, refresh } = useTokenGetRequest<number>(url);

  if (loading) return message('... Loading');
  if (error) return message('Error');
  if (response == 0) return message('No notifications');

  return message(`${response} new Notifications`);
}
