import {
  Button,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import InfoCard from '../../../components/Card/InfoCard';
import { InfoCardsType } from '../../../models/InfoCards';

import ReservationCard from './ReservationCard';

const HEIGHT: number = 150;
//TODO: remove coming soon
const content = (title: string, soon: boolean = false): React.ReactNode => (
  <CardContent>
    <Typography sx={{ fontSize: 20 }} color='text.secondary' gutterBottom>
      {title}
    </Typography>
    {soon && (
      <Typography sx={{ fontSize: 20 }} color='text.secondary' gutterBottom>
        COMING SOON
      </Typography>
    )}
  </CardContent>
);

const action: React.ReactNode = (
  <CardActions>
    <Button size='small'>Learn More</Button>
  </CardActions>
);

const ASSIGNED_COLOR = '#FFE599';
const MAINTENANCE_COLOR = '#FBBBCA';

export const createInfoCardContent = (): InfoCardsType => {
  const [invisible, setInvisible] = useState<boolean>(false);
  const history = useHistory();

  const renderReservatioContent = (title: string): React.ReactNode => (
    <CardContent>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Typography sx={{ fontSize: 12 }} color='text.secondary'>
          {title}
        </Typography>
        <Divider orientation='vertical' flexItem />
        <Button size='small' onClick={() => history.push('/reservation/new')}>
          Create new reservation
        </Button>
      </Stack>
      {<ReservationCard />}
    </CardContent>
  );

  return {
    reservation: (
      <InfoCard
        content={renderReservatioContent('Reservations')}
        height={HEIGHT}
        actions={[]}
      />
    ),
    // TODO: for ITD
    // assigned: (
    //   <InfoCard
    //     content={content('Assigned Vehicle')}
    //     height={HEIGHT}
    //     color={ASSIGNED_COLOR}
    //   />
    // ),
    actions: (
      <InfoCard
        content={content('Vehicles out for Maintenance', true)}
        height={HEIGHT}
        color={MAINTENANCE_COLOR}
      />
    ),
  };
};
