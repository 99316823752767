import React from 'react';

import GroupUsers from '../../../Department/components/GroupUsers';

interface Props {
  groupId: number;
  clickClose: () => void;
  currentUsers: string[],
}

export default function AddUser({
  groupId,
  clickClose,
  currentUsers
}: Props): React.ReactElement {

  return <GroupUsers clickClose={clickClose} groupId={groupId} currentUsers={currentUsers} />;
}
