import React from 'react';
import { useHistory } from 'react-router-dom';

import MaterialTable from '@material-table/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from '@mui/material/Grid';

import Dialog from '../../../components/Dialog';
import LocalErrorBoundary from '../../../components/ErrorBoundary/LocalErrorboundary';
import LoadingSpinner from '../../../components/Loading';
import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { groupTableColumns, GroupType } from '../../../models/Group';
import httpClient from '../../../utils/httpClient';
import NewGroupForm from './NewGroupForm';

export default function Groups() {
  const history = useHistory();
  const { response, error, loading, refresh } =
    useTokenGetRequest<GroupType[]>('/api/group');
  const [openNewGroup, setOpenNewGroup] = React.useState<boolean>(false);
  const [openGroupManagement, setOpenGroupManagement] =
    React.useState<boolean>(false);
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();

  const handleOpenNewGroup = () => {
    setOpenNewGroup(true);
  };

  const handleCloseNewGroup = () => {
    setOpenNewGroup(false);
    refresh();
  };

  const handleCloseNewGroupNoSave = () => {
    setOpenNewGroup(false);
  };

  const handleOpenGroupManagement = (
    event?: React.MouseEvent,
    rowData?: GroupType
  ) => {
    if (rowData) {
      history.push(`/group/${rowData.id}`, rowData);
    }
  };

  const handleCloseGroupManagement = () => {
    setOpenGroupManagement(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <LocalErrorBoundary>
          <Grid container direction='column' spacing={3}>
            <Grid item>
              {openNewGroup ? (
                <Dialog
                  open={openNewGroup}
                  fullScreen={false}
                  clickClose={handleCloseNewGroupNoSave}
                  title='New Group'
                >
                  <NewGroupForm clickClose={handleCloseNewGroup} />
                </Dialog>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item>
              <div style={{ height: 600, width: 'auto' }}>
                <MaterialTable
                  columns={groupTableColumns}
                  data={response?.sort(ascSort) ?? []}
                  title={'Group Management'}
                  options={{
                    grouping: true,
                    pageSize: 15,
                    pageSizeOptions: [15, 50, 100],
                    emptyRowsWhenPaging: false,
                  }}
                  actions={[
                    {
                      icon: () => (
                        <AddCircleIcon color='success' fontSize='large' />
                      ),
                      tooltip: 'Add Group',
                      isFreeAction: true,
                      onClick: (event) => handleOpenNewGroup(),
                    },
                  ]}
                  onRowClick={handleOpenGroupManagement}
                  editable={{
                    deleteTooltip: (rowData) => `Delete "${rowData.name}"`,
                    onRowDelete: (oldData) =>
                      httpClient
                        .request({
                          url: `/api/group/${oldData.id}`,
                          method: 'DELETE',
                          headers: {
                            'Content-Type': 'text/json',
                            Authorization: `Bearer ${accessToken}`,
                          },
                          responseType: 'json',
                        })
                        .then(() => {
                          createNotification({
                            message: 'Success',
                            options: { variant: 'success' },
                          });
                        })
                        .catch(() => {
                          createNotification({
                            message: 'Error: unable to delete.',
                            options: { variant: 'error' },
                          });
                        })
                        .finally(() => refresh()),
                  }}
                  localization={{
                    body: {
                      editRow: {
                        saveTooltip: '"YES" - Delete',
                        deleteText:
                          'Are you sure you want to delete this Group?',
                      },
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </LocalErrorBoundary>
      </>
    );
  }
}

function ascSort(a: GroupType, b: GroupType) {
  var nameA = a.name.toUpperCase();
  var nameB = b.name.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}
