import { Box, Typography, Button, CircularProgress } from '@mui/material';
import React from 'react';
import UserAssignModal from './UserAssignModal';

interface UserAssignProps {
  carId: string;
  refresh: Function;
}

export default function UserAssign({ carId, refresh }: UserAssignProps) {
  const [openUserList, setOpenUserList] = React.useState<boolean>(false);
  const [hideButton, setHideButton] = React.useState<boolean>(false);

  const openUserListModal = () => setOpenUserList(true);
  const closeUserListModal = () => setOpenUserList(false);
  const closeAndRefresh = () => {
    refresh();
    setOpenUserList(false);
  };

  return (
    <div>
      {openUserList && (
        <UserAssignModal
          carId={carId}
          open={openUserList}
          setClose={closeUserListModal}
          closeRefresh={closeAndRefresh}
        />
      )}

      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          disabled={openUserList}
          onClick={() => {
            openUserListModal();
          }}
          variant='contained'
        >
          Assign to user
        </Button>
        {openUserList && (
          <CircularProgress
            size={24}
            color='secondary'
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </div>
  );
}
