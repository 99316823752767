import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, Grid, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface Props {
  fullScreen?: boolean;
  maxWidth?: Breakpoint | false | undefined;
  open: boolean;
  clickClose: () => void;
  children?: React.ReactElement;
  title?: string;
}

export default function Index({
  open,
  fullScreen = true,
  maxWidth = false,
  clickClose,
  children,
  title = '',
}: Props) {
  return (
    <>
      <Dialog
        keepMounted={false}
        fullScreen={fullScreen}
        open={open}
        onClose={clickClose}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item xs={1}>
              <Toolbar>
                <Tooltip
                  title='Close without saving'
                  placement='right'
                  disableInteractive
                >
                  <IconButton
                    edge='start'
                    color='inherit'
                    onClick={clickClose}
                    aria-label='close'
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Toolbar>
            </Grid>
            <Grid item>
              <Typography variant='h6'>{title}</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </AppBar>
        <>{children}</>
      </Dialog>
    </>
  );
}
