import React, { useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { APP_HEADER_NAME, DRAWER_WIDTH } from '../../constants/layout';
import { ApplicationState } from '../../store';
import Drawer from '../Drawer';
import Image from '../Profile/Image';
import Copyright from './Copyright';
import MCDSS_Logo_2 from '../../media/MCDSS_Logo_2.png';
import Dialog from '../Dialog';
import { useMsal } from '@azure/msal-react';
import Stack from '@mui/material/Stack';
import theme from '../../theme';
import { AccessActions, AccessState } from '../../store/Access';
import { ProfileType } from '../../models/Access';
import {
  impersonateUser,
  profileData,
  clearImpersonation,
} from '../../services/user';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useAccessToken from '../../hooks/useAccessToken';
import { searchResultVmType } from '../../models/Graph';
import urljoin from 'url-join';
import { useHistory } from 'react-router';
import UserSearch from '../UserSearch';

const USER_IMG_URL = `https://graph.microsoft.com/v1.0/me/photo/$value`;
const USER_INFO_URL = (id: string): string =>
  urljoin('https://graph.microsoft.com/v1.0/users', id);
const USER_PHOTO_URL = (id: string): string =>
  urljoin(USER_INFO_URL(id), '/photo/$value');
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function WelcomeUser() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openImpersonation, setImpersonationDialog] =
    React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const { accounts } = useMsal();
  const username = accounts[0].name;
  const { isLoading, profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleImpersonationClick = () => {
    handleClose();
    setImpersonationDialog(true);
  };

  if (!isLoading) {
    if (profile?.isItdadmin || profile?.impersonation) {
      return (
        <>
          <Button onClick={handleClick} variant='text'>
            <Stack direction='row' spacing={1} alignItems='center'>
              {profile?.impersonation ? (
                <Image
                  userName={username}
                  imgUrl={USER_IMG_URL}
                  impersonatedUser={{
                    imgUrl: USER_PHOTO_URL(profile.azureId),
                    username: profile.name,
                  }}
                />
              ) : (
                <Image userName={username} imgUrl={USER_IMG_URL} />
              )}

              {profile?.impersonation ? (
                <Stack direction='column'>
                  <Typography noWrap sx={{ color: 'white' }}>
                    {username}
                  </Typography>
                  <Typography
                    noWrap
                    sx={{ color: 'lightgrey', fontSize: 'small' }}
                  >
                    {profile.name}
                  </Typography>
                </Stack>
              ) : (
                <Typography noWrap sx={{ color: 'white' }}>
                  {username}
                </Typography>
              )}
            </Stack>
          </Button>
          <>
            <Menu
              id='profile-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleImpersonationClick}>
                Impersonate User
              </MenuItem>
              {profile?.impersonation ? <CancelImpersonation /> : <></>}
            </Menu>
            {openImpersonation ? <ImpersonationSelection /> : <></>}
          </>
        </>
      );
    } else {
      return (
        <Stack direction='row' spacing={1} alignItems='center'>
          <Image userName={username} imgUrl={USER_IMG_URL} />
          <Typography noWrap sx={{ color: 'white' }}>
            {username}
          </Typography>
        </Stack>
      );
    }
  } else {
    return <CircularProgress size='18em' thickness={4} color='secondary' />;
  }
}

function CancelImpersonation() {
  const [isLoading, setLoadingState] = React.useState<boolean>(false);
  const { accessToken, refreshToken } = useAccessToken();
  const history = useHistory();
  const removeImpersonation = () => {
    clearImpersonation(accessToken).then(() => {
      profileData(accessToken).then((data) => {
        AccessActions.setAccessProfile(data.data);
        if (history.location.pathname === '/') {
          history.go(0);
        } else {
          history.push('/');
        }
      });
    });
  };

  const handleClearImpersonation = () => {
    setLoadingState(true);
    removeImpersonation();
  };

  if (!isLoading) {
    return (
      <MenuItem onClick={handleClearImpersonation}>
        Clear Impersonation
      </MenuItem>
    );
  } else {
    return (
      <MenuItem onClick={handleClearImpersonation}>
        <CircularProgress thickness={4} color='secondary' />
      </MenuItem>
    );
  }
}

function ImpersonationSelection() {
  const [openImpersonation, setImpersonationDialog] =
    React.useState<boolean>(true);
  const [selectedUser, setSelectedUser] =
    React.useState<null | searchResultVmType>(null);
  const { accessToken, refreshToken } = useAccessToken();
  const history = useHistory();

  const handleClose = () => {
    setImpersonationDialog(false);
    setSelectedUser(null);
  };

  React.useEffect(() => {
    if (accessToken != null) {
      if (selectedUser != null) {
        impersonateUser(accessToken, selectedUser.id).then((response) => {
          if (response.status === 200) {
            profileData(accessToken).then((data) => {
              AccessActions.setAccessProfile(data.data);
              if (history.location.pathname === '/') {
                history.go(0);
              } else {
                history.push('/');
              }
              setImpersonationDialog(false);
            });
          }
        });
      }
    }
  }, [selectedUser]);

  return (
    <>
      <Dialog
        fullScreen={false}
        open={openImpersonation}
        clickClose={() => setImpersonationDialog(false)}
        title=''
      >
        <Grid
          container
          sx={{ padding: '2em' }}
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <Grid item>
            <h2>Impersonating User</h2>
          </Grid>
          <Grid item>
            {selectedUser === null ? (
              <UserSearch
                departmentFilter={false}
                isSubmitting={false}
                selectUser={(user: searchResultVmType) => setSelectedUser(user)}
              />
            ) : (
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='center'
                sx={{ width: '15em', height: '15em' }}
              >
                <Grid item>
                  <CircularProgress
                    size='10em'
                    thickness={4}
                    color='secondary'
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

const getAccessFilter = ({
  isItdadmin,
  isDeptAdmin,
  isFleetAdmin,
  groupAdmin,
}: ProfileType): boolean => {
  return isItdadmin || isDeptAdmin || isFleetAdmin || groupAdmin;
};

export default function index({ children }: { children?: React.ReactNode }) {
  const [open, setOpen] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
  const [departmentName, setDepartmentName] = React.useState<string>('');

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const backdrop = useSelector(
    (state: ApplicationState) => state.applicationBackdrop
  );

  const { profile } = useSelector<ApplicationState, AccessState>(
    (state: ApplicationState) => state.access
  );

  useEffect(() => {
    let isMounted = true;

    if (profile !== null) {
      setIsAdmin(getAccessFilter(profile));
      if (profile.dept !== null) {
        setDepartmentName(
          `${APP_HEADER_NAME} - ${profile.dept.departmentName ?? ''}`
        );
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000000 }}
        open={backdrop?.isLoading ?? false}
      >
        <CircularProgress size='18em' thickness={4} color='secondary' />
      </Backdrop>
      <AppBar position='fixed' open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          {isAdmin && (
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          {/* <div>
            <a href='http://mcdss.co.monterey.ca.us/' target='_blank'>
              <img src={MCDSS_Logo_2} height='50px' width='auto' />
            </a>
          </div> */}
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            component='div'
            // sx={{ fontStyle: 'italic', width: '130px' }}
            sx={{ fontStyle: 'italic', width: 'auto' }}
            variant='h5'
            color='inherit'
            noWrap
          >
            {departmentName}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <WelcomeUser />
        </Toolbar>
      </AppBar>

      {isAdmin && <Drawer open={open} toggleDrawer={toggleDrawer} />}

      <main
        style={{
          marginTop: 80,
          marginRight: 5,
          marginBottom: 15,
          flexGrow: 1,
          marginLeft: open ? DRAWER_WIDTH + 5 : 76,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          wrap='nowrap'
        >
          <Grid item xs>
            {children}
          </Grid>
          <Grid item xs={1} alignContent='start'>
            <Copyright sx={{ pt: 4 }} />
          </Grid>
        </Grid>
      </main>
    </>
  );
}
