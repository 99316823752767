import { AxiosResponse } from 'axios';
import { OptionsObject } from 'notistack';
import { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { fleetScopes } from '../authConfig';
import httpClient from '../utils/httpClient';
import useNotifier from './useNotifier';

const _defaultOptions: OptionsObject = {
  variant: 'error',
};

const message = 'Connection Error.';
const noContent = 'No Content.';

export default function useTokenGetRequest<T> (
  url: string,
  errorMessage: string = message
) {
  const { instance } = useMsal();
  const { createNotification } = useNotifier();
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [counter, setCounter] = useState<number>(0);

  const refresh = () => setCounter(counter + 1);

  const fetchData = async (
    url: string,
    token_: string
  ): Promise<AxiosResponse> => {
    const result: AxiosResponse = await httpClient.get(url, {
      headers: {
        ContentType: 'application/json',
        Authorization: `Bearer ${token_}`,
      },
    });
    return result;
  };

  async function token() {
    const res = await instance
      .acquireTokenSilent(fleetScopes)
      .then((response) => {
        return response.accessToken;
      })
      .catch((response) => {
        console.log(response);
        return instance.acquireTokenPopup(fleetScopes).then(response => {
          if(response){
            return response.accessToken;
          }
          return null;
        });
      });
    return res;
  }

  useEffect(() => {
    let isMounted = true;
    try {
      if (isMounted) setLoading(true);
      token().then((tokn: string | null) => {
        if(tokn != null){
          fetchData(url, tokn)
            .then((response: AxiosResponse<T> | null) => {
              if (response !== null) {
                if (response.status === 200) {
                  if (isMounted) setResponse(response.data);
                } else {
                  if (isMounted) {
                    setResponse(null);
                    createNotification({
                      message: noContent,
                      options: { variant: 'info' },
                    });
                  }
                }
              } 
            })
            .catch(()=>{
              createNotification({
                options: { ..._defaultOptions },
                message: errorMessage,
              })
            })
            .finally(() => {
              if (isMounted) setLoading(false);
            });
        }
      });
    } catch (error: any) {
      createNotification({
        options: { ..._defaultOptions },
        message: errorMessage,
      });

      if (isMounted) {
        setResponse(null);
        setError(error);
        setLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [url, counter]);

  return { response, error, loading, refresh };
}
