import React from 'react';
import { CarType } from '../../../../models/FleetAsset';

import MaterialTable, { MaterialTableProps } from '@material-table/core';
import {MTableCell, MTableBodyRow} from '@material-table/core';
import urlJoin from 'url-join';
import LoadingSpinner from '../../../../components/Loading';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import UploadDocument from '../../uploadDocument';
import FolderUpload from '../../uploadFolder';
import {
  AttachmentMetadata,
  ATTACHMENT_TABLE_COLUMNS,
  DocumentMetadata,
} from '../../../../models/Attachment';
import './AttachmentTable.css';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import { update } from 'lodash';



const CAR_ATTACHMENT_URL = (id: number, archive: boolean) => 
  urlJoin("api","document", "1", `${id}?archived=${archive}`);
const CAR_ARCHIVED_URL = (id: number) => 
  urlJoin("api", "document", "1", id.toString(), "archived");
export default function AttachmentTable({
    parentCar: { id },
  }: {
    parentCar: CarType;
  }){
    
    var [updateCount, setCount] = React.useState<number>(0);
    var [displayArchivedDocuments, showArchiveDocs] = React.useState<boolean>(false);
    var documentURL = CAR_ATTACHMENT_URL(id, displayArchivedDocuments);

    const { response, error, loading, refresh } =
      useTokenGetRequest<{data:AttachmentMetadata[]}>(documentURL);

    React.useEffect(() => {
      let isMounted = true;
      if(isMounted){
        refresh();
      }
    }, [updateCount])

    return (
      <>        
        <MaterialTable
          isLoading={loading}
          options={{            
            grouping: true,
            pageSize: 15,
            pageSizeOptions: [15, 50, 100],
            emptyRowsWhenPaging: false,
            rowStyle:(rowData,index, level) => { 
              if(level > 0){
                return {backgroundColor: "rgb(231 230 230 / 40%)"}
              }
              return {};
             }
          }}
          columns={ATTACHMENT_TABLE_COLUMNS(refresh)}
          data={(response != null ? response.data: [])}
          // data={!displayArchivedDocuments ? (response != null ? response.data: []): (archiveResponse != null ? archiveResponse.data: [])}
          components={{
            Row: props => {
              console.log(props);
              if(props.level > 0){
                return (<MTableBodyRow className="inset-row" {...props} />)  
              }
              return (<MTableBodyRow {...props} />);
            }
          }}
          //title="Attachments"
          title={
            <Typography variant="h6" style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}>              
          <Grid container direction='row' spacing={3}>
            <Grid item>            
                Attachments
            </Grid>
            <Grid item>
              <Button type='button' color="warning" variant={displayArchivedDocuments ? "contained" : 'outlined'} onClick={() => 
                { if(displayArchivedDocuments) {
                    showArchiveDocs(false);                     
                  }else{ 
                    showArchiveDocs(true); 
                  }
                }}>{displayArchivedDocuments ? "Hide Archived Documents": "Show Archived Documents"}</Button>
            </Grid>
            </Grid>
            </Typography>
          }
          parentChildData={
            (attachment, rows) => 
              rows.find(folder => folder.id === ((attachment as DocumentMetadata)?.parentFolder?.id ?? 0))
            }
          actions={[
            {
              icon: () => (<FolderUpload parentCar={id} showLabel={false} updateFunction={() => setCount(updateCount + 1)}/>),
              tooltip: "Create Folder",
              isFreeAction: true,
              onClick: (event) => console.log(event)
            },{
              icon: () => (<UploadDocument parentObject={{ id: id }} showLabel={false} updateFunction={() => setCount(updateCount + 1)}/>),
              tooltip: "Attach Document",
              isFreeAction: true, 
              onClick: (event) => console.log(event)
            }
          ]}
          >
        </MaterialTable>
        
        </>
    )
}