import React from 'react';
import NavButton from './NavButton';
import NewReservation from '../Home/Reservations/NewReservation';

export default function ReservationForm() {
  return (
    <NavButton
      pageLabel='Reservation Form'
      path={{ location: '/reservation', name: 'Reservations' }}
    >
      <NewReservation />
    </NavButton>
  );
}
