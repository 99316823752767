import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import urljoin from 'url-join';
import LoadingSpinner from '../../../components/Loading';
import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { CarType } from '../../../models/FleetAsset';
import httpClient from '../../../utils/httpClient';
import CarForm from './CarInfo';
import RelatedCarFields from './RelatedCarFields';
import AssignedUserCard from './components/AssignedUserCard';
import CarImage from './components/CarImage';
import InactiveCarModal from './components/InactiveCarModal';
import UserAssign from './components/UserAssign';

import EditCarImage from './components/EditCarImage';

import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { UploadDocumentMetadata } from '../../../models/UploadDocumentMetadata';
import Tags from './components/Tags';

const SINGLE_CAR_URL = (id: string): string => urljoin('/api/car', id);

interface SingleVehicleUrlProps {
  id: string;
}

const ActiveSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function SingleVehicle() {
  const [open, setOpen] = useState<boolean>(false);
  const { id } = useParams<SingleVehicleUrlProps>();
  const singleCarUrl = SINGLE_CAR_URL(id);
  const { response, error, loading, refresh } =
    useTokenGetRequest<CarType>(singleCarUrl);
  const [checked, setChecked] = useState<boolean>(false);
  const [disableChecked, setDisableChecked] = useState<boolean>(false);
  const { accessToken } = useAccessToken();
  const history = useHistory();
  const { createNotification } = useNotifier();

  const handleUpdate = ({
    active,
    note,
  }: {
    active: boolean;
    note: string;
  }) => {
    setOpen(false);
    setChecked(active);
    setDisableChecked(true);
    httpClient
      .request({
        url: singleCarUrl,
        method: 'PUT',
        data: { active, note },
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(() => {
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
      })
      .catch(() => {
        createNotification({
          message: 'Error: unable to Update.',
          options: { variant: 'error' },
        });
      })
      .finally(() => {
        setDisableChecked(false);
        refresh();
      });
  };

  useEffect(() => {
    let isMounted = true;

    if (!loading && !_.isEmpty(response)) {
      // console.log(response)
      if (isMounted) setChecked(response?.active ?? false);
    }

    return () => {
      isMounted = false;
    };
  }, [loading, response]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Box ml={2} sx={{ width: '98%' }}>
      <>
        {open ? (
          <InactiveCarModal
            setOpen={setOpen}
            open={open}
            active={checked}
            updateStatus={handleUpdate}
          />
        ) : null}
      </>
      <Button onClick={() => history.goBack()}>go back</Button>

      <Grid container direction='column' spacing={3}>
        <Grid item>
          <Stack direction='row' spacing={1} justifyContent='flex-start'>
            {response && <CarImage id={response.imageId} />}
            <EditCarImage carId={id} refresh={refresh} />
            {response && <CarForm car={response} />}
          </Stack>
        </Grid>

        <Grid item>
          <Divider />
          <Stack direction='row' spacing={2}>
            <Stack direction='column' sx={{ width: '50%' }}>
              <Typography sx={{ fontSize: 10 }} color='text.secondary'>
                TAGS
              </Typography>
              <Tags tags={response?.tags ?? []} carId={id} />
            </Stack>

            <Divider orientation='vertical' flexItem />
            <Stack direction='column'>
              <Typography sx={{ fontSize: 10 }} color='text.secondary'>
                STATUS
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <ActiveSwitch
                      checked={checked}
                      onChange={() => setOpen(true)}
                      disabled={disableChecked}
                    />
                  }
                  label={checked ? 'active' : 'inactive'}
                />
              </FormGroup>
            </Stack>
            <Divider orientation='vertical' flexItem />
            <Stack>
              <Typography>assigned to:</Typography>
              {id && response?.assignedTo === null && (
                <UserAssign carId={id} refresh={refresh} />
              )}
              {response && response?.assignedTo !== null && (
                <AssignedUserCard
                  carId={id}
                  user={response.assignedTo}
                  refresh={refresh}
                />
              )}
            </Stack>
          </Stack>
          <Divider />
        </Grid>
        {response && (
          <Grid item>
            <RelatedCarFields car={response} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
