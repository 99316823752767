import { Column } from "@material-table/core"
import { toHumanFriendlyDate } from '../utils/timeHelper';

export interface CarScheduledMaintenance
{
    assetSmId: number,
    assetId: number,
    saId: number, 
    sa: string, 
    saDatePreviousDate: string | null,
    saDateNextDate: string | null,
    saMeterPrevious: number | null,
    saMeterNext: number| null
}

export const CAR_SM_TABLE_COLUMNS: Array<Column<CarScheduledMaintenance>> = [
    {title: "Description", field: "sa"},
    {title: "Previous Maint. Date", field: "saDatePreviousDate", render: rowData => 
        rowData.saDatePreviousDate !== null ? (toHumanFriendlyDate(rowData.saDatePreviousDate)): "" },
    {title: "Next Maint. Date", field: "saDateNextDate", render: rowData => 
        rowData.saDateNextDate !== null ? (toHumanFriendlyDate(rowData.saDateNextDate)): "" },
]