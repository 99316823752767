import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { fleetScopes } from '../../authConfig';
import noAccess from '../../media/noAccess.jpg';
import { ApplicationState } from '../../store';
import { AccessActions, AccessState } from '../../store/Access';
import httpClient from '../../utils/httpClient';
import Public from '../Layout/Public';
import LoadingSpinner from '../Loading';
import Mobile from '../Layout/Mobile';

import { OptionsObject } from 'notistack';
import useNotifier from '../../hooks/useNotifier';

const errorNotifierOptions: OptionsObject = {
  variant: 'error',
};
const connectionErrorMessage = 'Connection Error';

export default function Access(props: React.PropsWithChildren<any>) {
  const { error } = useMsalAuthentication(InteractionType.Redirect);
  const { instance, accounts, inProgress } = useMsal();
  const [appApiError, setAppApiError] = useState<boolean>(false);
  const { createNotification, handleDimissAll } = useNotifier();

  const { isLoading } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (accounts && inProgress === InteractionStatus.None && !error) {
      instance.setActiveAccount(accounts[0]);
      instance.acquireTokenSilent(fleetScopes).then((response) => {
        if (response) {
          httpClient
            .get('/api/User/me/profiledata', {
              headers: {
                ContentType: 'application/json',
                Authorization: `Bearer ${response.accessToken}`,
              },
            })
            .then((response: any) => {
              dispatch(AccessActions.setAccessProfile(response.data));
              createNotification({ message: 'Welcome.' });
            })
            .catch((err: any) => {
              console.log(err);
              setAppApiError(true);
              dispatch(AccessActions.setLoadingFalse());
              createNotification({
                message: connectionErrorMessage,
                options: { ...errorNotifierOptions },
              });
            });
        }
      });
    }
  }, [inProgress]);

  if (error) {
    return (
      <Public>
        <Typography
          component='h1'
          variant='h6'
          color='inherit'
          sx={{ flexGrow: 1 }}
        >
          {'Fleet encountered an Error.'}
        </Typography>
      </Public>
    );
  }

  if (inProgress !== InteractionStatus.None || isLoading) {
    return (
      <Public>
        <LoadingSpinner message='Please wait while we sign you in.' />
      </Public>
    );
  }

  if (inProgress === InteractionStatus.None && !isLoading && appApiError) {
    return (
      <Public>
        <Typography variant='h1' color='inherit' noWrap sx={{ flexGrow: 1 }}>
          {'Fleet encountered an Error while trying to connect to the server.'}
        </Typography>
      </Public>
    );
  }

  return (
    <>
      <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Public>
          <div style={{ marginTop: '50px' }}>
            <Stack spacing={2} justifyContent='center' alignItems='center'>
              <Typography>Not Authenticated</Typography>
              <img src={noAccess} />
            </Stack>
          </div>
        </Public>
      </UnauthenticatedTemplate>
    </>
  );
}
