import MaterialTable from '@material-table/core';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { PropsWithChildren } from 'react';
import { ExportCsv } from '../../../utils/csvExportHelper';
import Dialog from '../../../components/Dialog';
import LoadingSpinner from '../../../components/Loading';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import {
  CarType,
  vehicleTableColumns
} from '../../../models/FleetAsset';
import SingleVehicle from './SingleVehicle';
import { useHistory } from 'react-router-dom';
import useNotifier from '../../../hooks/useNotifier';

export default function index(props : PropsWithChildren<{ url: string, hideDeptNumber?: boolean }>) {
  const { response, error, loading } = useTokenGetRequest<CarType[]>(props.url);
  const [open, setOpen] = React.useState(false);
  const [tableRowData, setRowData] = React.useState<CarType | null>(
    null
  );
  const history = useHistory();
  const { createNotification } = useNotifier();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRowData(null);
    setOpen(false);
  };

  const handleRowClick = (
    event?: React.MouseEvent,
    rowData?: CarType
  ) => {
    try {
      if (rowData !== undefined && rowData.assetId) {
        setRowData(rowData);
        history.push(`/vehicle/${rowData.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const TableColumnDefinition = vehicleTableColumns(props.hideDeptNumber ?? true)
  return (
    <>
      <Grid container direction='column' spacing={3}>
        {
          props.children !== null ? (
          <Grid item>
            {props.children}
          </Grid> 
          ): (<></>)}
        <Grid item>
          <div style={{ height: 600, width: 'auto' }}>
            <MaterialTable
              isLoading={loading}
              columns={TableColumnDefinition}
              data={response ?? []}
              title={'Vehicle Information'}
              options={{
                filtering: true,
                grouping: true,
                pageSize: 15,
                pageSizeOptions: [15, 50, 100],
                emptyRowsWhenPaging: false,
                exportMenu: [{
                  label: "Export as CSV",
                  exportFunc(columns, renderData, tableData) {
                    ExportCsv(createNotification, columns, renderData, tableData);
                  },
                }]
              }}                
              onRowClick={handleRowClick}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
