import React, { createContext } from 'react';

export interface HomeContextType {
  reload: Function | null;
}

interface HomeContextViewProps {
  value: HomeContextType;
  children: React.ReactNode;
}

const defaultHomeContextState = {
  reload: null,
};

export const HomeContext = createContext<HomeContextType>(
  defaultHomeContextState
);

export default function HomeContextView({
  value,
  children,
}: HomeContextViewProps) {
  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
}
