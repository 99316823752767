import {useState} from 'react';
import useAccessToken from '../../../../hooks/useAccessToken';
import { Grid, Button, DialogContent, DialogActions} from '@mui/material';
import { Delete } from '@mui/icons-material';
import Dialog from '../../../../components/Dialog';
import LoadingSpinner from '../../../../components/Loading';
import {mileageDeleteService } from '../../../../services/mileage';

export default function MileageActions({id, updateFunction}: {id: number, updateFunction: () => void})
{
    const { accessToken }  = useAccessToken();
    const [deleteLoading, setDeletionStatus] = useState<boolean>(false);
    const [openConfirmation, setConfirmationStatus] = useState<boolean>(false);
    const handleMileageDeletion = () => {
        setDeletionStatus(true);
        mileageDeleteService(accessToken, id.toString()).then(() => {            
            setConfirmationStatus(false);
            setDeletionStatus(false);
            updateFunction();
        })
    }

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item>
                <Button color='error' variant='contained' type='button' onClick={() => setConfirmationStatus(true)}><Delete /> Delete</Button>
            </Grid>
            {openConfirmation ? 
            (<Grid item>
                <Dialog
                    open={openConfirmation}
                    clickClose={() => setConfirmationStatus(false)}                    
                    maxWidth="md"
                    fullScreen={false}
                    >
                        
                        {deleteLoading ? (<h3 className="mx-5"><LoadingSpinner /></h3>): (<>
                            <DialogContent>
                                <h3 className='text-center m'>Confirm Deletion</h3>
                                <hr />
                                <p>Are you sure you want to delete mileage entry?</p>
                            </DialogContent>
                            <DialogActions>
                                <Grid container direction="row" justifyContent='right' spacing={2}>
                                    <Grid item>
                                        <Button color='secondary' onClick={() => setConfirmationStatus(false)}>Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant='outlined' onClick={handleMileageDeletion}>Confirm</Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </>)}
                </Dialog>
            </Grid>): 
            (<></>)}
            
        </Grid>
    )

}