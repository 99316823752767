import { ProfileType } from './Access';

export interface ItdUserAssignmentVm {
  id: number;
  userId: string;
  IsDeptAdmin: boolean;
}

export class NewItdUserAssignment implements ItdUserAssignmentVm {
  id: number;
  userId: string;
  IsDeptAdmin: boolean;

  constructor(init: ProfileType) {
    (this.id = init.id),
      (this.userId = init.azureId),
      (this.IsDeptAdmin = this.stringToBoolean(init.isDeptAdmin));
  }

  private stringToBoolean(isAdmin: string | boolean | null | undefined) {
    return String(isAdmin) === '1' || String(isAdmin).toLowerCase() === 'true';
  }
}
