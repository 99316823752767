import React from 'react';
import { Stack, Typography } from '@mui/material';
import DatePickerDialog from './DatePickerDialog';
import TimePickerDialog from './TimePickerDialog';

interface DateTimePickerProps {
  title: string;
  date: Date | undefined;
  handleDate: Function;
}

const TODAY = new Date();
TODAY.setDate(TODAY.getDate() + 1);

export default function DateTimePicker({
  title,
  date,
  handleDate,
}: DateTimePickerProps) {
  return (
    <div style={{ marginLeft: '.8em', fontSize: '.7rem' }}>
      <Typography color='indianred' variant='h6' fontSize='inherit'>
        {title}
      </Typography>
      <Stack direction='row'>
        <DatePickerDialog
          disableDate={TODAY}
          scheduledDay={date}
          setSelectedDate={handleDate}
        />
        <TimePickerDialog scheduledDay={date} setSelectedDate={handleDate} />
      </Stack>
    </div>
  );
}
