import React, { useState } from 'react';

import { Column } from '@material-table/core';
import Button from '@mui/material/Button';
import { OptionsObject } from 'notistack';
import { useParams } from 'react-router-dom';
import urlJoin from 'url-join';
import { AlternateProps } from '../../../../components/TransferList';
import useAccessToken from '../../../../hooks/useAccessToken';
import useNotifier from '../../../../hooks/useNotifier';
import { searchResultVmType } from '../../../../models/Graph';
import {
  groupUserAddType,
  setGroupCarsService,
} from '../../../../services/group';
import CarSearch from './CarSearch';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { CarType } from '../../../../models/FleetAsset';

interface GroupUsersProps {
  id: string;
}

interface Props {
  clickClose: () => void;
  carBlackList: CarType[];
}
export default function GroupCars({ clickClose, carBlackList }: Props) {
  /// if needed get user Id from url
  // ex:
  const { id } = useParams<GroupUsersProps>();
  const { accessToken, refreshToken } = useAccessToken();
  const { createNotification } = useNotifier();
  const [groupUsers, setGroupUsers] = useState<AlternateProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [availableDone, setAvailableDone] = useState<boolean>(false);
  const [memberDone, setMembersDone] = useState<boolean>(true);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const blackList = carBlackList.map((car: CarType) => car.id);

  interface ReservationType {
    active: string;
    id: number;
    out: number;
    in: string;
  }

  const CAR_MILEAGE_TABLE_COLUMNS: Array<Column<ReservationType>> = [
    { title: 'Column 1', field: 'active' },
    { title: 'Column 2', field: 'id' },
    { title: 'Column 3', field: 'out' },
    { title: 'Column 4', field: 'in' },
  ];

  const successOptions: OptionsObject = {
    variant: 'success',
  };

  const errorOptions: OptionsObject = {
    variant: 'error',
  };

  const handleNewUsers = () => {
    setIsSubmitting(true);
    const postNewUrl = `/api/car/group/${id}/car`;
    const cars: number[] = selectedUsers.map((car: any) => {
      return car.id;
    });

    setGroupCarsService(accessToken, postNewUrl, { carList: cars })
      .then((res) => {
        createNotification({ message: 'Success', options: successOptions });
      })
      .catch((err) => {
        createNotification({
          message: 'Connection Error',
          options: errorOptions,
        });
      })
      .finally(() => {
        clickClose();
        setIsSubmitting(false);
      });
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          fullWidth
          variant='contained'
          color='success'
          disabled={selectedUsers.length < 1 || isSubmitting}
          onClick={() => handleNewUsers()}
        >
          Add Selected vehicles
        </Button>
        {isSubmitting && (
          <CircularProgress
            size={24}
            color='secondary'
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
      <CarSearch
        setUsers={setSelectedUsers}
        isSubmitting={isSubmitting}
        blackList={blackList}
      />
    </div>
  );
}
