import React from 'react';
import { CarType } from '../../../models/FleetAsset';

import { tabConfigType } from '../../../models/Tabs';
import MileageTable from './components/MileageTable';
import PreventativeMaintenanceTable from './components/PreventativeMaintenanceTable';
import ScheduledMaintenanceTable from './components/ScheduledMaintenanceTable';
import WorkOderTable from './components/WorkOderTable';

import Tabs from '../../../components/Tabs';
import AttachmentTable from './components/AttachmentTable';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import Notes from './components/Notes';
import CheckOutTable from './components/CheckoutTable';

export default function RelatedCarFields({ car }: { car: CarType }) {
  const tabConfig: tabConfigType[] = [
    { label: 'Mileage', key: 0, component: <MileageTable parentCar={car} /> },    
    {
      label: "Checkouts",
      key: 1, 
      component: <CheckOutTable parentCar={car} />
    },
    {
      label: 'Preventative Maint.',
      key: 2,
      component: <PreventativeMaintenanceTable parentCar={car} />,
    },
    {
      label: 'Scheduled Maint.',
      key: 3,
      component: <ScheduledMaintenanceTable parentCar={car} />,
    },
    {
      label: 'Work Orders',
      key: 4,
      component: <WorkOderTable parentCar={car} />,
    },
    {
      label: "Attachments",
      key: 5,
      component: <AttachmentTable parentCar={car} />,
    },
    {
      label: "Notes",
      key: 6,
      component: <Notes parentCar={car} />,
    },
  ];

  return (
    <>
      <Tabs tabConfig={tabConfig} />
    </>
  );
}
