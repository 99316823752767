import React, { forwardRef } from 'react';
import { useHistory } from 'react-router';

import MaterialTable from '@material-table/core';
import { Button, Grid } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import { useLocation } from 'react-router-dom';
import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import {
  NOTIFICATIONS_TABLE_COLUMNS,
  UserReport,
} from '../../../models/Notification';
import httpClient from '../../../utils/httpClient';

const UPDATE_NOTIFICATION_URL = (notificationId: string | number) =>
  `/api/notifications/${notificationId}`;

export default function NotificationTable() {
  const history = useHistory();
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();
  const location = useLocation();
  const { pathname } = location;

  const url = pathname.includes('group')
    ? 'api/notifications/groups'
    : 'api/notifications';

  const { response, error, loading, refresh } =
    useTokenGetRequest<UserReport[]>(url);

  const handleRowClick = (event?: React.MouseEvent, rowData?: UserReport) => {
    if (rowData?.notification?.carId) {
      history.push(`/vehicle/${rowData.notification.carId}`);
    }
  };

  const updateNotification = (rowData: UserReport) => {
    return httpClient
      .request({
        url: UPDATE_NOTIFICATION_URL(rowData.id),
        method: 'PUT',
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
        params: { Seen: rowData.isSeen },
      })
      .then(() => {
        createNotification({
          message: 'Update successful.',
          options: { variant: 'success' },
        });
      })
      .catch(() => {
        createNotification({
          message: 'Error, please try again.',
          options: { variant: 'error' },
        });
      })
      .finally(() => {
        refresh();
      });
  };

  return (
    <>
      <Grid container direction='column' spacing={3}>
        <Grid item>
          <Button onClick={() => history.goBack()}>go back</Button>
        </Grid>
        <Grid item>
          <div style={{ height: 600, width: 'auto' }}>
            <MaterialTable
              columns={NOTIFICATIONS_TABLE_COLUMNS}
              data={
                response?.sort((a: UserReport, b: UserReport) => {
                  return (
                    new Date(b.createdOn).getTime() -
                    new Date(a.createdOn).getTime()
                  );
                }) ?? []
              }
              title={'Reported issues'}
              options={{
                grouping: true,
                pageSize: 15,
                pageSizeOptions: [15, 50, 100],
                emptyRowsWhenPaging: false,
              }}
              isLoading={loading}
              onRowClick={handleRowClick}
              localization={{
                body: {
                  editRow: {
                    saveTooltip: 'confirm',
                    deleteText:
                      'please confirm that you are aware of this issue.',
                  },
                },
              }}
              editable={{
                editTooltip: (rowData: UserReport) => {
                  return rowData.isSeen ? '' : `update seen status`;
                },
                isEditable: (rowData: UserReport) => !rowData.isSeen,
                onRowUpdate: (newData: any, oldData: any) => {
                  return updateNotification(newData);
                },
              }}
              icons={{
                Edit: forwardRef((props, ref) => (
                  <EditIcon {...props} ref={ref} color='inherit' />
                )),
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
