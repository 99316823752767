import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TransferList from '../../../components/TransferList';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { Button, Typography } from '@mui/material';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import GroupManagementTabs from './GroupManagementTabs';
import { GroupType } from '../../../models/Group';

interface GroupManagementProps {
  id: string;
}

interface pageState {
  group: GroupType;
}

// TODO: WE NEED AN ENDPOINT TO GET A SINGLE GROUP's INFO
export default function GroupManagement() {
  const history = useHistory();
  const location = useLocation<GroupType>();
  const state: GroupType = location.state;

  return (
    <>
      <Typography variant='h5'>Group Name: {state.name}</Typography>
      <GroupManagementTabs />
    </>
  );
}

{
  /* <DialogContent>
<TransferList />
</DialogContent> */
}
