import MaterialTable from '@material-table/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';


import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import Dialog from '../../../components/Dialog';
import LoadingSpinner from '../../../components/Loading';
import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { ProfileType, userTableColumns } from '../../../models/Access';
import httpClient from '../../../utils/httpClient';
import AddUser from './components/AddUser';

interface groupTableType extends ProfileType {
  isThisGroupAdmin: boolean;
}

export default function GroupUsersTable({
  url,
  groupId = -1,
}: {
  url: string;
  groupId?: number;
  children?: JSX.Element;
}) {
  const { id } = useParams<{ id: string | undefined }>();
  const _groupId = id === undefined ? groupId : parseInt(id, 10);

  const history = useHistory();
  const { accessToken } = useAccessToken();
  const { response, error, loading, refresh } =
    useTokenGetRequest<ProfileType[]>(url);
  const { createNotification } = useNotifier();
  const [openIsAdmin, setOpenIsAdmin] = React.useState<boolean>(false);
  const [profileData, setProfileData] = React.useState<ProfileType>();
  const [openAddUsers, setAddUsers] = React.useState<boolean>(false);
  const [data, setData] = React.useState<groupTableType[] | ProfileType[]>([]);
  const [rows, setRows] = React.useState<any>([]);
  const [currentUsers, setCurrentUsers] = React.useState<string[]>([]);

  function isItAdmin(info: any): boolean {
    let res = false;

    if (info === undefined || info === null) {
      return false;
    }

    switch (info.role) {
      case 'Admin':
        res = true;
        break;
      case 'User':
        res = false;
        break;

      default:
        res = false;
        break;
    }

    return res;
  }

  useEffect(() => {
    let isMounted = true;

    function run() {
      

      if (!loading && response) {
        // if (id !== undefined) {
          const listUserId: string[] = [];
          _.forEach(response, function (o) {
            const info = _.find(o.adminGroups, { id: _groupId });

            const isThisGroupAdmin = isItAdmin(info);

            const res = { ...o, isThisGroupAdmin: isThisGroupAdmin };
            if (isMounted)
              setData((pre) => {
                return [...pre, res];
              });

              listUserId.push(o.azureId)
          });
          
          setCurrentUsers(listUserId)

          const setGroupAdminButton = {
            title: 'Group Admin',
            field: 'isThisGroupAdmin',
            lookup: { true: 'Yes', false: 'No' },
            editable: 'onUpdate',
          };
          setRows([setGroupAdminButton, ...userTableColumns]);
          console.log(rows);
        // }

      }
    }

    run();

    return () => {
      isMounted = false;
      setData([]);
    };
  }, [loading, response]);

  const handleOpenAddUsers = () => {
    setAddUsers(true);
  };

  const handleCloseAddUsers = () => {
    setAddUsers(false);
    refresh();
  };

  const handleCloseAddUsersNoSave = () => {
    setAddUsers(false);
  };

  const handleRowClick = (event?: React.MouseEvent, rowData?: ProfileType) => {
    try {
      if (rowData !== undefined && rowData.id) {
        setProfileData(rowData);
        setOpenIsAdmin(true);
        history.push(`/user/${rowData.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (error) {
    console.log(error);
  }

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Grid container direction='column' spacing={3}>
          <Grid item>
            {openAddUsers ? (
              <Dialog
                open={openAddUsers}
                fullScreen={false}
                maxWidth={'xl'}
                clickClose={handleCloseAddUsersNoSave}
              >
                <AddUser clickClose={handleCloseAddUsers} groupId={_groupId} currentUsers={currentUsers}/>
              </Dialog>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item>
            <div style={{ height: 600, width: 'auto' }}>
              <MaterialTable
                columns={rows ?? []}
                data={data ?? []}
                title={'Users'}
                options={{
                  emptyRowsWhenPaging: false,
                  grouping: true,
                  pageSize: 15,
                  pageSizeOptions: [15, 50, 100],
                  columnResizable: true,
                }}
                onRowClick={handleRowClick}
                actions={[
                  {
                    icon: () => (
                      <AddCircleIcon color='success' fontSize='large' />
                    ),
                    tooltip: 'Add User(s)',
                    isFreeAction: true,
                    onClick: (event) => handleOpenAddUsers(),
                  },
                ]}
                editable={{
                  onRowUpdate: (newData: any, oldData: any) =>
                    httpClient
                      .request({
                        url: `/api/group/${_groupId}/user`,
                        method: 'PUT',
                        data: {
                          userId: newData.id,
                          newRole: newData.isThisGroupAdmin === 'true' ? 'Admin' : 'User',
                        },
                        headers: {
                          'Content-Type': 'text/json',
                          Authorization: `Bearer ${accessToken}`,
                        },
                        responseType: 'json',
                      })
                      .then(() => {
                        createNotification({
                          message: 'Success',
                          options: { variant: 'success' },
                        });
                      })
                      .catch(() => {
                        createNotification({
                          message: 'Error while saving.',
                          options: { variant: 'error' },
                        });
                      })
                      .finally(() => refresh()),

                  onRowDelete: (oldData) =>
                    httpClient
                      .request({
                        url: '/api/group/user',
                        method: 'DELETE',
                        data: {
                          groupId: _groupId,
                          userId: oldData.azureId,
                        },
                        headers: {
                          'Content-Type': 'text/json',
                          Authorization: `Bearer ${accessToken}`,
                        },
                        responseType: 'json',
                      })
                      .then(() => {
                        createNotification({
                          message: 'Success',
                          options: { variant: 'success' },
                        });
                      })
                      .catch(() => {
                        createNotification({
                          message: 'Error: unable to delete.',
                          options: { variant: 'error' },
                        });
                      })
                      .finally(() => refresh()),
                }}
              />
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
