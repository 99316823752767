import React from 'react';
import { Column } from '@material-table/core';
import { CsvBuilder } from 'filefy';
import _ from 'lodash';
import { CarType } from '../models/FleetAsset';
import { CarMileageReading } from '../models/CarMileageReading';

export function ExportCsv<T extends object>(
  notifier: any,
  columns: Array<Column<T>>,
  data: Array<T>,
  tableData: {
    searchedData: T[];
    filteredData: T[];
    groupedData: T[];
  },
  filename: string = 'data',
  delimiter: string = ','
) {
  try {
    console.log('columns', columns);
    let finalData: any = data;
    if(CarTableTypeCheck(data)){
        finalData = CarTableExportFormatter(data);
    }
    else if(MileageTableTypeCheck(data)){
        finalData = MileageExportFormatter(data);
    }

    if (data.length && !Array.isArray(data[0])) {
      if (typeof data[0] === 'object') {
        // @ts-ignore
        finalData = finalData.map((row) =>
          columns.map((col) =>
            col.exportTransformer
              ? col.exportTransformer(row)
              : // @ts-ignore
                row[col.field]
          )
        );
      }
    }

    const builder = new CsvBuilder(filename + '.csv');
    //@ts-ignore
    builder
      .setDelimeter(delimiter)
      //@ts-ignore
      .setColumns(columns.map((col) => col.title))
      .addRows(Array.from(finalData))
      .exportFile();
    notifier({
      message: 'File exported!',
      options: { variant: 'success' },
    });
  } catch (err) {
    notifier({
      message: 'Error during file export',
      options: { variant: 'error' },
    });
  }
}

function CarTableTypeCheck(data: any): data is Array<CarType> {
    return (data as Array<CarType>).every(car => car.id !== undefined);
}

function CarTableExportFormatter(data: Array<CarType>): any {
    return data.map((row: CarType, idx: number) => {
        return {
            ...row
        };
    })
}

function MileageTableTypeCheck(data: any): data is Array<CarMileageReading> {
    return (data as Array<CarMileageReading>).every(car => car.id !== undefined);
}

function MileageExportFormatter(data: Array<CarMileageReading>)
{
    return data.map((row: CarMileageReading, idx: number) => {
        return {
            ...row            
        };
    });
}
