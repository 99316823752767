import React from 'react';

import { Stack, Typography } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

import Grid from '@mui/material/Grid';

import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Sanitized Checkbox' } };

export default function SanitizeConfirmation({ formik }: { formik: any }) {
  const handleSanitizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('sanitized', event.target.checked);
  };

  return (
    <Grid container direction='column' spacing={1}>
      <Grid item xs={4}>
        <div style={{ height: 10, paddingTop: 2 }}>
          <FormHelperText
            hidden={
              !(formik.touched.sanitized && Boolean(formik.errors.sanitized))
            }
            error={true}
          >
            {formik.errors.sanitized}
          </FormHelperText>
        </div>
      </Grid>
      <Grid item xs>
        <Stack direction='row' alignItems='center'>
          <Checkbox
            required
            {...label}
            checked={formik.values.sanitized}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
            onChange={handleSanitizeChange}
            color='secondary'
          />
          <Typography variant='h6'>
            I certify the following surfaces have been cleaned:
          </Typography>
        </Stack>

        <ul>
          <li> Steering wheel </li>
          <li> Gear shift </li>
          <li> Seat belt fastener </li>
          <li> Radio, thermostat and other dashboard controls</li>
          <li> Door handles inside and out and the trunk open/close control</li>
          <li> Window controls</li>
          <li> Glove box</li>
          <li> Fuel cap and any plug-in devices</li>
        </ul>
      </Grid>
    </Grid>
  );
}
