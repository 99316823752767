import * as yup from 'yup';
import { Column } from '@material-table/core';


interface GroupDeptType {
  departmentId: string;
  departmentName: string;
}

export interface GroupType {
  id: number;
  name: string;
  dept: GroupDeptType;
  crossDepartment: boolean;
}

export interface GroupInitType {
  id?: string;
  name?: string;
  departmentId?: string;
  crossDepartment?: boolean;
}

export class NewGroupFormValues implements GroupInitType {
  name: string = '';
  departmentId: string = '';
  crossDepartment: boolean = false;

  constructor(init?: GroupInitType) {
    Object.assign(this, init);
  }
}
export const NewGroupValidationSchema = yup.object({
  name: yup.string().required('Required'),
  departmentId: yup.string().required('Required'),
  crossDepartment: yup.boolean().required(),
});

export type NewGroupValidationSchemaType = yup.InferType<
  typeof NewGroupValidationSchema
>;

export const groupTableColumns: Array<Column<GroupType>> = [
  { title: 'Group Name', field: 'name' },
  { title: 'Department', field: 'dept.departmentName' },
  { title: 'Cross Department', field: 'crossDepartment', lookup: { true: 'Yes', false: 'No' } },
];