import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Fab,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import LoadingSpinner from '../../../../components/Loading';

import Dialog from '../../../../components/Dialog';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import { ProfileType } from '../../../../models/Access';
import { GRID_BOOLEAN_COL_DEF } from '@mui/x-data-grid';
import useAccessToken from '../../../../hooks/useAccessToken';
import useNotifier from '../../../../hooks/useNotifier';
import httpClient from '../../../../utils/httpClient';

interface UserAssignModalProps {
  carId: string;
  open: boolean;
  setClose: Function;
  closeRefresh: Function;
}
const URL = (id: string) => `/api/Car/${id}/users`;
const UPDATE_RESERVATION_URL = (id: string) => `/api/Car/${id}`;

export default function UserAssignModal({
  carId,
  open,
  setClose,
  closeRefresh,
}: UserAssignModalProps) {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [selectedUser, setSelecteduser] = React.useState<string | undefined>(
    undefined
  );
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();
  const { response, error, loading, refresh } = useTokenGetRequest<
    ProfileType[]
  >(URL(carId));

  React.useEffect(() => {
    let isMounted = true;

    return () => {
      isMounted = false;
      setSelecteduser(undefined);
    };
  }, []);

  const submit = () => {
    setIsSubmitting(true);
    httpClient
      .request({
        url: UPDATE_RESERVATION_URL(carId),
        method: 'PUT',
        data: {
          AssignedTo: selectedUser,
        },
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(() => {
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
        setIsSubmitting(false);
      })
      .catch(() => {
        createNotification({
          message: 'Error while Canceling.',
          options: { variant: 'error' },
        });
      })
      .finally(() => closeRefresh());
  };

  const renderSelect = () => (
    <>
      <TextField
        select
        required={true}
        disabled={isSubmitting}
        fullWidth
        value={selectedUser}
        id='selectedUser'
        name='selectedUser'
        label='Select'
        variant='standard'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const { value, name } = event.target;

          setSelecteduser(value);
        }}
      >
        {response &&
          response
            .sort((a: any, b: any) => {
              try {
                if (a.name > b.name) {
                  return 1;
                }
                if (a.name < b.name) {
                  return -1;
                }
                return 0;
              } catch (e) {
                return 0;
              }
            })
            .map((option: ProfileType, index: number) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
      </TextField>

      <Stack
        alignItems='center'
        justifyContent='space-between'
        direction='row'
        spacing={3}
        sx={{ mt: '1em' }}
      >
        <Box sx={{ position: 'relative' }}>
          <Button
            size='small'
            variant='outlined'
            disabled={selectedUser === undefined || isSubmitting}
            onClick={() => submit()}
          >
            Confirm
          </Button>
          {isSubmitting && (
            <CircularProgress
              size={24}
              color='secondary'
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Button
            size='small'
            onClick={() => setClose()}
            color='error'
            variant='outlined'
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </>
  );

  return (
    <>
      {open ? (
        <Dialog
          maxWidth='md'
          open={open}
          fullScreen={false}
          title={'Assign Car'}
          clickClose={() => {
            setClose();
          }}
        >
          <DialogContent sx={{ width: '400px' }}>
            {loading ? (
              <LoadingSpinner size={40} />
            ) : (
              <div>{renderSelect()}</div>
            )}
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}
