import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface loadingProps {
  message?: string;
  size?: number;
}

export default function index({ message = '', size = 100 }: loadingProps) {
  return (
    <>
      <Stack spacing={2} justifyContent='center' alignItems='center' mt={10}>
        <CircularProgress size={size} color='secondary' />
        <Typography>{message}</Typography>
      </Stack>
    </>
  );
}
