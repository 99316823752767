import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

const CARS_DEPT_URL_DEFAULT = '/api/car/department';
const USERS_DEPT_URL_DEFAULT = '/api/department/users';

export interface DeptGroupSelectorState {
  isLoading: boolean;
  group: string;
  carUrl: string;
  userUrl: string;
}

const initialState: DeptGroupSelectorState = {
  isLoading: true,
  group: 'all',
  carUrl: CARS_DEPT_URL_DEFAULT,
  userUrl: USERS_DEPT_URL_DEFAULT,
};

export interface SetDeptGroupAction {
  type: 'SET_DEPT_GROUP';
  group: string;
  carUrl: string;
  userUrl: string;
}

export interface SetDefautlAction {
  type: 'SET_DEPT_DEFAULT';
  group: string;
  carUrl: string;
  userUrl: string;
}

export type KnownAction = SetDeptGroupAction | SetDefautlAction;

const getDeptGroupUrls = (groupName: string) => {
  switch (groupName.toLocaleLowerCase()) {
    case 'all':
      return { carUrl: CARS_DEPT_URL_DEFAULT, userUrl: USERS_DEPT_URL_DEFAULT };

    default:
      return {
        carUrl: `/api/Car/group?groupId=${groupName}`,
        userUrl: `/api/group/members?groupId=${groupName}`,
      };
  }
};

export const DeptGroupSelectionActions = {
  setDeptGroup:
    (group: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const appState = getState();

      const groupUrls = getDeptGroupUrls(group);

      dispatch({
        type: 'SET_DEPT_GROUP',
        group,
        ...groupUrls,
      });
    },
  setDeptGroupDefautl:
    (): AppThunkAction<KnownAction> => (dispatch, getState) => {
      const appState = getState();

      dispatch({
        type: 'SET_DEPT_DEFAULT',
        group: 'all',
        carUrl: CARS_DEPT_URL_DEFAULT,
        userUrl: USERS_DEPT_URL_DEFAULT,
      });
    },
};

export const reducer: Reducer<DeptGroupSelectorState> = (
  state: DeptGroupSelectorState | undefined,
  incomingAction: Action
): DeptGroupSelectorState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case 'SET_DEPT_GROUP':
      return {
        group: action.group,
        carUrl: action.carUrl,
        userUrl: action.userUrl,
        isLoading: false,
      };
    case 'SET_DEPT_DEFAULT':
      return {
        group: action.group,
        carUrl: action.carUrl,
        userUrl: action.userUrl,
        isLoading: false,
      };
    default:
      return state;
  }
};
