import React, { Component, ErrorInfo, ReactNode } from 'react';
import PublicLayout from '../Layout/Public';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

interface Props {}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <PublicLayout>
          <Stack direction='column' spacing={1}>
            <Typography variant='h3'>Something went wrong.</Typography>
            <Typography variant='h4'>
              please refresh the page or contact ITD for Help.
            </Typography>
          </Stack>
        </PublicLayout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
