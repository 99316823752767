import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import SectionName from '../../components/SectionName';
import useTokenGetRequest from '../../hooks/useTokenGetRequest';
import { CarType } from '../../models/FleetAsset';
import CheckInModal from '../Home/components/CheckInModal';
import CheckOutModal from '../Home/components/CheckOutModal';
import HomeContextView from '../Home/HomeContextView';
import NavButton from './NavButton';

export default function index() {
  const [available, setAvailable] = useState<CarType[]>([]);
  const [notAvailable, setNotAvailable] = useState<CarType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [availableView, setAvailableView] = useState<CarType[]>([]);
  const [tagList, setTagList] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string>('');
  const { response, error, loading, refresh } =
    useTokenGetRequest<CarType[]>('/api/Car');
  const history = useHistory();
  useEffect(() => {
    let isMounted = true;

    if (!loading && response !== null) {
      setAvailable([]);
      setNotAvailable([]);
      var tagList = new Set<string>();
      response.forEach((car: CarType) => {
        if (car.tags !== null) {
          car.tags.forEach((tag) => {
            tagList.add(tag.toLocaleUpperCase());
          });
        }
        if (car.checkedOut === null) {
          if (car.assignedTo !== null) {
            if (isMounted) {
              setAvailable((prev) => [car, ...prev]);
              setAvailableView((prev) => [car, ...prev]);
            }
          } else {
            if (isMounted) {
              setAvailable((prev) => [...prev, car]);
              setAvailableView((prev) => [...prev, car]);
            }
          }
        } else {
          if (isMounted) setNotAvailable((prev) => [...prev, car]);
        }
      });

      if (isMounted) {
        setTagList(Array.from(tagList).sort());
        setIsLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [loading, response]);

  const filterAvailableBySelectedTag = () => {
    if (selectedTag === '') {
      setAvailableView(available);
      return;
    }
    const filtered = available.filter((car) => {
      if (car.tags !== null) {
        return car.tags
          .map((tag: string) => tag.toLocaleUpperCase())
          .includes(selectedTag);
      }
      return false;
    });
    setAvailableView(filtered);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) filterAvailableBySelectedTag();
    return () => {
      isMounted = false;
    };
  }, [selectedTag]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedTag(event.target.value);
  };

  return (
    <NavButton
      path={{ location: '/reservation', name: 'Reservations' }}
      pageLabel='CheckIn/Checkout'
    >
      <HomeContextView value={{ reload: refresh }}>
        <Grid container direction='column' spacing={2}>
          {loading ? (
            <Grid item ml={5} mr={5}>
              <div>...Loading</div>
            </Grid>
          ) : (
            <>
              <Grid item ml={5} mr={5} mt={3}>
                <Stack direction='column'>
                  <Stack
                    direction='row'
                    divider={<Divider orientation='vertical' flexItem />}
                    spacing={2}
                    justifyContent='flex-start'
                    alignItems='center'
                    height={'78.91px'}
                  >
                    <Box>
                      <SectionName title={'Checked Out'} />
                      <h6>Select a car to Check In</h6>
                    </Box>
                  </Stack>
                  <Divider
                    style={{ marginBottom: '.5em', marginTop: '.5em' }}
                  />
                  {!isLoading && (
                    <CheckInModal
                      color={'#FF6666'}
                      carList={notAvailable}
                      mobile={true}
                    />
                  )}
                </Stack>
              </Grid>
              <Divider
                style={{ marginBottom: '.5em', marginTop: '.5em' }}
                flexItem
              />
              <Grid item ml={5} mr={5}>
                <Stack direction='column'>
                  <Box>
                    <SectionName title={'Available'} />
                    <h6>Select a car to Check Out</h6>
                  </Box>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <FormHelperText>Filter by Tag</FormHelperText>
                    <Select
                      value={selectedTag}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'filter by tag' }}
                    >
                      <MenuItem value=''>
                        <em>ALL</em>
                      </MenuItem>
                      {tagList.map((tag, index) => (
                        <MenuItem key={index} value={tag}>
                          <em>{tag}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Divider
                    style={{ marginBottom: '.5em', marginTop: '.5em' }}
                  />
                  {!isLoading && (
                    <CheckOutModal carList={availableView} mobile={true} />
                  )}
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </HomeContextView>
    </NavButton>
  );
}
