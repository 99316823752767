import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import MaterialTable from '@material-table/core';
import { Grid } from '@mui/material';

import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import ReservationsType, {
  RESERVATIONS_TABLE_COLUMNS,
} from '../../../models/Reservations';
import httpClient from '../../../utils/httpClient';

const DELETE_RESERVATION_URL = (reservationId: string | number) =>
  `/api/Reservation/${reservationId}`;

export default function reservedVehiclesList() {
  const history = useHistory();

  const dispatch = useDispatch();
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();

  const { response, error, loading, refresh } = useTokenGetRequest<
    ReservationsType[]
  >('api/reservation/dept');

  const handleRowClick = (
    event?: React.MouseEvent,
    rowData?: ReservationsType
  ) => {
    if (rowData?.car?.id) {
      history.push(`/vehicle/${rowData.car.id}`);
    }
  };

  const cancelReservation = (reservationId: number) => {
    return httpClient
      .request({
        url: DELETE_RESERVATION_URL(reservationId),
        method: 'DELETE',
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(() => {
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
      })
      .catch(() => {
        createNotification({
          message: 'Error during Checkout.',
          options: { variant: 'error' },
        });
      })
      .finally(() => {
        refresh();
      });
  };

  return (
    <>
      <Grid container direction='column' spacing={3}>
        <Grid item>
          <div style={{ height: 600, width: 'auto' }}>
            <MaterialTable
              columns={RESERVATIONS_TABLE_COLUMNS}
              data={response ?? []}
              title={'Vehicles Reserved'}
              options={{
                grouping: true,
                pageSize: 15,
                pageSizeOptions: [15, 50, 100],
                emptyRowsWhenPaging: false,
              }}
              isLoading={loading}
              onRowClick={handleRowClick}
              localization={{
                body: {
                  editRow: {
                    saveTooltip: '"YES" - Delete',
                    deleteText:
                      'Are you sure you want to Delete this Reservation?',
                  },
                },
              }}
              editable={{
                onRowDelete: (oldData) => cancelReservation(oldData.id),
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
