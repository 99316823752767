import { Column } from '@material-table/core';
import * as yup from 'yup';
import { toHumanFriendlyDate } from '../utils/timeHelper';
import { FuelLevelValuesMapping, FuelLevels } from '../utils/fuelHelper';
import _ from 'lodash';
import List from '@mui/material/List';
import { CarNoteType } from './FleetAsset';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const classes = {
  messageArea: {
    height: '8vh',
    overflowY: 'auto',
  },
};
export interface CheckInCheckOutType {
  id?: number;
  carId: number;
  userId: number;
  purpose: string;
  checkOutDate: string | null;
  actualCheckInDate?: string | null;
  expectedCheckInDate: string | null;
  endingFuelLevel?: number | null;
  sanitized?: boolean | null;
  notes?: CarNoteType[];
  userName?: string | null;
}

export interface CheckInFormType {
  id: number;
  endingFuelLevel?: number | null;
  sanitized: boolean;
  notes?: string;
}

export const NewCheckOutSchema = yup.object({
  carId: yup.number().moreThan(0),
  userId: yup.number().moreThan(0),
  purpose: yup.string().required('required'),
  checkOutDate: yup.string().required('required'),
  expectedCheckInDate: yup.string().required('required'),
  notes: yup.string(),
});

export const CheckInSchema = yup.object({
  id: yup.number().moreThan(0).required('required'),
  endingFuelLevel: yup.number(),
  sanitized: yup.boolean().oneOf([true], 'Please confirm sanitization.'),
  notes: yup.string().nullable(),
});

export type NewGroupValidationSchemaType = yup.InferType<
  typeof NewCheckOutSchema
>;

export const CHECKOUT_TABLE_COLUMNS: Array<Column<CheckInCheckOutType>> = [
  {
    title: 'Start Date',
    width: '12%',
    field: 'checkoutDate',
    render: (rowData) =>
      rowData.checkOutDate !== null
        ? toHumanFriendlyDate(rowData.checkOutDate)
        : '',
  },
  {
    title: 'End Date',
    width: '12%',
    field: 'expectedEndDate',
    render: (rowData) =>
      rowData.actualCheckInDate === null
        ? 'Approx: ' + toHumanFriendlyDate(rowData.expectedCheckInDate)
        : toHumanFriendlyDate(rowData.actualCheckInDate ?? null),
  },
  { title: 'User', field: 'userName', width: 'auto' },
  { title: 'Purpose', field: 'purpose', width: 'auto' },
  {
    title: 'Ending Fuel Level',
    field: 'endingFuelLevel',
    width: '10%',
    render: (rowData) =>
      rowData.endingFuelLevel !== null
        ? FuelLevelValuesMapping[rowData.endingFuelLevel as FuelLevels]
        : '',
  },
  {
    title: 'Notes',
    field: 'notes',
    width: '35%',
    render: (rowData: CheckInCheckOutType) => {
      if (rowData.notes === null || _.isEmpty(rowData.notes)) {
        return '';
      }
      return (
        <List sx={classes.messageArea}>
          {rowData.notes &&
            rowData.notes
              .sort((a: CarNoteType, b: CarNoteType) => {
                var textA = new Date(a.createdOn);
                var textB = new Date(b.createdOn);
                return textA > textB ? -1 : textA < textB ? 1 : 0;
              })
              .map((note: CarNoteType, idx: number) => (
                <ListItem dense key={note.userId}>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '14px',
                      color: '#607d8b',
                    }}
                    secondaryTypographyProps={{
                      color: '#0E86D4',
                      fontSize: '10px',
                    }}
                    primary={`${note.note}`}
                    secondary={`${note.userName}, ${toHumanFriendlyDate(
                      note.createdOn
                    )}`}
                  />
                </ListItem>
              ))}
        </List>
      );
    },
  },
];
