import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import $ from 'jquery';
import CircularProgress from '@mui/material/CircularProgress';

export default function NewTagForm({ addItems }: { addItems: Function }) {
  const [tagValue, setTagValue] = useState<string>('');
  const [tags, setTags] = useState<Set<string>>(new Set());
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const addItem = (item: string) => {
    setTags((prev) => new Set(prev).add(item));
  };

  const removeItem = (item: string) => {
    setTags((prev) => {
      const next = new Set(prev);

      next.delete(item);

      return next;
    });
  };

  const onAddClick = () => {
    addItem(tagValue);
    setTagValue('');
    $('#add-tag-input').focus();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagValue(event.target.value);
  };

  return (
    <form
      noValidate
      autoComplete='off'
      onSubmit={(event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const list: string[] = Array.from(tags);
        addItems(list);
      }}
      onKeyDown={(event) => {
        // prevent enter from submitting form
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      }}
    >
      <Box sx={{ overflow: 'hidden', width: 600, height: 320, margin: 2 }}>
        <FormControl sx={{ m: 0.5, mt: 1 }} variant='filled' fullWidth>
          <InputLabel htmlFor='filled-adornment-password'>Tag</InputLabel>
          <FilledInput
            onKeyDown={(event) => {
              if (tagValue.length === 0) return;
              // add tag on enter press
              if (event.keyCode === 13) {
                onAddClick();
              }
            }}
            autoFocus
            id='add-tag-input'
            value={tagValue}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position='end'>
                <Fab
                  onClick={onAddClick}
                  disabled={tagValue.length === 0 || isSubmitting}
                  color='success'
                  aria-label='add item to tag list'
                  size='small'
                  type='submit'
                >
                  <AddIcon fontSize='large' />
                </Fab>
              </InputAdornment>
            }
          />
        </FormControl>
        <Paper sx={{ margin: 0.5, overflow: 'auto' }} elevation={3} square>
          <Box sx={{ height: 200 }}>
            {Array.from(tags).map((word: string) => (
              <Chip
                sx={{ margin: 1 }}
                label={word.toUpperCase()}
                onDelete={() => removeItem(word)}
              />
            ))}
          </Box>
        </Paper>

          <Button
            sx={{ margin: 0.5 }}
            variant='contained'
            color='secondary'
            disabled={tags.size < 1 || isSubmitting}
            type='submit'
          >
            Add Tags
          </Button>
          {isSubmitting && (
            <CircularProgress
              size={72}
              color='secondary'
              sx={{
                position: 'absolute',
                top: '50%',
                left: '47%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
      </Box>
    </form>
  );
}
