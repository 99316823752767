import { Column } from '@material-table/core';
import { toHumanFriendlyDate } from '../utils/timeHelper';
import { ProfileType } from './Access';
import { CheckInCheckOutType } from './CheckInCheckOut';
import { CarType } from './FleetAsset';
import { format } from 'date-fns';

export default interface ReservationsType {
  id: number;
  expectedStartTime: string;
  expectedEndTime: string | null;
  user: ProfileType;
  car: CarType;
  purpose: string;
  createdOn: string;
  checkOut?: CheckInCheckOutType | null;
}

export const RESERVATIONS_TABLE_COLUMNS: Array<Column<ReservationsType>> = [
  { title: 'Asset Number', field: 'car.assetNumber', width: 10 },
  // { title: 'Active', field: 'car.active', lookup: { true: 'Active' } },
  // { title: 'Mileage', field: 'car.latestMileage' },
  // { title: 'License', field: 'car.licensePlate' },
  { title: 'Reserved By', field: 'user.name' },
  {
    title: 'Expected Check Out',
    field: 'expectedStartTime',
    type: 'date',

    render: (rowData, renderType) => {
      if (renderType === 'row') {
        if (rowData.expectedStartTime !== null) {
          return toHumanFriendlyDate(rowData.expectedStartTime);
        }
        return '';
      } else {
        if (rowData !== null) {
          return format(new Date(rowData.toString()), 'MM-dd-yyyy');
        } else {
          return '';
        }
      }
    },
  },
  {
    title: 'Expected Return',
    field: 'expectedEndTime',
    render: (rowData, renderType) => {
      if (renderType === 'row') {
        if (rowData.expectedEndTime !== null) {
          return toHumanFriendlyDate(rowData.expectedEndTime);
        }
        return '';
      } else {
        if (rowData !== null) {
          return format(new Date(rowData.toString()), 'MM-dd-yyyy');
        } else {
          return '';
        }
      }
    },
  },
  {
    title: 'Description',
    sorting: false,
    render: (rowData, renderType) => {
      if (renderType === 'row') {
        if (rowData.expectedEndTime !== null) {
          return `${rowData.car.year} ${rowData.car.make} ${rowData.car.model}`;
        }
        return '';
      } else {
        if (rowData !== null) {
          return `${rowData.car.year} ${rowData.car.make} ${rowData.car.model}`;
        } else {
          return '';
        }
      }
    },
  },
  // { title: 'Make', field: 'car.make' },
  // { title: 'Model', field: 'car.model' },
  // { title: 'Year', field: 'car.year' },
  // { title: 'Color', field: 'car.color' },
  { title: 'Purpose', field: 'purpose' },
  {
    title: 'Created',
    field: 'createdOn',
    render: (rowData, renderType) => {
      if (renderType === 'row') {
        if (rowData.createdOn !== null) {
          return toHumanFriendlyDate(rowData.createdOn);
        }
        return '';
      } else {
        if (rowData !== null) {
          return format(new Date(rowData.toString()), 'MM-dd-yyyy');
        } else {
          return '';
        }
      }
    },
  },
];
