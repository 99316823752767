import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { AccessState } from '../../../../store/Access';

interface CarImageProps {
  id: string | null;
  width?: number;
  height?: number;
}

export default function CarImage({
  id,
  width = 120,
  height = 100,
}: CarImageProps) {
  const { profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );
  const default_image =
    'https://gsmechanical.com.au/wp-content/uploads/2018/01/AdobeStock_110205977-e1516765819712.jpeg';

  const imageUrl =
    id === null ? default_image : `${id}?${profile?.storageToken}`;

  return (
    <img
      style={{ borderRadius: 10 }}
      id='carImage'
      height={height}
      width={width}
      src={imageUrl}
    />
  );
}
