import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { SnackbarMessage, OptionsObject, SnackbarKey } from "notistack";
import _ from "lodash";
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface NotificationMessageType {
  message: SnackbarMessage;
  options?: OptionsObject;
}

export interface NotificationType extends NotificationMessageType {
  key: SnackbarKey;
  dismissed?: boolean;
}

export interface SnakBarStateType {
  notifications: NotificationType[];
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

const initialState = {
  notifications: [],
};

export interface EnqueSnackBarpAction {
  type: "ENQUEUE_SNACKBAR";
  notification: NotificationType;
}
export interface CloseSnackBarpAction {
  type: "CLOSE_SNACKBAR";
  dismissAll: boolean; // dismiss all if no key has been defined
  key: SnackbarKey;
}
export interface RemoveSnackBarpAction {
  type: "REMOVE_SNACKBAR";
  key: SnackbarKey;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | EnqueSnackBarpAction
  | CloseSnackBarpAction
  | RemoveSnackBarpAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const snackBarActions = {
  enqueueSnackbar:
    (message: NotificationMessageType): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const appState = getState();
      const key: SnackbarKey =
        message?.options?.key ?? new Date().getTime() + Math.random();
      if (appState) {
        dispatch({
          type: "ENQUEUE_SNACKBAR",
          notification: {
            ...message,
            key: key,
            dismissed: false,
          },
        });
      }
    },
  closeSnackbar:
    (key: SnackbarKey): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const dismissAll = _.isNumber(key) ? !_.isEmpty(key) : _.isEmpty(key);
      const appState = getState();
      if (appState) {
        dispatch({
          type: "CLOSE_SNACKBAR",
          dismissAll, // dismiss all if no key has been defined
          key,
        });
      }
    },
  removeSnackbar:
    (key: SnackbarKey): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: "REMOVE_SNACKBAR",
          key,
        });
      }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<SnakBarStateType> = (
  state: SnakBarStateType | undefined,
  incomingAction: Action
): SnakBarStateType => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "ENQUEUE_SNACKBAR":
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      };
    case "CLOSE_SNACKBAR":
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          action.dismissAll || notification.key === action.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        ),
      };
    case "REMOVE_SNACKBAR":
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      };
    default:
      return state;
  }
};
