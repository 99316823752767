import Typography from '@mui/material/Typography';
import moment from 'moment';
import React from 'react';

import { Box, FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { createCheckInSelection } from '../../../utils/timeHelper';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  '& :hover': {
    backgroundColor: '#eeeeee',
  },
}));

const TODAY = new Date();

const clockIcon: { [index: string]: React.ReactElement } = {
  '1200': (
    <span role='img' aria-label='calendar icon'>
      🕛
    </span>
  ),
  '1230': (
    <span role='img' aria-label='calendar icon'>
      🕧
    </span>
  ),
  '0100': (
    <span role='img' aria-label='calendar icon'>
      🕐
    </span>
  ),
  '0130': (
    <span role='img' aria-label='calendar icon'>
      🕜
    </span>
  ),
  '0200': (
    <span role='img' aria-label='calendar icon'>
      🕑
    </span>
  ),
  '0230': (
    <span role='img' aria-label='calendar icon'>
      🕝
    </span>
  ),
  '0300': (
    <span role='img' aria-label='calendar icon'>
      🕒
    </span>
  ),
  '0330': (
    <span role='img' aria-label='calendar icon'>
      🕞
    </span>
  ),
  '0400': (
    <span role='img' aria-label='calendar icon'>
      🕓
    </span>
  ),
  '0430': (
    <span role='img' aria-label='calendar icon'>
      🕟
    </span>
  ),
  '0500': (
    <span role='img' aria-label='calendar icon'>
      🕔
    </span>
  ),
  '0530': (
    <span role='img' aria-label='calendar icon'>
      🕠
    </span>
  ),
  '0600': (
    <span role='img' aria-label='calendar icon'>
      🕕
    </span>
  ),
  '0630': (
    <span role='img' aria-label='calendar icon'>
      🕡
    </span>
  ),
  '0700': (
    <span role='img' aria-label='calendar icon'>
      🕖
    </span>
  ),
  '0730': (
    <span role='img' aria-label='calendar icon'>
      🕢
    </span>
  ),
  '0800': (
    <span role='img' aria-label='calendar icon'>
      🕗
    </span>
  ),
  '0830': (
    <span role='img' aria-label='calendar icon'>
      🕣
    </span>
  ),
  '0900': (
    <span role='img' aria-label='calendar icon'>
      🕘
    </span>
  ),
  '0930': (
    <span role='img' aria-label='calendar icon'>
      🕤
    </span>
  ),
  '1000': (
    <span role='img' aria-label='calendar icon'>
      🕙
    </span>
  ),
  '1030': (
    <span role='img' aria-label='calendar icon'>
      🕥
    </span>
  ),
  '1100': (
    <span role='img' aria-label='calendar icon'>
      🕚
    </span>
  ),
  '1130': (
    <span role='img' aria-label='calendar icon'>
      🕦
    </span>
  ),
};

interface TimePickerDialogProps {
  setSelectedDate: Function;
  scheduledDay: Date | undefined;
}

export default function TimePickerDialog({
  scheduledDay,
  setSelectedDate,
}: TimePickerDialogProps) {
  const listTime = createCheckInSelection(TODAY);

  const handleSelectChange = (
    event: SelectChangeEvent | React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (scheduledDay === undefined) {
      return;
    }
    const _newDatetime = event.target.value;
    const _day = new Date(_newDatetime);
    const hours = _day.getHours();
    const minutes = _day.getMinutes();
    const _date = new Date(scheduledDay);

    _date.setHours(hours, minutes, 0);
    setSelectedDate(_date);
  };

  return (
    <StyledBox>
      <FormControl variant='standard' sx={{ width: 160 }} size='medium'>
        <Select
          sx={{ width: 150, margin: '.5em' }}
          renderValue={(value) => {
            return (
              <Typography variant='h5' fontSize='20px'>
                {moment(scheduledDay).format('hh:mm a')}
                {clockIcon[moment(scheduledDay).format('hhmm')]}
              </Typography>
            );
          }}
          labelId='Check-In-time-select-label'
          id='Check-In-time-select'
          value={moment(scheduledDay).format('YYYY-MM-DDTHH:mm') ?? ''}
          onChange={handleSelectChange}
          size='medium'
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {listTime &&
            listTime.map((t: Date, idx: number) => (
              <MenuItem
                key={moment(t).format('YYYY-MM-DDTHH:mm')}
                value={moment(t).format('YYYY-MM-DDTHH:mm')}
              >
                <Typography variant='h6'>
                  {moment(t).format('hh:mm a')}
                </Typography>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </StyledBox>
  );
}
