import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";

export interface AppBackdropState {
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
};

export interface SetAppBackdropAction {
  type: "SET_BACKDROP";
  isLoading: boolean;
}

export type KnownAction = SetAppBackdropAction;

export const backdropActions = {
  closeBackDrop: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch({
        type: "SET_BACKDROP",
        isLoading: false,
      });
    }
  },
  openBackDrop: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch({
        type: "SET_BACKDROP",
        isLoading: true,
      });
    }
  },
};

export const reducer: Reducer<AppBackdropState> = (
  state: AppBackdropState | undefined,
  incomingAction: Action
): AppBackdropState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "SET_BACKDROP":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};
