import MaterialTable from '@material-table/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Grid from '@mui/material/Grid';
import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import Dialog from '../../../components/Dialog';
import LoadingSpinner from '../../../components/Loading';
import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { CarType, vehicleTableColumns } from '../../../models/FleetAsset';
import httpClient from '../../../utils/httpClient';
import AddCar from './components/AddCar';

export default function GroupTable({ url }: { url: string }) {
  const { id } = useParams<{ id: string | undefined }>();
  const { createNotification } = useNotifier();
  const { accessToken } = useAccessToken();
  const { response, error, loading, refresh } =
    useTokenGetRequest<CarType[]>(url);
  const [open, setOpen] = React.useState(false);
  const [openAddCars, setOpenAddCars] = React.useState(false);

  const [tableRowData, setRowData] = React.useState<CarType | null>(null);
  const history = useHistory();

  const handleOpenCars = () => {
    setOpenAddCars(true);
  };

  const handleCloseCars = () => {
    setOpenAddCars(false);
    refresh();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setRowData(null);
    setOpen(false);
  };

  const handleRowClick = (event?: React.MouseEvent, rowData?: CarType) => {
    try {
      if (rowData !== undefined && rowData.assetId) {
        setRowData(rowData);
        history.push(`/vehicle/${rowData.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenAddCars = () => {
    handleOpenCars();
  };

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <>
          {openAddCars ? (
            <Dialog
              open={openAddCars}
              fullScreen={false}
              maxWidth={'xl'}
              clickClose={handleCloseCars}
              title={'Add Car(s) to Group'}
            >
              <AddCar clickClose={handleCloseCars} carBlackList={response} />
            </Dialog>
          ) : (
            <></>
          )}
        </>
        <Grid container direction='column' spacing={3}>
          <Grid item>
            <div style={{ height: 600, width: 'auto' }}>
              <MaterialTable
                columns={vehicleTableColumns()}
                data={response ?? []}
                title={'Vehicle Information'}
                options={{
                  grouping: true,
                  pageSize: 15,
                  pageSizeOptions: [15, 50, 100],
                  emptyRowsWhenPaging: false,
                }}
                onRowClick={handleRowClick}
                actions={[
                  {
                    icon: () => (
                      <AddCircleIcon color='success' fontSize='large' />
                    ),
                    tooltip: 'Add Car(s)',
                    isFreeAction: true,
                    onClick: (event) => handleOpenAddCars(),
                  },
                ]}
                editable={{
                  onRowDelete: (oldData) =>
                    httpClient
                      .request({
                        url: '/api/Car/group',
                        method: 'DELETE',
                        data: {
                          groupId: parseInt(id ?? '-1', 10),
                          carId: oldData.id,
                        },
                        headers: {
                          'Content-Type': 'text/json',
                          Authorization: `Bearer ${accessToken}`,
                        },
                        responseType: 'json',
                      })
                      .then(() => {
                        createNotification({
                          message: 'Success',
                          options: { variant: 'success' },
                        });
                      })
                      .catch(() => {
                        createNotification({
                          message: 'Error: unable to delete.',
                          options: { variant: 'error' },
                        });
                      })
                      .finally(() => refresh()),
                }}
              />
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
