import React from 'react';
import { UploadFile, Delete } from '@mui/icons-material';
import { Paper, Button, Grid, Stack } from '@mui/material';
import {useDropzone} from 'react-dropzone'

import { UploadDocumentMetadata } from '../../../models/UploadDocumentMetadata';

export default function SingleDocument({ 
    uploadDocument,
    deleteFunction, 
    updateFunction, 
    appendFunction
  }: {
    uploadDocument: UploadDocumentMetadata, 
    deleteFunction: () => void, 
    updateFunction: () => void, 
    appendFunction : (appendDocument: UploadDocumentMetadata[]) => void
  }){
    
    const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone();

    React.useEffect(() => {
      if(acceptedFiles.length == 1){
        uploadDocument.file = acceptedFiles[0];
      }else if(acceptedFiles.length > 1){
        uploadDocument.file = acceptedFiles[0];
        appendFunction(acceptedFiles.slice(1).map(document => { return {documentType: uploadDocument.documentType, file: document} as UploadDocumentMetadata } ));
      }}, [acceptedFiles]);
    return (
    <Grid item>
      <Stack direction='row' spacing={1}>
      <Paper {...getRootProps()} sx={{borderStyle: 'dashed', borderWidth: 2, borderColor: 'rgb(0,0,0,0.5)', borderRadius: 2, width: 400, paddingTop: 1, paddingBottom: 1}} className="text-center">
        <input {...getInputProps()} />
        {
          isDragActive ?
            <span><UploadFile /> Dropping files...</span> :
            uploadDocument.file !== null ? uploadDocument.file.name : <span><UploadFile /> Drop files here, or click to select files</span>
        }
      </Paper>
      <select value={uploadDocument.documentType} onChange={event => {
          uploadDocument.documentType = event.currentTarget.value;
          updateFunction();
          }}>
        <option value="">Please choose a category</option>
        <option value="insurance">Insurance Document</option>
        <option value="accident">Accident Report</option>
      </select>
      <Button size='small' variant='outlined' color='error' onClick={deleteFunction}><Delete /></Button>
      </Stack>
    </Grid>
    );
}