import * as React from 'react';
import { Grid, Button, DialogContent, Link, DialogActions, CircularProgress, TextField, MenuItem, InputAdornment, Divider, Box, Typography } from '@mui/material';
import { Delete, Folder, MoveToInbox,  } from '@mui/icons-material';
import useAccessToken from '../../../hooks/useAccessToken';
import { documentDeleteService, documentGetService, documentMoveToFolder, unarchiveDocument } from '../../../services/document';
import urljoin from 'url-join';
import { Attachment, DocumentMetadata, FolderMetadata } from '../../../models/Attachment';
import Dialog from '../../../components/Dialog';
import { green } from '@mui/material/colors';
import LoadingSpinner from '../../../components/Loading';
import useTestTokenRequest from '../../../hooks/useTokenGetRequest';


export default function DocumentActions({attachment, updateFunction }: {attachment: Attachment, updateFunction: () => void})
{
    const {accessToken} = useAccessToken();
    const [confirmDeletion, setDeleteConfirmation] = React.useState<boolean>(false);
    const [deleteCompleted, setCompletionStatus] = React.useState<boolean>(false);
    const [loadingImage, setLoadingStatus] = React.useState<boolean>(false);
    const [deleteInProgress, setInprogress] = React.useState<boolean>(false);
    const [moveStatus, setMoveStatus] = React.useState<number>(0);
    const [destinationFolderId, setDestinationFolder] = React.useState<string>("");
    const [unarchiveDocumentDialog, setUnarchiveDialog] = React.useState<boolean>(false);

    const handleDeleteCall = () => {
        setInprogress(true);
        setDeleteConfirmation(false);
        documentDeleteService(accessToken, attachment.id).then(() => {
            setCompletionStatus(true);
        });
    }

    const handleCompleteClose = () => {
        setDeleteConfirmation(false);
        setCompletionStatus(false);
        setLoadingStatus(false);
        setUnarchiveDialog(false);
        updateFunction();
    }

    const handleGetDocumentCall = () => {
        setLoadingStatus(true);
        documentGetService(accessToken,attachment.id).then((data) => {
            const href = URL.createObjectURL(data.data);
            var link = document.createElement('a');
            link.href = href;
            link.download = attachment.name;
            link.click();
            setLoadingStatus(false);
        });
    }

    const handleMoveAction = () => {
        if(destinationFolderId !== undefined){
            documentMoveToFolder(accessToken, attachment.id,destinationFolderId).then((data) => {
                setMoveStatus(0);
                updateFunction();
            });
        }
    }

    const handleMoveBaseAction = () => {
        setDestinationFolder("");
        documentMoveToFolder(accessToken, attachment.id,null).then((data) => {
            setMoveStatus(0);
            updateFunction();
        });
        
    }

    const handleUnarchiveAction = () => {
        unarchiveDocument(accessToken, attachment.id).then((data) => {
            setMoveStatus(0);
            updateFunction();
        })
    }

    return (
        <Grid container direction="row" spacing={2}>  
            <Grid item>                
                <Button variant='outlined' color="info" onClick={handleGetDocumentCall}>
                    {loadingImage ? (
                        <CircularProgress
                            size={24}
                            sx={{
                            color: green[500],
                            top: '50%',
                            left: '50%',                            
                            }}/>
                    ) : (<></>) }
                    <span> View</span>
                </Button>
            </Grid>            
            {
                attachment.archived ? 
                (<>
                    <Grid item>
                        <Button variant='outlined' color='success' onClick={() => setUnarchiveDialog(true)}><MoveToInbox /> Unarchive Document</Button>
                    </Grid>
                </>): 
                (<>
                <Grid item>
                    <Button variant='outlined' color='success' onClick={() => setMoveStatus(1)} ><MoveToInbox /> Move Document</Button> 
                </Grid>
                <Grid item>                
                    <Button variant='outlined' color='error' onClick={() => setDeleteConfirmation(true)}><Delete /> Archive</Button>
                </Grid>
                </>) 
            }
            
            {confirmDeletion ? 
                (
                    <Grid item>
                        <Dialog
                            open={confirmDeletion}
                            clickClose={() => setDeleteConfirmation(false)}
                            fullScreen={false}
                            >
                                <>
                                    <DialogContent>
                                        <h4>Are you sure you want to archive this document?</h4>
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid container direction='row-reverse' spacing={2}>
                                            <Grid item>
                                                <Button type='button' variant='contained' color='error' onClick={handleDeleteCall}>Confirm</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button type='button' variant='outlined' onClick={() => setDeleteConfirmation(false)}>Cancel</Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </>
                        </Dialog>

                    </Grid>
                ): (<></>)}
            {
                (moveStatus == 1) ? 
                    (<Dialog open={moveStatus==1} clickClose={() => setMoveStatus(0)} fullScreen={false} maxWidth="md">
                        <>
                       <DialogContent>
                            <Box textAlign="center" marginBottom={5}>                            
                                <h3 style={{borderBottom:"1px solid rgba(0, 0, 0, 0.12)", paddingBottom:"3px"}}>Move Document</h3>
                            </Box>
                            <Grid container direction="row" spacing={3} alignItems='center'>
                                <Grid item>
                                    <FolderSelection parentId={attachment.parentId} parentType={1} selectedFolder={destinationFolderId} setFolderId={setDestinationFolder} />
                                </Grid>
                                <Grid item>
                                    <Button variant='contained' color="secondary" onClick={handleMoveAction}>Move</Button>
                                </Grid>
                                {
                                    (attachment as DocumentMetadata).parentFolder !== undefined ? 
                                        (<>
                                            <Divider orientation="vertical" flexItem>
                                                OR
                                            </Divider>
                                            <Grid item>
                                                <Button variant='outlined' color="info" onClick={handleMoveBaseAction}>Move to base folder</Button>
                                            </Grid>
                                        </>): (<></>)}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container direction="row" spacing={2}>
                                <Grid item>
                                    
                                </Grid>
                            </Grid>
                        </DialogActions>
                        </>
                    </Dialog>) :
                    (<></>)
            }
            {unarchiveDocumentDialog ? (
                <Dialog open={unarchiveDocumentDialog}
                    fullScreen={false}
                    clickClose={() => setUnarchiveDialog(false)}>
                    <>
                        <DialogContent>
                            <h3>Do you want to unarchive this document?</h3>
                            <Grid container justifyContent='center' >
                                <Grid item>                                    
                                    <b><i>{attachment.name}</i></b>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container direction="row-reverse" spacing={2}>
                                <Grid item>
                                    <Button type='button' color='secondary'>No</Button>
                                </Grid>
                                <Grid item>
                                    <Button type='button' color='primary' variant='outlined' onClick={handleUnarchiveAction}>Yes</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                </Dialog>) : 
                (<></>)}
            {deleteInProgress ? (
                <Dialog open={deleteInProgress}
                    fullScreen={false}
                    clickClose={() => {}}>
                    <DialogContent>
                            <h3>
                                <LoadingSpinner message="" /> 
                                {`Deleting Document`}
                            </h3>
                        </DialogContent>
                    </Dialog>
                    ): (<></>)}
            {deleteCompleted ? (
                <Grid item>
                        <Dialog
                            open={deleteCompleted}
                            clickClose={handleCompleteClose}
                            fullScreen={false}
                            >
                                <>
                                    <DialogContent>
                                        <h4>Document Archived</h4>
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid container direction='row-reverse' spacing={2} justifyContent='center' alignItems={'center'}>
                                            <Grid item>
                                                <Button type='button' variant='contained' onClick={handleCompleteClose}>Close</Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </>
                        </Dialog>

                    </Grid>): (<></>)}
        </Grid>
    );
}

function FolderSelection({parentId, parentType, selectedFolder, setFolderId}:{parentId: number, parentType: number, selectedFolder: string, setFolderId: (folderId : string) => void}){
    const {response, error, loading} = useTestTokenRequest<{ data :FolderMetadata[]}>(`/api/folder?parentId=${parentId}&parentType=${parentType}`);
    
    if(!loading && response != undefined && response?.data.length > 0){
        return (
            <TextField 
                id="folder-selection"
                select 
                label="Folder"
                helperText='Select a destination folder'
                value={selectedFolder}
                onChange={event => setFolderId(event.target.value)}
                >
                {response.data.map(folder => (<MenuItem key={folder.id} value={folder.id}>{folder.name}</MenuItem>))}
            </TextField>
        )
    }else{
        return(
            <select disabled>
                <option>No Folders Available</option>
            </select>
        );
    }

}