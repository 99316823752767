import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar, SnackbarKey, OptionsObject } from "notistack";
import {
  snackBarActions,
  NotificationType,
  NotificationMessageType,
} from "../store/Snackbar";
import { ApplicationState } from "../store";
import Slide from "@mui/material/Slide";

let displayed: SnackbarKey[] = [];

const useNotifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(
    (state: ApplicationState) => state.snackBar?.notifications ?? []
  );

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }: NotificationType) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            dispatch(snackBarActions.removeSnackbar(myKey));
            removeDisplayed(myKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);


  const _enqueueSnackbar = (message: NotificationMessageType) =>
    dispatch(snackBarActions.enqueueSnackbar(message));
  const _closeSnackbar = (key: SnackbarKey = "") =>
    dispatch(snackBarActions.closeSnackbar(key));

  const createNotification = ({ message, options = {} }: NotificationMessageType) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    const _key = new Date().getTime() + Math.random();

    const _defaultOptions: OptionsObject = {
      key: _key,
      variant: "info",
      TransitionComponent: Slide as React.ComponentType,
    };
    
    _enqueueSnackbar({
      options: { ..._defaultOptions, ...options },
      message,
    });
  };

  const handleDimissAll = () => {
    _closeSnackbar();
  };

  return { createNotification, handleDimissAll };
};

export default useNotifier;
