import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Paper,
  InputBase,
  Divider,
  IconButton,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
  Stack,
} from '@mui/material';
import urljoin from 'url-join';
import { Clear } from '@mui/icons-material';

import useGraphToken from '../../hooks/useGraphToken';
import { searchResultVmType } from '../../models/Graph';
import { searchUser } from '../../services/user';
import Image from '../../components/Profile/Image';
import { ApplicationState } from '../../store';
import { AccessState } from '../../store/Access';

const USER_INFO_URL = (id: string): string =>
  urljoin('https://graph.microsoft.com/v1.0/users', id);
const USER_PHOTO_URL = (id: string): string =>
  urljoin(USER_INFO_URL(id), '/photo/$value');
const DEPT_SEARCH_URL = (
  query: string,
  dept: string = 'Information Technology'
) =>
  `https://graph.microsoft.com/v1.0/users?$filter=startsWith(displayName,'${query}') and department eq '${dept}'&$select=id,displayName,mail,department,displayName,jobTitle,userPrincipalName`;
const SEARCH_URL = (query: string) =>
  `https://graph.microsoft.com/v1.0/users?$filter=startsWith(displayName,'${query}')&$select=department,displayName,jobTitle,userPrincipalName,id`;

export default function UserSearch({
  isSubmitting,
  selectUser,
  departmentFilter = true,
  userBlackList = [],
  multiple = false,
  children,
}: {
  isSubmitting: boolean;
  selectUser: Function;
  userBlackList?: string[];
  departmentFilter?: boolean;
  multiple?: boolean;
  children?: React.ReactElement;
}) {
  const { graphToken, refreshGraphToken } = useGraphToken();
  const [searchText, setSearchText] = useState<string>('');
  const [loadingResult, setLoadingResult] = useState<boolean>(true);
  const [searchResult, setSearchResult] = useState<searchResultVmType[]>([]);
  const { profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );
  useEffect(() => {
    if (searchText && searchText.length >= 2) {
      let query: string;
      if (departmentFilter) {
        if (profile?.dept?.departmentName) {
          query = DEPT_SEARCH_URL(searchText, profile.dept.departmentName);
        } else {
          query = DEPT_SEARCH_URL(searchText);
        }
      } else {
        query = SEARCH_URL(searchText);
      }

      refreshGraphToken();
      setLoadingResult(true);
      searchUser(query, graphToken)
        .then((data: any) =>
          data.sort((a: searchResultVmType, b: searchResultVmType) => {
            if (a.displayName > b.displayName) {
              return 1;
            }
            if (a.displayName < b.displayName) {
              return -1;
            }
            return 0;
          })
        )
        .then((data: any) => {
          if (userBlackList?.length > 0) {
            data = data.filter(
              (profile: searchResultVmType) =>
                !userBlackList.includes(profile.id)
            );
          }

          setSearchResult(data);
          setLoadingResult(false);
        });
    } else {
      setSearchResult([]);
      setLoadingResult(false);
    }
  }, [searchText]);

  function CustomizedInputBase() {
    return (
      <Grid container alignItems='center' justifyContent='center'>
        <Grid item>
          <Paper
            component='form'
            sx={{
              p: '2px 4px',
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              width: 400,
            }}
          >
            <InputBase
              autoComplete='false'
              autoCorrect='false'
              autoCapitalize='false'
              spellCheck={false}
              autoFocus
              sx={{ ml: 1, flex: 1 }}
              value={searchText}
              placeholder='Search'
              inputProps={{ 'aria-label': 'search' }}
              onChange={(event) => {
                setSearchResult([]);
                setSearchText(event.target.value);
              }}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
            <IconButton
              type='button'
              sx={{ p: '10px' }}
              aria-label='Clear'
              onClick={() => {
                setSearchText('');
                setSearchResult([]);
              }}
            >
              <Clear />
            </IconButton>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  const customAvailableList = (
    items: searchResultVmType[],
    message: string = 'Type in the Search Box above to find a user'
  ) => {
    if (!items || items.length == 0) {
      return (
        <Card>
          <List
            sx={{
              width: 600,
              height: 450,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component='div'
            role='list'
          >
            <ListItem key={'#48957-32'} role='listitem'>
              <ListItemText id={'34'} primary={`${message}`} />
            </ListItem>
          </List>
        </Card>
      );
    } else {
      return (
        <Card>
          <List
            sx={{
              width: 610,
              height: 380,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component='div'
            role='list'
          >
            {items.map((value: searchResultVmType) => {
              const labelId = `transfer-list-all-item-${value}-label`;
              let adName: string = value.userPrincipalName.split('@')[0];
              return (
                <ListItem
                  defaultValue={value.id}
                  key={value.id}
                  role='listitem'
                  button
                  disabled={isSubmitting}
                  onClick={(event) => {
                    event.preventDefault();
                    selectUser(value);
                  }}
                >
                  <ListItemIcon>
                    <Image
                      userName={value.displayName}
                      imgUrl={USER_PHOTO_URL(value.id)}
                      addAdminCheck={false}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={value.displayName}
                    secondary={`(${adName}) ${value.jobTitle}`}
                  />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Card>
      );
    }
  };

  return (
    <div style={{ height: 470, marginLeft: 16 }}>
      <>
        {CustomizedInputBase()}
        {loadingResult && <LinearProgress color='secondary' />}
      </>

      <Stack direction='row' spacing={4} mt={2} mr={1} sx={{ height: 370 }}>
        <>{customAvailableList(searchResult)}</>
        <>{children !== undefined ? children : <></>}</>
      </Stack>
      <br />
    </div>
  );
}
