import React from 'react';
import Dialog from '../../../../components/Dialog';
import { DialogContent, Button } from '@mui/material';
import DocumentDropZone from '../../uploadDocument/DocumentDropZone';

interface EditCarImageProps {
  carId: string;
  refresh: Function;
}

export default function EditCarImage({ carId, refresh }: EditCarImageProps) {
  const [openEdit, setOpenEdit] = React.useState<boolean>(false);

  return (
    <>
      {openEdit ? (
        <Dialog
          open={openEdit}
          clickClose={() => setOpenEdit(false)}
          title='Edit Car Image'
          maxWidth='sm'
          fullScreen={false}
        >
          <DialogContent>
            <DocumentDropZone carId={carId} handleClose={refresh} />
          </DialogContent>
        </Dialog>
      ) : null}
      <Button onClick={() => setOpenEdit(true)}>Edit</Button>
    </>
  );
}
