import { Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import * as React from 'react';

///built in icons
import ColorLensIcon from '@mui/icons-material/ColorLens';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import EvStationIcon from '@mui/icons-material/EvStation';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SmartScreenIcon from '@mui/icons-material/SmartScreen';
import NumbersIcon from '@mui/icons-material/Numbers';

import { CarType } from '../../../models/FleetAsset';

const textCreate = (
  text: string | null,
  title: string,
  icon: React.ReactNode
): React.ReactNode => {
  return (
    <Tooltip title={title}>
      <Stack
        direction='column'
        spacing={0}
        justifyContent='center'
        alignItems='center'
      >
        {icon}
        <Typography>{text ?? ''}</Typography>
      </Stack>
    </Tooltip>
  );
};

export default function CarForm({ car }: { car: CarType }) {
  return (
    <Grid container spacing={3} justifyContent='flex-start' alignItems='center'>
      <Grid item>
        {textCreate(
          car.assetNumber,
          'Asset Number',
          <NumbersIcon fontSize='large' />
        )}
      </Grid>
      <Grid item>
        {textCreate(
          `${car.year} ${car.make} ${car.model}`,
          'Year Make Model',
          <DriveEtaIcon fontSize='large' />
        )}
      </Grid>
      <Grid item>
        {textCreate(car.color, 'Color', <ColorLensIcon fontSize='large' />)}
      </Grid>
      <Grid item>
        {textCreate(
          car.fuelType,
          'Fuel Type',
          car.fuelType?.toLowerCase() === 'electric' ? (
            <EvStationIcon />
          ) : (
            <LocalGasStationIcon fontSize='large' />
          )
        )}
      </Grid>
      <Grid item>
        {textCreate(
          car.vin,
          'Vin Number',
          <EmojiTransportationIcon fontSize='large' />
        )}
      </Grid>

      <Grid item>
        {textCreate(
          car.licensePlate,
          'License Plate',
          <SmartScreenIcon fontSize='large' />
        )}
      </Grid>
      <Grid item>
        {textCreate(
          car.deptNumber,
          'Department Number',
          <DynamicFeedIcon fontSize='large' />
        )}
      </Grid>
    </Grid>
  );
}
