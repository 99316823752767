import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { COPYRIGHT_LINK } from "../../constants/layout";

export default function Copyright(props: any) {
  return (
    <div style={{ height: "3rem" }}>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        <Link
          color="inherit"
          target="blank"
          href={COPYRIGHT_LINK}
        >
          {`Copyright © ITD ${new Date().getFullYear()}.`}
        </Link>{" "}
      </Typography>
    </div>
  );
}
