import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { CarType } from '../../../models/FleetAsset';
import ReservationsType from '../../../models/Reservations';
import CancelReservationButton from './CancelReservationButton';
import CheckoutReservationButton from './CheckoutReservationButton';

const RESERVATIONS_URL: string = '/api/Reservation/me/future';

const CAR_TITLE_LENGTH = 28;
export default function ReservationCard() {
  const [reservationToday, setReservationToday] =
    React.useState<boolean>(false);
  const { response, error, loading, refresh } =
    useTokenGetRequest<ReservationsType[]>(RESERVATIONS_URL);

  // React.useEffect(() => {
  //   let isMounted = true;

  //   if (!loading) {
  //     console.log(response);
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [loading, response]);

  const getLength = (item: string | null) => {
    if (item === null) {
      return 0;
    }
    return item.length;
  };

  const modelName = (model: string | null, availableCharacters: number) => {
    if (model === null) {
      return '';
    }
    const result =
      model.length > availableCharacters
        ? `${model?.substring(0, availableCharacters)}... - `
        : model.length < availableCharacters
        ? `${model} - `
        : ' - ';

    return result;
  };

  const GenerateCarName = (car: CarType) => {
    const CarNameBase = `${car.year} ${car.make}`;
    const assetNumberLength = getLength(car.assetNumber);

    const availableCharacters =
      CAR_TITLE_LENGTH - (CarNameBase.length + assetNumberLength + 3);

    const result = `${CarNameBase} ${modelName(
      car.model,
      availableCharacters
    )} ${car.assetNumber} \n`;

    return result;
  };

  const GenerateTimeStamp = (dateTime: string) => {
    const date = new Date(dateTime);
    return moment(date).calendar();
  };

  const renderActionButton = (reservationId: number, date: string) => {
    const _date = new Date(date);
    if (isToday(_date)) {
      return (
        <CheckoutReservationButton
          reservationId={reservationId}
          refresh={refresh}
        />
      );
    } else {
      // TODO: edit button.
      return null;
      // <Button
      //   aria-label='checkout vehicle'
      //   color='warning'
      //   size='small'
      //   fullWidth
      // >
      //   edit
      // </Button>
    }
  };

  const GenerateListItem = (reservation: ReservationsType) => (
    <>
      <ListItem alignItems='flex-start' >
        <Grid container direction='row' justifyContent='space-between'>
          <Grid item xs={8}>
            <ListItemText
              primary={GenerateTimeStamp(reservation.expectedStartTime)}
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component='span'
                    variant='body2'
                    color='text.primary'
                  >
                    {GenerateCarName(reservation.car)}
                  </Typography>

                  {` — ${reservation.purpose}`}
                </>
              }
            />
          </Grid>

          <Grid item xs={4}>
            <Grid container direction='column' justifyContent='space-around'>
              <Grid item>
                <CancelReservationButton
                  reservationId={reservation.id}
                  refresh={refresh}
                />
              </Grid>

              <Grid item>
                {renderActionButton(
                  reservation.id,
                  reservation.expectedStartTime
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </>
  );

  const renderNoReservations = () => (
    <>
      <ListItem alignItems='flex-start' sx={{width: 368}}>
        <ListItemText primary='No Reservations at this time.' />
      </ListItem>
      <Divider />
    </>
  );

  const renderLoading = () => (
    <>
      <ListItem alignItems='flex-start' sx={{width: 368}}>
        <ListItemText primary='loading...' />
      </ListItem>
      <Divider />
    </>
  );

  // if (loading) {
  //   return <div>loading...</div>;
  // }

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 380,
        height: 100,
        bgcolor: 'background.paper',
        overflow: 'auto',
        borderRadius: 1.5,
      }}
    >
      <Divider />
      {loading
        ? renderLoading()
        : _.isEmpty(response) || _.isNull(response)
        ? renderNoReservations()
        : response.map((reservation: ReservationsType) =>
            GenerateListItem(reservation)
          )}
    </List>
  );
}

function isToday(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}
