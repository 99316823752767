import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import Dialog from '../../../../components/Dialog';
import useAccessToken from '../../../../hooks/useAccessToken';
import useNotifier from '../../../../hooks/useNotifier';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import httpClient from '../../../../utils/httpClient';
import NewTagForm from './NewTagForm';

const CAR_TAGS_URL = (id: number | string) => `/api/Car/${id}/tag`;

export default function Tags({
  tags,
  carId,
}: {
  tags: string[];
  carId: string | number;
}) {
  const { response, error, loading, refresh } = useTokenGetRequest<string[]>(
    CAR_TAGS_URL(carId)
  );
  const { createNotification } = useNotifier();
  const { accessToken, refreshToken } = useAccessToken();
  // const [newTags, setNewTags] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    let isMounted = true;

    if (!loading && response !== null) {
      console.log(response);
    }

    return () => {
      isMounted = false;
    };
  }, [loading, response]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const addNewTags = (newTags: string[]) => {
    httpClient
      .request({
        url: CAR_TAGS_URL(carId),
        method: 'POST',
        data: { tags: newTags },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        // responseType: 'json',
      })
      .then(() => {
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
      })
      .catch(() => {
        createNotification({
          message: 'Error: unable to add.',
          options: { variant: 'error' },
        });
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleDelete = (tag: string) => {
    httpClient
      .request({
        url: CAR_TAGS_URL(carId),
        method: 'PUT',
        data: [tag],
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(() => {
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
      })
      .catch(() => {
        createNotification({
          message: 'Error: unable to delete.',
          options: { variant: 'error' },
        });
      })
      .finally(() => refresh());
  };

  const TagContainer = (childrenList: React.ReactNode[]): React.ReactNode => (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      sx={{ width: '50%', height: '65px' }}
    >
      {childrenList.map((child: React.ReactNode) => child)}
    </Stack>
  );

  const loadingList: React.ReactNode[] = [
    <Box sx={{ m: 2, position: 'relative' }}>
      <CircularProgress
        size={34}
        color='secondary'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
        }}
      />
    </Box>,
  ];

  const tagList: React.ReactNode[] = [
    <Dialog
      open={open}
      fullScreen={false}
      maxWidth={'xl'}
      clickClose={handleClose}
      title={'NEW TAG'}
    >
      <NewTagForm addItems={addNewTags} />
    </Dialog>,
    <Button
      variant='contained'
      color='success'
      aria-label='add tags'
      onClick={() => handleOpen()}
    >
      <AddIcon fontSize='large' />
    </Button>,
    <div>
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={1}
        mb={2}
        mt={1}
        sx={{ height: '55px', overflowY: 'auto' }}
      >
        {response != null &&
          response.map((tag: string) => (
            <Grid item xs>
              <Chip
                label={tag.toUpperCase()}
                onDelete={() => handleDelete(tag)}
              />
            </Grid>
          ))}
      </Grid>
    </div>,
  ];

  if (loading) {
    return <>{TagContainer(loadingList)}</>;
  }

  return <>{TagContainer(tagList)}</>;
}

{
  /* <NewTagForm clickClose={handleCloseCars} carBlackList={response} /> */
}
