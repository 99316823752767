import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import React from 'react';

import { OptionsObject } from 'notistack';
import useAccessToken from '../../../../hooks/useAccessToken';
import useNotifier from '../../../../hooks/useNotifier';
import GroupCars from './GroupCars';
import { CarType } from '../../../../models/FleetAsset';

const CrossDeptSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

interface Props {
  clickClose: () => void;
  carBlackList: CarType[] | null;
}

export default function AddCar({ clickClose, carBlackList }: Props): React.ReactElement {
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();

  const [isLoading, setIsLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const successOptions: OptionsObject = {
    variant: 'success',
  };

  const errorOptions: OptionsObject = {
    variant: 'error',
  };

  return <GroupCars clickClose={clickClose} carBlackList={carBlackList ?? []} />;
}
