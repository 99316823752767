import React from 'react';
import { Column } from "@material-table/core"
import { formatMialage } from '../utils/carHelper';
import { toHumanFriendlyDate } from '../utils/timeHelper';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import DocumentActions from '../views/components/uploadDocument/DocumentActions';
import { Article, Folder } from '@mui/icons-material';
import FolderActions from '../views/components/uploadFolder/FolderActions';

export interface Attachment {
    name: string ,
    date: string,
    type: string,
    id: string,
    parentId: number, 
    archived: boolean
}

export interface DocumentMetadata extends Attachment {
    attachmentType: 'Document',
    parentFolder?: FolderMetadata | { id: string }    
}

export interface FolderMetadata extends Attachment {
    attachmentType: 'Folder'
}

export type AttachmentMetadata = DocumentMetadata | FolderMetadata;


export const ATTACHMENT_TABLE_COLUMNS = (updateFunction: () => void) => { 
    return [
        {title: "", field: "type", width: "5%", render: rowData => {
            if(rowData.attachmentType === 'Folder'){
                return (<Folder />)
            }else{
                return (<Article />);
            }
        }},
        {title: "Name", field: "name"},
        {title: "Type", field: "type"},
        {title: "Date", field: "date", render: rowData => 
            rowData.date !== null ? (toHumanFriendlyDate(rowData.date)): ""},
        {title: "Actions", field: "id", render: rowData => {
            if(rowData.attachmentType === 'Document'){            
                return (
                    <DocumentActions attachment={rowData} updateFunction={updateFunction} />
                );
            }else{
                return (<FolderActions attachment={rowData} updateFunction={updateFunction} />);
            }
        }}
    ] as Array<Column<AttachmentMetadata>>;
}