import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { ProfileType } from '../../models/Access';
import { AccessFilterType, DrawerIndexPropTypes } from '../../models/drawer';
import { ApplicationState } from '../../store';
import { AccessState } from '../../store/Access';
import { groupDrawerItems, singleDrawerItems } from './config';
import DrawerView from './DrawerView';
import { generateDrawerSections, generateMainListItems } from './helper';

const getAccessFilter = ({
  isItdadmin,
  isDeptAdmin,
  isFleetAdmin,
  groupAdmin,
}: ProfileType) => ({ isItdadmin, isDeptAdmin, isFleetAdmin, groupAdmin });

export default function index({ open, toggleDrawer }: DrawerIndexPropTypes) {
  const history = useHistory();
  const { profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );

  let accessFilter: AccessFilterType = {
    isItdadmin: false,
    isDeptAdmin: false,
    isFleetAdmin: false,
    groupAdmin: false,
  };
  if (profile !== null) {
    accessFilter = getAccessFilter(profile);
  }

  const createRoutingFunc = (path: string) => {
    const historyRoute = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => history.push(path);
    return historyRoute;
  };

  const mainListItems: React.ReactNode = generateMainListItems(
    singleDrawerItems(createRoutingFunc, accessFilter)
  );

  const secondaryListItems: React.ReactNode = generateDrawerSections(
    groupDrawerItems(createRoutingFunc)
  );

  return (
    <DrawerView
      open={open}
      toggleDrawer={toggleDrawer}
      mainListItems={mainListItems}
    />
  );
}
