import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

import Layout from './components/Layout';
import { ProfileType } from './models/Access';
import { ApplicationState } from './store';
import { AccessState } from './store/Access';
import SingleUserView from './views/components/users/components/SingleUserView';
import SingleVehicle from './views/components/vehiclesAlternative/SingleVehicle';
import Department from './views/Department';
import DeptCheckOutReservationHolder from './views/Department/components/DeptCheckOutReservationHolder';
import GroupMangement from './views/Department/components/GroupManagement';
import Group from './views/Group';
import GroupCheckOutReservationHolder from './views/Group/components/GroupCheckOutReservationHolder';
import Home from './views/Home';
import DeskTopReservation from './views/Home/Reservations/DeskTopReservation';
import NewReservation from './views/Home/Reservations/NewReservation';
import UpcomingReservations from './views/Home/Reservations/UpcomingReservations';
import ItdManager from './views/ItdManager';
import NotificationTable from './views/components/Notifications/NotificationTable';

interface RouteAccessType {
  isItdadmin: boolean;
  isDeptAdmin: boolean;
  isFleetAdmin: boolean;
  groupAdmin: boolean;
}

export default () => {
  const [access, setAccess] = useState<RouteAccessType | null>(null);

  const { profile, isLoading } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );

  useEffect(() => {
    let isMounted = true;

    if (!isLoading && profile !== null) {
      const getAccessFilter = ({
        isItdadmin,
        isDeptAdmin,
        isFleetAdmin,
        groupAdmin,
      }: ProfileType): RouteAccessType => ({
        isItdadmin,
        isDeptAdmin,
        isFleetAdmin,
        groupAdmin,
      });
      const _access = getAccessFilter(profile);
      setAccess(_access);
    }

    return () => {
      isMounted = false;
    };
  }, [isLoading, profile]);

  function PrivateGroupRoute({ children, ...rest }: any) {
    if (access !== null) {
      return (
        <Route
          {...rest}
          render={() => {
            return access.groupAdmin === true ||
              access.isDeptAdmin ||
              access.isItdadmin ? (
              children
            ) : (
              <Redirect to='/' />
            );
          }}
        />
      );
    } else {
      return <></>;
    }
  }

  function PrivateItdRoute({ children, ...rest }: any) {
    if (access !== null) {
      return (
        <Route
          {...rest}
          render={() => {
            return access.isItdadmin === true ? children : <Redirect to='/' />;
          }}
        />
      );
    }
    return <></>;
  }

  function PrivateDeptRoute({ children, ...rest }: any) {
    if (access !== null) {
      return (
        <Route
          {...rest}
          render={() => {
            return access.isDeptAdmin === true ? children : <Redirect to='/' />;
          }}
        />
      );
    }
    return <></>;
  }

  return (
    <Layout>
      <Route exact path='/' component={Home} />
      <Route path='/reservation/new' component={DeskTopReservation} />
      <Route path='/reservation/upcoming' component={UpcomingReservations} />
      <PrivateGroupRoute path='/group'>
        <Route exact path='/group' component={Group} />
      </PrivateGroupRoute>
      <PrivateGroupRoute path='/group/:id([0-9]+)'>
        <Route exact path='/group/:id([0-9]+)' component={GroupMangement} />
      </PrivateGroupRoute>
      <PrivateGroupRoute path='/group/checkedout'>
        <Route
          exact
          path='/group/checkedout'
          component={GroupCheckOutReservationHolder}
        />
      </PrivateGroupRoute>
      <PrivateGroupRoute exact path='/group/notifications'>
        <Route
          exact
          path='/group/notifications'
          component={NotificationTable}
        />
      </PrivateGroupRoute>
      <PrivateGroupRoute path='/vehicle/:id'>
        <Route exact path='/vehicle/:id' component={SingleVehicle} />
      </PrivateGroupRoute>
      <PrivateGroupRoute path='/user/:id'>
        <Route exact path='/user/:id' component={SingleUserView} />
      </PrivateGroupRoute>
      <PrivateDeptRoute exact path='/department'>
        <Route exact path='/department' component={Department} />
      </PrivateDeptRoute>
      <PrivateDeptRoute exact path='/department/checkedout'>
        <Route
          exact
          path='/department/checkedout'
          component={DeptCheckOutReservationHolder}
        />
      </PrivateDeptRoute>
      <PrivateDeptRoute exact path='/department/notifications'>
        <Route
          exact
          path='/department/notifications'
          component={NotificationTable}
        />
      </PrivateDeptRoute>
      <PrivateItdRoute exact path='/application'>
        <Route exact path='/application' component={ItdManager} />
      </PrivateItdRoute>
    </Layout>
  );
};
