import Tabs from "../../../components/Tabs";
import { tabConfigType } from "../../../models/Tabs";
import GroupCheckedOutVehicles from './GroupCheckedOutVehicles';
import GroupReservationTable from './GroupReservationTable';


export default function DeptCheckOutReservationHolder(){
    const tabConfig: tabConfigType[] = [
        { label: 'CheckOuts', key: 0, component: <GroupCheckedOutVehicles/> },    
        {
          label: "Reservations",
          key: 1, 
          component: (<GroupReservationTable/>)
        }
      ];
    
      return (
        <>
          <Tabs tabConfig={tabConfig} />
        </>
      );
}