import Grid from '@mui/material/Grid';
import { InfoCardsType } from '../../models/InfoCards';

export default function InfoCards({
  reservation,
  assigned,
  actions,
}: InfoCardsType) {
  return (
    <Grid
      container
      direction='row'
      wrap='nowrap'
      justifyContent='space-evenly'
      alignItems='center'
    >
      {reservation && <Grid item>{reservation}</Grid>}
      {assigned && <Grid item>{assigned}</Grid>}
      {actions && <Grid item>{actions}</Grid>}
    </Grid>
  );
}
