import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import urljoin from 'url-join';

import { useMsal } from '@azure/msal-react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { APP_HEADER_NAME, DRAWER_WIDTH } from '../../constants/layout';

import { ProfileType } from '../../models/Access';
import { ApplicationState } from '../../store';
import { AccessState } from '../../store/Access';
import theme from '../../theme';

import Image from '../Profile/Image';

const USER_IMG_URL = `https://graph.microsoft.com/v1.0/me/photo/$value`;
const USER_INFO_URL = (id: string): string =>
  urljoin('https://graph.microsoft.com/v1.0/users', id);
const USER_PHOTO_URL = (id: string): string =>
  urljoin(USER_INFO_URL(id), '/photo/$value');
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function WelcomeUser() {
  const { accounts } = useMsal();
  const username = accounts[0].name;
  const { isLoading } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );
  if (!isLoading) {
    return <Image userName={username} imgUrl={USER_IMG_URL} />;
  } else {
    return <CircularProgress size='18em' thickness={4} color='secondary' />;
  }
}

const getAccessFilter = ({
  isItdadmin,
  isDeptAdmin,
  isFleetAdmin,
  groupAdmin,
}: ProfileType): boolean => {
  return isItdadmin || isDeptAdmin || isFleetAdmin || groupAdmin;
};

export default function index({ children }: { children?: React.ReactNode }) {
  const [open, setOpen] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

  const backdrop = useSelector(
    (state: ApplicationState) => state.applicationBackdrop
  );

  const { profile } = useSelector<ApplicationState, AccessState>(
    (state: ApplicationState) => state.access
  );

  useEffect(() => {
    let isMounted = true;

    if (profile !== null) {
      setIsAdmin(getAccessFilter(profile));
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1000000 }}
        open={backdrop?.isLoading ?? false}
      >
        <CircularProgress size='18em' thickness={4} color='secondary' />
      </Backdrop>
      <AppBar position='fixed' open={open}>
        <Toolbar
          sx={{
            pr: '38px',
            // m: 2, // keep right padding when drawer closed
          }}
        >
          <div>
            <a href='http://mcdss.co.monterey.ca.us/' target='_blank'>
              <Typography variant='h6' noWrap component='div' color='white'>
                DSS
              </Typography>
            </a>
          </div>
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            component='div'
            sx={{ fontStyle: 'italic', width: 'auto' }}
            variant='h4'
            color='inherit'
            noWrap
          >
            {APP_HEADER_NAME}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <div>
            <WelcomeUser />
          </div>
        </Toolbar>
      </AppBar>

      <main
        style={{
          marginTop: 60,
          marginRight: 15,
          marginBottom: 15,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        {children}
      </main>
    </>
  );
}
