import { Typography } from '@mui/material';
import React from 'react';

interface SectionNameProps {
    title: string;
}

export default function index({ title }: SectionNameProps ) {
  return (
    <Typography variant='h5' align="left" color='secondary' sx={{fontWeight: 'italic'}} >{title}</Typography>
  )
}
