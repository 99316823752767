import * as React from 'react';
import { Grid, Button, DialogContent, Link, DialogActions, CircularProgress } from '@mui/material';
import { Delete, MoveToInbox, UploadFile,  } from '@mui/icons-material';
import useAccessToken from '../../../hooks/useAccessToken';
import { folderDeleteService, documentGetService } from '../../../services/document';
import urljoin from 'url-join';
import { Attachment } from '../../../models/Attachment';
import Dialog from '../../../components/Dialog';
import { green } from '@mui/material/colors';
import LoadingSpinner from '../../../components/Loading';
import UploadDocument from '../uploadDocument';


export default function FolderActions({attachment, updateFunction }: {attachment: Attachment, updateFunction: () => void})
{
    const {accessToken} = useAccessToken();
    const [confirmDeletion, setDeleteConfirmation] = React.useState<boolean>(false);
    const [deleteCompleted, setCompletionStatus] = React.useState<boolean>(false);
    const [loadingImage, setLoadingStatus] = React.useState<boolean>(false);
    const [deleteInProgress, setInprogress] = React.useState<boolean>(false);

    const handleDeleteCall = () => {
        setInprogress(true);
        setDeleteConfirmation(false);
        folderDeleteService(accessToken, attachment.id).then(() => {            
            setCompletionStatus(true);
        });
    }

    const handleCompleteClose = () => {
        setDeleteConfirmation(false);
        setCompletionStatus(false);
        setLoadingStatus(false);
        updateFunction();
    }

    return (
        <Grid container direction="row" spacing={2}>  
            <Grid item>
                <UploadDocument parentObject={{id: attachment.parentId}} updateFunction={updateFunction} folderId={attachment.id} variant='outlined' />
            </Grid>
            <Grid item>
                <Button variant='outlined' color='error' onClick={() => setDeleteConfirmation(true)}><Delete /> Delete</Button>
            </Grid>
            {confirmDeletion ? 
                (
                    <Grid item>
                        <Dialog
                            open={confirmDeletion}
                            clickClose={() => setDeleteConfirmation(false)}
                            fullScreen={false}
                            >
                                <>
                                    <DialogContent>
                                        <div className='text-center'>
                                        <h4>Are you sure you want to delete this folder?</h4>
                                        <h2 style={{color: "red"}}><u>ALL</u></h2>
                                        <h3>Documents in folder will be archived.</h3>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid container direction='row-reverse' spacing={2}>
                                            <Grid item>
                                                <Button type='button' variant='contained' color='error' onClick={handleDeleteCall}>Confirm</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button type='button' variant='outlined' onClick={() => setDeleteConfirmation(false)}>Cancel</Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </>
                        </Dialog>
                    </Grid>
                ): (<></>)}
            {deleteInProgress ? (
                <Dialog open={deleteInProgress}
                    fullScreen={false}
                    clickClose={() => {}}>
                    <DialogContent>
                            <h3>
                                <LoadingSpinner message="" /> 
                                {`Deleting Folder`}
                            </h3>
                        </DialogContent>
                    </Dialog>
                    ): (<></>)}
            {deleteCompleted ? (
                <Grid item>
                        <Dialog
                            open={deleteCompleted}
                            clickClose={handleCompleteClose}
                            fullScreen={false}
                            >
                                <>
                                    <DialogContent>
                                        <h4>Folder Deleted.  Documents Archived</h4>
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid container direction='row-reverse' spacing={2} justifyContent='center' alignItems={'center'}>
                                            <Grid item>
                                                <Button type='button' variant='contained' onClick={handleCompleteClose}>Close</Button>
                                            </Grid>
                                        </Grid>
                                    </DialogActions>
                                </>
                        </Dialog>

                    </Grid>): (<></>)}
        </Grid>
    );
}