import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import number from 'yup/lib/number';
import { FuelSelectList } from '../../../utils/fuelHelper';

function valuetext(value: number) {
  return `${value}`;
}

export default function FuelLevelSelect({
  value,
  setFormValue,
}: {
  value: number;
  setFormValue: Function;
}) {
  const handleChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ): void => {
    if (Number.isInteger(value)) {
      setFormValue(value as number);
    }
  };

  return (
    <Box sx={{ ml: 4, mr: 4 }}>
      <Typography id='input-slider' gutterBottom>
        Ending Fuel Level
      </Typography>
      <Slider
        color='secondary'
        aria-label='Always visible'
        value={value}
        getAriaValueText={valuetext}
        step={1}
        marks={FuelSelectList()}
        max={4}
        valueLabelDisplay='off'
        onChange={handleChange}
      />
    </Box>
  );
}
