import React from 'react';
import { useHistory } from 'react-router';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function NavButton({
  pageLabel = '',
  path = { location: '/', name: 'Home' },
  children,
}: {
  pageLabel?: string;
  path?: { location: string; name: string };
  children?: React.ReactNode;
}) {
  const history = useHistory();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // height: '100vh',
          width: '100vw',
        }}
      >
        <Button
          variant='outlined'
          color='primary'
          onClick={() => history.push('/')}
          size='small'
          sx={{ marginLeft: '1em' }}
        >
          Home
        </Button>

        <Button
          variant='outlined'
          color='primary'
          onClick={() => history.push(path.location)}
          size='small'
          sx={{ marginRight: '1em' }}
        >
          {path.name}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // height: '100vh',
          width: '100vw',
        }}
      >
        <Typography color='secondary' variant='caption'>
          {pageLabel}
        </Typography>
      </Box>
      {children}
    </>
  );
}
