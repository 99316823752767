import EngineeringIcon from '@mui/icons-material/Engineering';
import GroupsIcon from '@mui/icons-material/Groups';
import LayersIcon from '@mui/icons-material/Layers';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

import {
  AccessByRoleType,
  AccessFilterType,
  AdditionalDrawerItemsType,
  DrawerItemType,
  SideBarMenuType,
} from '../../models/drawer';

const SIDE_BAR_MENU_LIST: SideBarMenuType = {
  basic: (func: Function): DrawerItemType => ({
    handleClick: func('/'),
    lable: 'CheckIn/CheckOut',
    icon: <LayersIcon />,
  }),

  groupAdmin: (func: Function): DrawerItemType => ({
    handleClick: func('/group'),
    lable: 'Group Management',
    icon: <GroupsIcon />,
  }),
  isDeptAdmin: (func: Function): DrawerItemType => ({
    handleClick: func('/department'),
    lable: 'Dept. Management',
    icon: <ViewQuiltIcon />,
  }),
  isItdadmin: (func: Function): DrawerItemType => ({
    handleClick: func('/application'),
    lable: 'Application Manager',
    icon: <EngineeringIcon />,
  }),
};

const DRAWER_SECTION_ORDER = ['groupAdmin', 'isDeptAdmin', 'isItdadmin'];

export const singleDrawerItems = (
  func: Function,
  accessFilter: AccessFilterType = {}
): DrawerItemType[] => {
  const drawerItems: DrawerItemType[] = [SIDE_BAR_MENU_LIST.basic(func)];

  DRAWER_SECTION_ORDER.forEach((key, index) => {
    if (accessFilter[key]) {
      drawerItems.push(SIDE_BAR_MENU_LIST[key](func));
    }
  });

  return drawerItems;
};

export const groupDrawerItems = (
  func: Function
): AdditionalDrawerItemsType[] => [
  {
    subheaderLabel: 'Group Management',
    itemList: [
      {
        handleClick: func('/group'),
        lable: 'ITD',
        icon: <GroupsIcon />,
      },
    ],
  },
];
