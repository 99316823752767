import { Column } from "@material-table/core"
import { formatMialage } from '../utils/carHelper';
import { toHumanFriendlyDate } from '../utils/timeHelper';


export interface CarWorkOrder
{
    assetId: number,
    documentId: number, 
    status: string, 
    statusDesc: string | null,
    isDisableDowntime: boolean | null,
    inDate: string | null,
    outDate: string | null
}

export const CAR_WO_TABLE_COLUMNS: Array<Column<CarWorkOrder>> = [
    {title: "Status Short Name", field: "status"},
    {title: "Status Desc", field: "statusDesc"},
    {title: "In Date", field: "inDate", render: rowData => 
        rowData.inDate !== null ? (toHumanFriendlyDate(rowData.inDate)): "" },
    {title: "Out Date", field: "outDate", render: rowData => 
    rowData.outDate !== null ? (toHumanFriendlyDate(rowData.outDate)): "" },
]