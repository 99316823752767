import React, { useState, useEffect } from 'react';

import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import httpClient from '../../../../utils/httpClient';
import Typography, {
  TypographyPropsVariantOverrides,
} from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import Grid from '@mui/material/Grid';
import { ProfileType } from '../../../../models/Access';
import Skeleton from '@mui/material/Skeleton';

// "@odata.context": string,
// "businessPhones": any,
// "displayName": string,
// "givenName": string,
// "jobTitle": string | null,
// "mail": string,
// "mobilePhone": string | null,
// "officeLocation": string | null,
// "preferredLanguage": string | null,
// "surname": string | null,
// "userPrincipalName": string,
// "id": string
function requestGraphToken(instance: IPublicClientApplication) {
  const accessTokenRequest = {
    scopes: ['User.Read'],
  };
  return instance.acquireTokenSilent(accessTokenRequest);
}

interface UserInfoProps {
  url: string;
  user: ProfileType;
}

export default function UserInfo({ url, user }: UserInfoProps) {
  const [userInfo, setUserInfo] = useState<any>({});
  const [graphAccessToken, setGraphToken] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { instance } = useMsal();

  useEffect(() => {
    let isMounted = true;

    requestGraphToken(instance).then((response) => {
      if (isMounted) {
        setGraphToken(response.accessToken);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const requestConfig: AxiosRequestConfig = {
      url: url,
      method: 'GET',
      headers: {
        'Content-Type': 'text/json',
        Authorization: `Bearer ${graphAccessToken}`,
      },
      responseType: 'json',
    };

    const fetchInfo = async () => {
      try {
        const result: AxiosResponse = await httpClient.request(requestConfig);
        if (isMounted && result.status === 200) {
          setUserInfo(result.data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch {
        setIsLoading(false);
      }
    };

    if (graphAccessToken !== '') {
      fetchInfo();
    }
    return () => {
      isMounted = false;
    };
  }, [graphAccessToken]);

  const text = (
    text: string | null | undefined,
    variant: OverridableStringUnion<
      Variant | 'inherit',
      TypographyPropsVariantOverrides
    >
  ): React.ReactNode => (
    <Typography variant={variant}>
      <Box fontWeight='italic' display='inline'>
        {`${text ? text : ''}`}
      </Box>
    </Typography>
  );

  if (isLoading) {
    return (
      <>
        <Skeleton width='35%' animation='wave' height={30} sx={{ ml: 2 }} />
      </>
    );
  }

  return (
    <>
      <Grid item>{text(user.name, 'h6')}</Grid>
      <Grid item>{text(user.dept?.departmentName, 'caption')}</Grid>
      <Grid item>{text(userInfo.mail, 'caption')}</Grid>
    </>
  );
}
