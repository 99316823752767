import moment from 'moment';

export function toHumanFriendlyDate(dateString: string | null | undefined) {
  if (dateString === null || dateString === undefined) {
    return '';
  }
  const date = new Date(dateString);
  return moment(date).format('llll');
}

const listOfHours = (year: number, month: number, day: number): Date[] => {
  const listOfMin = [0, 30];
  const start = 8;
  const end = 16;
  const range = Array.from({ length: end }, (_, i) => i + start);
  const result: Date[] = [];

  range.reduce((acc: Date[], hour: number) => {
    acc.push(new Date(year, month, day, hour, 0, 0));
    acc.push(new Date(year, month, day, hour, 30, 0));

    return acc;
  }, result);

  return result;
};

export function createCheckInSelection(date: Date): Date[] {
  const YEAR = date.getFullYear();
  const MONTH = date.getMonth();
  const DAY = date.getDate();
  return listOfHours(YEAR, MONTH, DAY);
}

export function roundToNearest30(date: Date): Date {
  const minutes = 30;
  const ms = 1000 * 60 * minutes;

  return new Date(Math.ceil(date.getTime() / ms) * ms);
}

export function roundToNearest5(date: Date): Date {
  const minutes = 5;
  const ms = 1000 * 60 * minutes;

  return new Date(Math.ceil(date.getTime() / ms) * ms);
}
