import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Fab,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import LoadingSpinner from '../../../../components/Loading';

import Dialog from '../../../../components/Dialog';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import { ProfileType } from '../../../../models/Access';
import { GRID_BOOLEAN_COL_DEF } from '@mui/x-data-grid';
import useAccessToken from '../../../../hooks/useAccessToken';
import useNotifier from '../../../../hooks/useNotifier';
import httpClient from '../../../../utils/httpClient';

interface UnassignuserProps {
  carId: number | string;
  open: boolean;
  setClose: Function;
  closeRefresh: Function;
}

const URL = (id: number | string) => `/api/Car/${id}/unassign`;

export default function UnassignUser({
  carId,
  open,
  setClose,
  closeRefresh,
}: UnassignuserProps) {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();

  const submit = () => {
    setIsSubmitting(true);
    httpClient
      .request({
        url: URL(carId),
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(() => {
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
        setIsSubmitting(false);
      })
      .catch(() => {
        createNotification({
          message: 'Error while Canceling.',
          options: { variant: 'error' },
        });
      })
      .finally(() => closeRefresh());
  };

  return (
    <>
      {open ? (
        <Dialog
          maxWidth='md'
          open={open}
          fullScreen={false}
          title={'Un-Assign car'}
          clickClose={() => {
            setClose();
          }}
        >
          <DialogContent sx={{ width: '400px' }}>
            <Stack
              alignItems='center'
              justifyContent='space-evenly'
              direction='row'
              spacing={3}
            >
              <Box sx={{ m: 1, position: 'relative' }}>
                <Button
                  size='medium'
                  disabled={isSubmitting}
                  onClick={() => submit()}
                  variant='outlined'
                >
                  Confirm
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    color='secondary'
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>

              <Button
                size='medium'
                disabled={isSubmitting}
                onClick={() => setClose()}
                color='error'
                variant='outlined'
              >
                Cancel
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}
