import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import { useContext, useState } from 'react';
import { HomeContext } from '../HomeContextView';
import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import httpClient from '../../../utils/httpClient';

const CHECKOUT_RESERVATION_URL = (reservationId: string | number) =>
  `/api/Reservation/${reservationId}/checkout`;

interface CheckoutReservationButtonProps {
  reservationId: number;
  refresh: Function;
}

export default function CheckoutReservationButton({
  reservationId,
  refresh,
}: CheckoutReservationButtonProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();
  const { reload } = useContext(HomeContext);

  const cancelReservation = () => {
    setLoading(true);
    httpClient
      .request({
        url: CHECKOUT_RESERVATION_URL(reservationId),
        method: 'POST',
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(() => {
        refresh();
        if (reload !== null) reload();
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
        setLoading(false);
      })
      .catch(() => {
        createNotification({
          message: 'Error during Checkout.',
          options: { variant: 'error' },
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          aria-label='Cancel reservation'
          color='primary'
          size='small'
          fullWidth
          onClick={() => cancelReservation()}
        >
          checkout
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color: green[500],
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </>
  );
}
