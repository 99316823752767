import React from 'react';
import {
  Box,
  CircularProgress,
  DialogContent,
  Fab,
  TextField,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import * as yup from 'yup';
import Dialog from '../../../../components/Dialog';

import SendIcon from '@mui/icons-material/Send';

import { useFormik } from 'formik';

interface InactiveCarModalProps {
  active: boolean;
  updateStatus: Function;
  open: boolean;
  setOpen: Function;
}

export default function InactiveCarModal({
  active,
  updateStatus,
  open,
  setOpen,
}: InactiveCarModalProps) {
  const NewNoteSchema = yup.object({
    activeValue: yup.boolean().required('required'),
    note: yup.string().when('activeValue', {
      is: (activeValue: boolean) => activeValue !== true,
      then: yup.string().required('required'),
    }),
  });

  const initialValues = {
    note: '',
    activeValue: !active,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: NewNoteSchema,
    onSubmit: (values, { resetForm, setFieldValue }) => {
      const { note, activeValue } = values;
      const _note = activeValue
        ? `[Active] ${note}`
        : `[Inactive] - reason: ${note}`;

      console.log('active', activeValue);
      console.log('note', _note);
      updateStatus({ active: activeValue, note: _note });
    },
  });

  return (
    <>
      {open ? (
        <Dialog
          maxWidth='md'
          open={open}
          fullScreen={false}
          title={'Vehicle Status'}
          clickClose={() => setOpen(false)}
        >
          <DialogContent sx={{ width: '400px' }}>
            <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
              <Stack
                direction='column'
                spacing={3}
                alignItems='flex-start'
                justifyContent='space-around'
                sx={{ mt: 2, mb: 2 }}
              >
                <Typography
                  variant='h6'
                  color={!active ? green[500] : 'error'}
                  sx={{ flexGrow: 1 }}
                >
                  {!active ? 'Set Active' : 'Set Inactive'}
                </Typography>
                <TextField
                  required={active}
                  id='note'
                  label='Reason'
                  fullWidth
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  disabled={formik.isSubmitting}
                  error={formik.touched.note && Boolean(formik.errors.note)}
                  helperText={formik.touched.note && formik.errors.note}
                  sx={{ height: '75px' }}
                />

                <Box sx={{ m: '1 3 1 1', position: 'relative' }}>
                  <Fab
                    color='primary'
                    aria-label='add'
                    size='small'
                    type='submit'
                    disabled={formik.isSubmitting}
                  >
                    <SendIcon />
                  </Fab>

                  {formik.isSubmitting && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Box>
              </Stack>
            </form>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );
}
