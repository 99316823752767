import React from 'react';

import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { a11yProps, TabPanel } from '../../utils/tabHelper';

import Box from '@mui/material/Box';
import { tabConfigType } from '../../models/Tabs';

export default function index({ tabConfig }: { tabConfig: tabConfigType[] }) {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = React.useState<number>(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box
      sx={{
        borderTop: 1,
        borderRight: 1,
        borderLeft: 1,
        borderBottom: 1,
        borderRadius: 2,
        borderColor: 'grey.200',
      }}
    >
      <Tabs
        onChange={handleTabChange}
        value={currentTab}
        aria-label='Tabs where selection follows focus'
        selectionFollowsFocus
        variant='fullWidth'
      >
        {tabConfig.map((item: tabConfigType, index: number) => (
          <Tab
            key={index}
            label={item.label}
            {...a11yProps(item.key)}
            sx={{ borderLeft: item.key == 0 ? 0 : 1, borderColor: 'grey.200' }}
          />
        ))}
      </Tabs>

      {tabConfig.map((item: tabConfigType, index: number) => (
        <TabPanel
          key={index}
          value={currentTab}
          index={item.key}
          dir={theme.direction}
        >
          {item.component}
        </TabPanel>
      ))}
    </Box>
  );
}
