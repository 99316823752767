import React from 'react';
import { CarType } from '../../../../models/FleetAsset';

import MaterialTable from '@material-table/core';
import urlJoin from 'url-join';
import LoadingSpinner from '../../../../components/Loading';
import useTokenGetRequest from '../../../../hooks/useTokenGetRequest';
import {
  CarMileageReading,
  CAR_MILEAGE_TABLE_COLUMNS,
} from '../../../../models/CarMileageReading';
import AddMileage from './AddMileage';
import { ExportCsv } from '../../../../utils/csvExportHelper';
import useNotifier from '../../../../hooks/useNotifier';

const MILEAGE_REFERENCE_URL = (id: number) =>
  urlJoin('api/car', id.toString(), 'mileage');

export default function MileageTable({ parentCar }: { parentCar: CarType }) {
  const { createNotification } = useNotifier();
  const dataUrl: string = MILEAGE_REFERENCE_URL(parentCar.id);

  const { response, error, loading, refresh } =
    useTokenGetRequest<CarMileageReading[]>(dataUrl);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <MaterialTable
        columns={CAR_MILEAGE_TABLE_COLUMNS(refresh)}
        data={response ?? []}
        title={'Mileage Tracker'}
        options={{
          exportMenu: [
            {
              label: 'Export as CSV',
              exportFunc: (columns: any, renderData: any, tableData: any) => {
                ExportCsv(
                  createNotification,
                  columns,
                  renderData,
                  tableData,
                  parentCar.assetNumber ?? `VehicleID ${parentCar.id}`
                );
              },
            },
          ],
        }}
        isLoading={loading}
        actions={[
          {
            icon: () => (
              <AddMileage id={parentCar.id} updateFunction={refresh} />
            ),
            tooltip: 'Add Mileage',
            isFreeAction: true,
            onClick: (event) => console.log(event),
          },
        ]}
      ></MaterialTable>
    </>
  );
}
