import MaterialTable from '@material-table/core';
import Grid from '@mui/material/Grid';
import React, { PropsWithChildren } from 'react';

import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../../components/Loading';
import useTokenGetRequest from '../../../hooks/useTokenGetRequest';
import { ProfileType, userTableColumns } from '../../../models/Access';

export default function index(props: PropsWithChildren<{ url: string }>) {
  const history = useHistory();
  const { response, error, loading, refresh } = useTokenGetRequest<
    ProfileType[]
  >(props.url);
  const [openIsAdmin, setOpenIsAdmin] = React.useState<boolean>(false);
  const [profileData, setProfileData] = React.useState<ProfileType>();

  const handleRowClick = (event?: React.MouseEvent, rowData?: ProfileType) => {
    try {
      if (rowData !== undefined && rowData.id) {
        setProfileData(rowData);
        setOpenIsAdmin(true);
        history.push(`/user/${rowData.id}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (error) {
    console.log(error);
  }

  if (loading) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Grid container direction='column' spacing={3}>
          {props.children !== null ? <Grid item>{props.children}</Grid> : <></>}
          <Grid item>
            <div style={{ height: 600, width: 'auto' }}>
              <MaterialTable
                columns={userTableColumns}
                data={response ?? []}
                title={'Users'}
                options={{
                  emptyRowsWhenPaging: false,
                  grouping: true,
                  pageSize: 15,
                  pageSizeOptions: [15, 50, 100],
                }}
                onRowClick={handleRowClick}
                isLoading={loading}
              />
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
