import React from 'react';
import { Send, AddCircle, Attachment, CloudDone } from '@mui/icons-material';
import { DialogActions, DialogContent, Button, Grid, IconButton, ButtonTypeMap } from '@mui/material';

import { UploadDocumentMetadata } from '../../../models/UploadDocumentMetadata';
import Dialog from '../../../components/Dialog';
import IAttachableObject from '../../../models/IAttachableObject';
import SingleDocument from './SingleDocument';
import {documentCreateService} from '../../../services/document';
import useAccessToken from '../../../hooks/useAccessToken'; 
import LoadingSpinner from '../../../components/Loading';

enum UploadState {
    Pending,
    Uploading,
    Uploaded
}

export default function UploadDocument({
    parentObject: { id },
    showLabel = true,
    updateFunction,
    folderId,
    variant = 'contained'
  }: {
    parentObject: IAttachableObject | { id: number },
    showLabel?: boolean,
    updateFunction: () => void, 
    folderId?: string,
    variant?: 'text' | 'outlined' | 'contained'
  }){
    const { accessToken } = useAccessToken();
    const [open, setUploadDialogOpen] = React.useState<boolean>(false);
    const [uploadDocuments, setPendingUploadDocuments] = 
        React.useState<UploadDocumentMetadata[]>([{documentType: '', file: null, folderId: folderId}]);

    const [uploadingState, setUploadStatus] = React.useState<UploadState>(UploadState.Pending);
    const [updateTracker, setNewUpdate] = React.useState<number>(0);
  
    const handleNewlyAttachedDocument = () => {    
      setPendingUploadDocuments([...uploadDocuments,{documentType: "", file: null, folderId: folderId}]);
    }
    const appendNewPresetDocuments = (newDocument: UploadDocumentMetadata[]) => {
      setPendingUploadDocuments([...uploadDocuments, ...newDocument]);
    }
    const handleSubmitClick = () => {
        let documentFormData = new FormData();
        let validDocuments = uploadDocuments.filter(document => document.file !== null);
        if(validDocuments && validDocuments.length > 0){
            validDocuments.forEach((document, index) => {
                documentFormData.append(`files[${index}].ParentId`, id.toString());
                documentFormData.append(`files[${index}].ParentType`, "1");
                documentFormData.append(`files[${index}].DocumentType`, document.documentType);
                documentFormData.append(`files[${index}].File`, (document.file as File));
                if(document.folderId){
                    documentFormData.append(`files[${index}].FolderId`, document.folderId);
                }
            })
            setUploadStatus(UploadState.Uploading);
            documentCreateService(accessToken,documentFormData).then(() => {
                setUploadStatus(UploadState.Uploaded);
                updateFunction();
            });
        }
    }

    const handleDialogClose = () => {
        setUploadDialogOpen(false);
        setPendingUploadDocuments([{documentType: '', file: null}]);
        setUploadStatus(UploadState.Pending);
    }

    const displayAppropriateUploadState = () => {
        switch(uploadingState){
            case UploadState.Pending:
                return (
                    <>
                        <DialogContent>
                            <h3 className="text-center">Upload Document</h3>
                            <hr />
                            <Grid container direction="column" spacing={1}>
                            {uploadDocuments.map((uploadDocument, index, array) => 
                                (<SingleDocument 
                                uploadDocument={uploadDocument}  
                                updateFunction={() => {
                                    setNewUpdate(updateTracker + 1);
                                }}
                                deleteFunction={() => {
                                    setPendingUploadDocuments(
                                        uploadDocuments.filter((_, documentIndex) => documentIndex != index))
                                }}
                                appendFunction={appendNewPresetDocuments} />)
                            )}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                        <Grid container direction='row-reverse' spacing={1}>
                            <Grid item>
                            <Button type="button" variant='contained' onClick={handleSubmitClick}>
                                <Send /> Upload
                            </Button>
                            </Grid>
                            <Grid item>
                            <Button 
                                type="button" 
                                variant='outlined' 
                                color="info" 
                                onClick={handleNewlyAttachedDocument}
                                >
                                <AddCircle /> Add Document
                            </Button>
                            </Grid>
                        </Grid>
                        </DialogActions>
                    </>
                );
            case UploadState.Uploading:
                return (
                    <DialogContent>
                        <h3>
                            <LoadingSpinner message="" /> 
                            {`Uploading ${uploadDocuments.length} documents`}
                        </h3>
                    </DialogContent>
                );
            case UploadState.Uploaded:
                return (
                    <>
                        <DialogContent>
                            <Grid container justifyContent="center" direction='column' alignItems='center'>
                                <Grid item>
                                    <CloudDone sx={{fontSize:'4rem'}} />
                                </Grid>
                                <Grid item>
                                    <h3>
                                        <span> Documents Uploaded</span> 
                                    </h3>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Grid container direction='row' alignItems='center' justifyContent='center'>
                                <Grid item>
                                    <Button type='button' variant='contained' onClick={handleDialogClose}>Close</Button>
                                </Grid>
                            </Grid>
                        </DialogActions>
                    </>
                )
        }
    }
  
      return (
        <Grid container direction='column'>
          <Grid item>
            {
                showLabel ? 
                    (<Button onClick={() => setUploadDialogOpen(true)} variant={variant}><Attachment /> Attach Document</Button>): 
                    (<IconButton onClick={() => setUploadDialogOpen(true)} ><Attachment/> </IconButton>) 
            }
            
          </Grid>
          <Grid item>
            {open ? (
              <Dialog 
                maxWidth='md'
                open={open} 
                fullScreen={false}
                clickClose={handleDialogClose}>
                    {displayAppropriateUploadState()}
              </Dialog>): 
              (<></>)
            }
          </Grid>
        </Grid>
      );
  }