import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import { ProfileType } from "../models/Access";


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AccessState {
  isLoading: boolean;
  profile: ProfileType | null;
}

const initialState: AccessState = {
  isLoading: true,
  profile: null,
};

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface SetApplicationAccessAction {
  type: "SET_APPLICATION_ACCESS";
  profile: ProfileType;
}

export interface SetIsLoadingFalseAction {
  type: "SET_IS_LOADING_FALSE";
  isLoading: false;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SetApplicationAccessAction | SetIsLoadingFalseAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const AccessActions = {
  setAccessProfile:
    (profile: ProfileType): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const appState = getState();

      dispatch({
        type: "SET_APPLICATION_ACCESS",
        profile,
      });
    },
    setLoadingFalse:
    (): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const appState = getState();

      dispatch({
        type: "SET_IS_LOADING_FALSE",
        isLoading: false,
      });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<AccessState> = (
  state: AccessState | undefined,
  incomingAction: Action
): AccessState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "SET_APPLICATION_ACCESS":
      return { profile: action.profile, isLoading: false };
    case "SET_IS_LOADING_FALSE":
      return {...state, isLoading: action.isLoading}
    default:
      return state;
  }
};
