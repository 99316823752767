import { AxiosResponse } from 'axios';
import { isAfter, isBefore } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card } from 'reactstrap';

import {
  Box,
  CircularProgress,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import Grid from '@mui/material/Grid';

import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import { CarType } from '../../../models/FleetAsset';
import { ApplicationState } from '../../../store';
import { AccessState } from '../../../store/Access';
import httpClient from '../../../utils/httpClient';
import CarImage from '../../components/vehiclesAlternative/components/CarImage';
import DateTimePicker from './DateTimePicker';

const default_image =
  'https://gsmechanical.com.au/wp-content/uploads/2018/01/AdobeStock_110205977-e1516765819712.jpeg';

const reservation_available_url = (
  startDate: string,
  endDate: string
): string =>
  `/api/Reservation/available?startDate=${startDate}&endDate=${endDate}`;

const make_reservation_url = '/api/Reservation';

const formatDateTime = (date: Date | undefined) => {
  const _date: Date = date === undefined ? new Date() : date;
  return moment(_date).format('YYYY-MM-DDTHH:mm:ss');
};

const TODAY = new Date();
TODAY.setDate(TODAY.getDate() + 1);
TODAY.setHours(8, 0, 0);

const END_DAY = new Date();
END_DAY.setDate(END_DAY.getDate() + 2);
END_DAY.setHours(8, 0, 0);

const defaultSelected: DateRange = {
  from: TODAY,
  to: END_DAY,
};

export default function index() {
  const [selectedRange, setSelectedRange] =
    React.useState<DateRange>(defaultSelected);
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [availableCars, setAvailableCars] = useState<CarType[]>([]);
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();
  const [selectedCar, setSelectedCar] = useState<CarType | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');

  const { profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setSelectedCar(null);
      setAvailableCars([]);
    }

    return () => {
      isMounted = false;
    };
  }, [selectedRange]);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };

  const checkAvailability = () => {
    setLoading(true);
    httpClient
      .request({
        url: reservation_available_url(
          formatDateTime(selectedRange.from),
          formatDateTime(selectedRange.to)
        ),
        method: 'GET',
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(({ data }: AxiosResponse) => {
        setAvailableCars(data);
        createNotification({
          message: 'Success',
          options: { variant: 'success' },
        });
        setLoading(false);
      })
      .catch(() => {
        createNotification({
          message: 'Error while checking availability.',
          options: { variant: 'error' },
        });
      });
  };

  const makeReservation = () => {
    setIsSubmitting(true);
    httpClient
      .request({
        url: make_reservation_url,
        method: 'POST',
        data: {
          carId: selectedCar?.id,
          expectedStartTime: formatDateTime(selectedRange.from),
          expectedEndTime: formatDateTime(selectedRange.to),
          purpose: note,
        },
        headers: {
          'Content-Type': 'text/json',
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'json',
      })
      .then(() => {
        createNotification({
          message: 'Reservation Created.',
          options: { variant: 'info' },
        });
        setIsSubmitting(false);
      })
      .catch(() => {
        createNotification({
          message: 'Error while making reservation.',
          options: { variant: 'error' },
        });
      })
      .finally(() => history.goBack());
  };

  const customAvailableList = (items: CarType[], message: string = 'Empty') => {
    if (_.isEmpty(items)) {
      return (
        <Card>
          <List
            key={-1}
            sx={{
              minWidth: 420,
              // maxWidth: 800,
              width: 'auto',
              height: 390,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component='div'
            role='list'
          >
            <ListItem key={'#48957-32'} role='listitem'>
              <ListItemText id={'34'} primary={`${message}`} />
            </ListItem>
          </List>
        </Card>
      );
    } else {
      return (
        <Card>
          <List
            sx={{
              minWidth: 420,
              maxWidth: 800,
              width: 'auto',
              height: 390,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component='div'
            role='list'
          >
            {items.map((value: CarType, index: number) => {
              const labelId = `transfer-list-all-item-${value}-label`;
              // let adName: string = value.userPrincipalName.split('@')[0];
              return (
                <ListItemButton
                  key={value.id}
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItem
                    defaultValue={value.id}
                    key={value.id}
                    role='listitem'
                    // button={true}
                    // disabled={isSubmitting}
                    onClick={(event) => {
                      event.preventDefault();
                      setSelectedCar(value);
                    }}
                  >
                    <ListItemIcon>
                      <CarImage
                        id={
                          value.imageId === null ? default_image : value.imageId
                        }
                        width={50}
                        height={50}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${value?.assetNumber ?? 'n/a'} - ${
                        value.year
                      } ${value.make} ${value.model}`}
                      secondary={`${value.fuelType}`}
                    />
                  </ListItem>
                </ListItemButton>
              );
            })}
            <ListItem />
          </List>
        </Card>
      );
    }
  };

  const handleStartDate = (date: Date) => {
    const setFromDate = () => {
      setSelectedRange((selectedRange) => {
        return { ...selectedRange, from: date };
      });
    };

    if (selectedRange.to === undefined) {
      setFromDate();
      return;
    }

    if (isAfter(date, selectedRange.to)) {
      const _newFromDate = new Date(date);
      _newFromDate.setHours(17, 0, 0);
      handleReturnDate(_newFromDate);
    }

    setFromDate();
  };

  const handleReturnDate = (date: Date) => {
    const setToDate = () => {
      setSelectedRange((selectedRange) => {
        return { ...selectedRange, to: date };
      });
    };

    if (selectedRange.to === undefined) {
      setToDate();
      return;
    }
    if (
      selectedRange.from !== undefined &&
      isBefore(date, selectedRange.from)
    ) {
      const _newFromDate = new Date(date);
      _newFromDate.setHours(8, 0, 0);
      date.setHours(17, 0, 0);
      handleStartDate(_newFromDate);
    }

    setToDate();
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Grid
        container
        direction='row'
        spacing={1}
        justifyContent='flex-start'
        alignItems='baseline'
        wrap='wrap'
      >
        <Grid item sm={12} md={12} lg={12}>
          <Box sx={{ mb: 4, position: 'relative', width: '100%' }}>
            <Button
              // fullWidth // if its mobile
              color='success'
              variant='contained'
              disabled={
                isSubmitting || _.isNull(selectedCar) || _.isEmpty(note)
              }
              onClick={() => makeReservation()}
              // sx={{ fontSize: '.5em' }}
              size='small'
            >
              Make Reservation
            </Button>

            {isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={12}>
          <TextField
            required
            fullWidth
            id='note'
            label='Purpose'
            value={note}
            onChange={(event) => setNote(event.target.value)}
            disabled={isSubmitting}
            size='medium'
            sx={{ fontSize: '.5em' }}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <DateTimePicker
            title={'Pick-up:'}
            date={selectedRange.from}
            handleDate={handleStartDate}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <DateTimePicker
            title={'Return:'}
            date={selectedRange.to}
            handleDate={handleReturnDate}
          />
        </Grid>

        <Grid item sm={12} md={12} lg={12}>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              disabled={loading}
              variant='contained'
              onClick={() => checkAvailability()}
              size='small'
            >
              Check Availability
            </Button>

            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={12}>
          {!_.isNull(availableCars) && customAvailableList(availableCars)}
        </Grid>
      </Grid>
    </Container>
  );
}
