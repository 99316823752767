import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';

interface CustomCheckBoxProps {
  name: string | number;
  initialValue: boolean;
  cb: Function;
}

function CustomCheckBox({
  name,
  initialValue,
  cb,
}: CustomCheckBoxProps): React.ReactElement {
  const [isChecked, setIsChecked] = useState<boolean>(initialValue);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsChecked(checked);
    cb(name, checked);
  };

  return (
    <Checkbox
      name={`${name}`}
      checked={isChecked}
      tabIndex={-1}
      disableRipple
      onChange={handleCheck}
    />
  );
}

export default CustomCheckBox;
