import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import InfoCards from '../../components/InfoCards';
import Tabs from '../../components/Tabs';
import { ApplicationState } from '../../store';
import {
  DeptGroupSelectionActions,
  DeptGroupSelectorState,
} from '../../store/DeptGroupSelector';
import GroupSelector from '../components/groupSelector';

import { tabConfigType } from '../../models/Tabs';
import User from '../components/users';
import Group from '../components/vehiclesAlternative';
import { createInfoCardContent } from './components/DeptInfoCards';
import Groups from './components/Groups';
import SectionName from '../../components/SectionName';

export default function index() {
  const { userUrl, carUrl, group } = useSelector<
    ApplicationState,
    DeptGroupSelectorState
  >((state) => state.deptGroupSelector);

  const InfoCardContent = createInfoCardContent();

  const groupSelector = (
    <GroupSelector
      group={group}
      setGroup={DeptGroupSelectionActions.setDeptGroup}
    />
  );

  const tabConfig: tabConfigType[] = [
    {
      label: 'Vehicles',
      key: 0,
      component: <Group url={carUrl}>{groupSelector}</Group>,
    },
    {
      label: 'Users',
      key: 1,
      component: <User url={userUrl}>{groupSelector}</User>,
    },
    {
      label: 'Group Management',
      key: 2,
      component: <Groups />,
    },
  ];

  return (
    <>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <SectionName title={'Department Mangement'} />
        </Grid>
        <Grid item>
          <InfoCards {...InfoCardContent} />
        </Grid>
        <Grid item></Grid>
        <Grid item>
          <Tabs tabConfig={tabConfig} />
        </Grid>
      </Grid>
    </>
  );
}
