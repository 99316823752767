import { Redirect, Route, Switch } from 'react-router';
import MobileView from './views/Mobil/Mobile';

import Mobile from './components/Layout/Mobile';
import ReservationForm from './views/Mobil/ReservationForm';
import Reservations from './views/Mobil/Reservations';

export default function MobileApp() {
  return (
    <Switch>
      <Route exact path='/'>
        <Mobile>
          <MobileView />
        </Mobile>
      </Route>
      <Route exact path='/reservation'>
        <Mobile>
          <Reservations />
        </Mobile>
      </Route>
      <Route exact path='/reservation/new'>
        <Mobile>
          <ReservationForm />
        </Mobile>
      </Route>
      <Route path='*'>
        <Redirect to='/' />
      </Route>
    </Switch>
  );
}
