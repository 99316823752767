import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useRef } from 'react';
import { ActiveModifiers, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  width: '214px',
  backgroundColor: '#fff',
  '& :hover': {
    backgroundColor: '#eeeeee',
  },
}));

interface DatepickerDialogProps {
  setSelectedDate: Function;
  scheduledDay: Date | undefined;
  disableDate: Date;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#eceff1',
    color: '#757575',
    maxWidth: 'none',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}));

export default function DatePickerDialog({
  setSelectedDate,
  scheduledDay,
  disableDate,
}: DatepickerDialogProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleDaySelect = (
    day: Date | undefined,
    selectedDay: Date,
    activeModifiers: ActiveModifiers,
    e: React.MouseEvent
  ) => {
    if (day === undefined) {
      return;
    }

    let hours = 8;
    let minutes = 0;

    if (scheduledDay !== undefined) {
      hours = scheduledDay.getHours();
      minutes = scheduledDay.getMinutes();
    }

    day.setHours(hours, minutes, 0);

    if (day) {
      setSelectedDate(day);
      handleTooltipClose();
    }
  };

  if (buttonRef === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <LightTooltip
            placement='bottom-start'
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <>
                <DayPicker
                  initialFocus={open}
                  mode='single'
                  numberOfMonths={1}
                  defaultMonth={scheduledDay}
                  selected={scheduledDay}
                  onSelect={handleDaySelect}
                  disabled={[{ before: disableDate }]}
                />
              </>
            }
          >
            <StyledButton onClick={handleTooltipOpen}>
              <Box>
                <Typography
                  variant='h5'
                  noWrap
                  m='.5em'
                  alignItems='right'
                  fontSize='20px'
                >
                  {moment(scheduledDay).format('ddd, MMM D')}
                  <span role='img' aria-label='calendar icon'>
                    📅
                  </span>
                  {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </Typography>
              </Box>
            </StyledButton>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
}
