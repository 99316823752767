import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';

import Button from '@mui/material/Button';
import { green } from '@mui/material/colors';
import { useFormik } from 'formik';

import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import {
  CheckInFormType,
  CheckInSchema,
} from '../../../models/CheckInCheckOut';
import { CarType } from '../../../models/FleetAsset';
import httpClient from '../../../utils/httpClient';
import FuelLevelSelect from './FuelLevelSelect';

import SanitizeConfirmation from './SanitizeConfirmation';

interface CheckOutFormProps {
  car: CarType;
  closeCheckout: Function;
  closeRefresh: Function;
}

export default function CheckInForm({
  car,
  closeCheckout,
  closeRefresh,
}: CheckOutFormProps) {
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();
  const [loading, setLoading] = React.useState(false);

  const recordId: number =
    car.checkedOut === null || car.checkedOut === undefined
      ? 0
      : car.checkedOut.id ?? 0;

  const initialValues: CheckInFormType = {
    id: recordId,
    endingFuelLevel: 0,
    sanitized: false,
    notes: undefined,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: CheckInSchema,
    onSubmit: (values: CheckInFormType) => {
      setLoading(true);

      httpClient
        .request({
          url: `/api/${recordId}/checkout`,
          method: 'PUT',
          data: values,
          headers: {
            'Content-Type': 'text/json',
            Authorization: `Bearer ${accessToken}`,
          },
          responseType: 'json',
        })
        .then(() => {
          createNotification({
            message: 'Success',
            options: { variant: 'success' },
          });
        })
        .catch(() => {
          createNotification({
            message: 'Error while saving.',
            options: { variant: 'error' },
          });
        })
        .finally(() => closeRefresh());
    },
  });

  const modalName = (text: string): React.ReactNode => (
    <Stack direction='column' mb={2}>
      <Typography color='secondary'>{text}</Typography>
    </Stack>
  );

  const handleFuelLevelChange = (value: number) => {
    formik.setFieldValue('endingFuelLevel', value);
  };

  return (
    <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
      <DialogContent>
        {modalName(`${car.year} ${car.make} ${car.model}`)}
        <FuelLevelSelect
          value={formik.values.endingFuelLevel ?? 0}
          setFormValue={handleFuelLevelChange}
        />
        <SanitizeConfirmation formik={formik} />
        <TextField
          disabled={formik.isSubmitting}
          fullWidth
          margin='dense'
          value={formik.values.notes}
          id='notes'
          label='notes'
          variant='standard'
          onChange={formik.handleChange}
          error={formik.touched.notes && Boolean(formik.errors.notes)}
          helperText={formik.touched.notes && formik.errors.notes}
          multiline
          maxRows={2}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='secondary'
          variant='contained'
          disabled={formik.isSubmitting}
          onClick={() => closeCheckout()}
          sx={{ mr: 4 }}
        >
          cancel
        </Button>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            color='primary'
            variant='contained'
            disabled={formik.isSubmitting}
            type='submit'
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            Check In
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </form>
  );
}
