import { format } from 'date-fns';

import { Column } from '@material-table/core';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

import { toHumanFriendlyDate } from '../utils/timeHelper';

export interface Reasons {
  type: string;
  message: string;
}

export interface Notification {
  id: number;
  carDescription: string;
  carId: number;
  reportedBy: string;
  reasons: Reasons[];
  createdOn: string;
}

export interface UserReport {
  id: number;
  userId: number;
  notificationId: number;
  isSeen: boolean;
  createdOn: string;
  dateSeen: string;
  notification: Notification;
}

function message(reasons: Reasons[]) {
  return (
    <Stack>
      {reasons.map((reason) => (
        <Typography>
          {reason.type}: {reason.message}
        </Typography>
      ))}
    </Stack>
  );
}

export const NOTIFICATIONS_TABLE_COLUMNS: Array<Column<UserReport>> = [
  // { title: 'ID', field: 'id', editable: 'never', width: 10 },
  {
    title: 'Seen',
    field: 'isSeen',

    width: 10,
    lookup: { true: 'Yes', false: 'No' },
    render: (rowData, renderType) => {
      if (renderType === 'row') {
        if (rowData.isSeen) {
          return <CheckIcon color='success' />;
        }
        return '';
      } else {
        return rowData;
      }
    },
  },
  {
    title: 'Reported on',
    field: 'CreatedOn',
    editable: 'never',
    type: 'date',
    grouping: false,
    customSort: (a, b) => {
      return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
    },
    render: (rowData, renderType) => {
      if (renderType === 'row') {
        if (rowData.createdOn !== null) {
          return toHumanFriendlyDate(rowData.createdOn);
        }
        return '';
      } else {
        if (rowData !== null) {
          return format(new Date(rowData.toString()), 'MM-dd-yyyy');
        } else {
          return '';
        }
      }
    },
  },
  {
    title: 'Car Description',
    field: 'notification.carDescription',
    editable: 'never',
  },
  {
    title: 'Reported By',
    field: 'notification.reportedBy',
    editable: 'never',
  },
  {
    title: 'Reasons',
    field: 'notification.reasons',
    editable: 'never',
    grouping: false,
    render: (rowData, renderType) => {
      if (renderType === 'row') {
        return message(rowData.notification.reasons);
      } else {
        return message(rowData.notification.reasons);
      }
    },
  },
];
