import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';

import { ApplicationState } from '../../store';
import {
  GroupSelectionActions,
  GroupSelectorState,
} from '../../store/GroupSelector';
import GroupSelector from '../components/groupSelector';
import User from '../components/users';
import GroupUsersTable from '../components/users/GroupUsersTable';
import Vehicles from '../components/vehiclesAlternative';

import InfoCards from '../../components/InfoCards';
import { createInfoCardContent } from './components/groupCards';

import Tabs from '../../components/Tabs';
import { tabConfigType } from '../../models/Tabs';
import SectionName from '../../components/SectionName';

export default function index() {
  const { userUrl, carUrl, group } = useSelector<
    ApplicationState,
    GroupSelectorState
  >((state) => state.groupSelector);

  const InfoCardContent = createInfoCardContent();

  const tabConfig: tabConfigType[] = [
    { label: 'Users', key: 0, component: group.toLowerCase() === 'all' ? (<User url={userUrl}/>) : (<GroupUsersTable url={userUrl} groupId={parseInt(group, 10)} /> )},
    {
      label: 'Vehicles',
      key: 1,
      component: <Vehicles url={carUrl} />,
    },
  ];

  return (
    <>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <SectionName title={'Group Management'} />
        </Grid>
        <Grid item mb={10}>
          <InfoCards {...InfoCardContent} />
        </Grid>
        <Grid item>
          <GroupSelector
            group={group}
            setGroup={GroupSelectionActions.setAGroup}
            isGroupAdmin
          />
        </Grid>
        <Grid item>
          <Tabs tabConfig={tabConfig} />
        </Grid>
      </Grid>
    </>
  );
}
