import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import React from 'react';

import { green } from '@mui/material/colors';
import { OptionsObject } from 'notistack';
import { useSelector } from 'react-redux';
import useAccessToken from '../../../hooks/useAccessToken';
import useNotifier from '../../../hooks/useNotifier';
import {
  NewGroupFormValues,
  NewGroupValidationSchema,
  NewGroupValidationSchemaType,
  GroupInitType
} from '../../../models/Group';
import groupCreateService from '../../../services/group';
import { ApplicationState } from '../../../store';
import { AccessState } from '../../../store/Access';

const CrossDeptSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

interface Props {
  clickClose: () => void;
}

export default function NewGroupForm({
  clickClose,
}: Props): React.ReactElement {
  const { accessToken } = useAccessToken();
  const { createNotification } = useNotifier();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const { profile } = useSelector<ApplicationState, AccessState>(
    (state) => state.access
  );

  const successOptions: OptionsObject = {
    variant: 'success',
  };

  const errorOptions: OptionsObject = {
    variant: 'error',
  };

  const formik = useFormik({
    initialValues: new NewGroupFormValues({
      departmentId: profile?.dept?.departmentId ?? '',
    }),
    validationSchema: NewGroupValidationSchema,
    onSubmit: (values: GroupInitType) => {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
      }

      groupCreateService(accessToken, values)
        .then((res) => {
          createNotification({ message: 'Success', options: successOptions });
          setSuccess(true);
          setLoading(false);
        })
        .catch((err) => {
          createNotification({
            message: 'Connection Error',
            options: errorOptions,
          });
          setSuccess(true);
          setLoading(false);
        })
        .finally(() => {
          clickClose();
        });
    },
  });

  const departmentName = (text: string): React.ReactNode => (
    <Stack direction='column' mb={2}>
      <Typography color='secondary'>{text}</Typography>
    </Stack>
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('crossDepartment', event.target.checked);
  };
  return (
    <>
      <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
        <DialogContent>
          {departmentName(profile?.dept?.departmentName ?? '')}
          <TextField
            disabled={formik.isSubmitting}
            required
            fullWidth
            autoFocus
            sx={{ height: 50, width: 300 }}
            value={formik.values.name}
            margin='dense'
            id='name'
            label='Group Name'
            variant='standard'
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <Stack direction='row' spacing={1} alignItems='center' mt={2}>
            <FormLabel component='legend'>Cross Department</FormLabel>
            <Typography>No</Typography>
            <CrossDeptSwitch
              disabled={formik.isSubmitting}
              onChange={handleChange}
              defaultChecked={false}
              inputProps={{ 'aria-label': 'ant design' }}
            />
            <Typography>Yes</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button disabled={formik.isSubmitting} type='submit'>
              create
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        </DialogActions>
      </form>
    </>
  );
}
